import React, { Fragment } from 'react';
import styles from '../../../styles';
import { ROW_HEIGHT } from '../index';
import Loading from '../../loading';
import Text from '../../text';
import { TouchableOpacity } from '../../../../plugins';
import { Col } from '../../layout';
import Icon from '../../../icon';

export default ({
  onPress,
  style = {},
  column,
  columnIndex,
  loading,
  sorting
}) => {
  const { label, field, width } = column;

  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={1}
      style={[
        styles.padding(10),
        styles.row,
        styles.border(0, 0, 1),
        !!width && { width, minWidth: width, maxWidth: width },
        { ...style, height: ROW_HEIGHT, alignItems: 'center' }
      ]}
    >
      {columnIndex === 0 && !!loading ? (
        <Loading style={[styles.padding(0)]} />
      ) : (
        <Fragment>
          {typeof label === 'string' ? (
            <Text numberOfLines={1} style={[styles.bold]}>
              {label}
            </Text>
          ) : (
            label
          )}
        </Fragment>
      )}
      <Col flex={1} fx={'flex-end'} style={[styles.marginLeft(10)]}>
        <Icon
          size={24}
          color={
            !!sorting && sorting.field === field && sorting.order === 'ASC'
              ? 'dark'
              : '#dddddd'
          }
          style={[styles.marginBottom(-5)]}
          name={'angle-up'}
        />
        <Icon
          size={24}
          color={
            !!sorting && sorting.field === field && sorting.order === 'DESC'
              ? 'dark'
              : '#dddddd'
          }
          style={[styles.marginTop(-5)]}
          name={'angle-down'}
        />
      </Col>
    </TouchableOpacity>
  );
};
