import React from 'react';
import { AppState, AsyncStorage } from '../../../plugins';
import FileTaskWorker from '../fileTaskWorker';
import { FORM_REVISION_SYNCS } from './_gql';
import { clear_ROOT_MUTATION, client } from '../../shared/apollo';

let inst;
export default class FormSyncTaskWorker extends FileTaskWorker {
  loadingText = '';
  async componentDidMount() {
    inst = this;
    this._mounted = true;
    const json = (await AsyncStorage.getItem('formSync')) || '[]';
    this.tasks = JSON.parse(json);
    // console.log('formSync', this.tasks);
    AppState.addEventListener('change', this._handleAppStateChange);
  }
  componentWillUnmount() {
    inst = null;
    this._mounted = false;
    AppState.removeEventListener('change', this._handleAppStateChange);
  }
  static getCache = (...args) => {
    return !!inst && inst.getCache(...args);
  };
  static addTask = async (...args) => {
    !!inst && (await inst.addTask(...args));
  };
  static clearTask = () => {
    !!inst && inst.clearTask();
  };
  static saveTask = () => {
    !!inst && inst.saveTask();
  };
  static toggleOnline = () => {
    !!inst && inst.toggleOnline();
  };
  static getTasksCount = (...args) => {
    return !!inst && inst.getTasksCount(...args);
  };
  getTasksCount = () => {
    return this.tasks.length;
  };
  saveTask = async () => {
    const json = JSON.stringify(this.tasks);
    await AsyncStorage.setItem('formSync', `${json}`);
  };
  addTask = async (task, debounce = 0) => {
    if (!!task) {
      const { revisionId, stageId, fields = [] } = task;
      const find = this.tasks.find(
        t => t.revisionId === revisionId && t.stageId === stageId
      );
      if (!!find) {
        for (const field of fields) {
          const existSameField = find.fields.find(
            _field => _field.fieldId === field.fieldId
          );
          if (!!existSameField) Object.assign(existSameField, field);
          else find.fields.push(field);
        }
      } else {
        this.tasks.push({ revisionId, stageId, fields });
      }
    }
    await new Promise(resolve => setTimeout(resolve), debounce);
    await this.startWorker();
  };
  getCache = ({ revisionId, stageId, fieldId }) => {
    const { fields = [] } =
      this.tasks.find(
        t => t.revisionId === revisionId && t.stageId === stageId
      ) || {};
    const { data } = fields.find(f => f.fieldId === fieldId) || {};
    if (!!data) {
      try {
        return JSON.parse(data);
      } catch (e) {}
    }
    return null;
  };
  taskHandler = async ({ revisionId, stageId, fields }) => {
    await client.mutate({
      mutation: FORM_REVISION_SYNCS(revisionId, stageId),
      variables: { fields }
    });
    await clear_ROOT_MUTATION();
  };
}
