import React from 'react';
import { Row, Col } from './layout';
import styles from '../styles';

export const CardHeader = ({ style, children, ...props }) => {
  return (
    <Row
      {...props}
      style={[
        styles.padding(12, 10),
        styles.backgroundColor('light-bg'),
        styles.border(0, 0, 1, 0)
      ].concat(style)}
    >
      {children}
    </Row>
  );
};

export const CardBody = ({ style, children, ...props }) => {
  return (
    <Col {...props} style={[styles.padding(10)].concat(style)}>
      {children}
    </Col>
  );
};

export const CardFooter = ({ style, children, ...props }) => {
  return (
    <Row
      {...props}
      style={[
        styles.padding(12, 10),
        styles.backgroundColor('light-bg'),
        styles.border(1, 0, 0, 0)
      ].concat(style)}
    >
      {children}
    </Row>
  );
};

export default ({ style, children, ...props }) => {
  return (
    <Col
      {...props}
      style={[
        styles.rounded(5),
        styles.border(1),
        styles.marginBottom(30)
      ].concat(style)}
    >
      {children}
    </Col>
  );
};
