import { ApolloBoost } from '../../../plugins';
const { gql } = ApolloBoost;

export const GET_REPORT = gql`
  query(
    $projectId: ID
    $startedAt: String
    $startedThru: String
    $weekStartedAt: String
    $now: String
  ) {
    me {
      id
      NOS_OF_FAILED: report(
        projectId: $projectId
        startedAt: $startedAt
        startedThru: $startedThru
        metricType: NOS_OF_FAILED
      ) {
        id
        metric
        data {
          date
          value
        }
      }
      NOS_OF_SUBMITTED: report(
        projectId: $projectId
        startedAt: $startedAt
        startedThru: $startedThru
        metricType: NOS_OF_SUBMITTED
      ) {
        id
        metric
        data {
          date
          value
        }
      }
      NOS_OF_PENDING_INSPECTION: report(
        projectId: $projectId
        startedAt: $startedAt
        startedThru: $startedThru
        metricType: NOS_OF_PENDING_INSPECTION
      ) {
        id
        metric
        data {
          date
          value
        }
      }
      NOS_OF_AWAITING_REVIEW: report(
        projectId: $projectId
        startedAt: $startedAt
        startedThru: $startedThru
        metricType: NOS_OF_AWAITING_REVIEW
      ) {
        id
        metric
        data {
          date
          value
        }
      }
      NOS_OF_PASSED: report(
        projectId: $projectId
        startedAt: $startedAt
        startedThru: $startedThru
        metricType: NOS_OF_PASSED
      ) {
        id
        metric
        data {
          date
          value
        }
      }
      NQAA: report(
        projectId: $projectId
        startedAt: $weekStartedAt
        startedThru: $now
        metricType: NQAA
      ) {
        id
        metric
        data {
          date
          value
        }
      }
      AVERAGE_PASS_RATE: report(
        projectId: $projectId
        startedAt: $startedAt
        startedThru: $startedThru
        metricType: AVERAGE_PASS_RATE
      ) {
        id
        metric
        data {
          date
          value
        }
      }
      AVERAGE_PROGRESSING_TIME: report(
        projectId: $projectId
        startedAt: $startedAt
        startedThru: $startedThru
        metricType: AVERAGE_PROGRESSING_TIME
      ) {
        id
        metric
        data {
          date
          value
        }
      }
      SUB_DISCIPLINES: report(
        projectId: $projectId
        startedAt: $startedAt
        startedThru: $startedThru
        metricType: SUB_DISCIPLINES
      ) {
        id
        metric
        data {
          date
          value
          name
        }
      }
      TOTAL_AVERAGE_PASS_RATE: report(
        projectId: $projectId
        metricType: TOTAL_AVERAGE_PASS_RATE
      ) {
        id
        metric
        data {
          date
          value
          name
        }
      }
    }
  }
`;
