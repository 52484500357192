import React from 'react';
import {
  ScrollView,
  ReactApollo,
  View,
  Hyperlink,
  Linking,
  OS,
  Image
} from '../../../plugins';
import { Row, Col, Flex, Responsively } from '../../../rnbc/components/layout';
import Loading from '../../../rnbc/components/loading';
import H5 from '../../../rnbc/components/h5';
import NavHeader from '../../../rnbc/components/navHeader';
import ActivityIndicator from '../../../rnbc/components/activityIndicator';
import FormIcon from './_formIcon';
import styles from '../../../rnbc/styles';
import { GET_FORMS, FORM_REVISION_CREATE } from './_gql';
import Alert from '../../../rnbc/components/alert';
import Text from '../../../rnbc/components/text';
import { errorParser } from '../../shared/errorParser';
import { client } from '../../shared/apollo';
import { alert } from '../../shared/popup';
import ScrollHeaderLayout from '../../../rnbc/components/scrollHeaderLayout';
import { BottomPadder } from '../../../rnbc/components/iphonePadder';
import Button from '../../../rnbc/components/button';
import Icon from '../../../rnbc/icon';

let fnRefresh;
export default ({
  history,
  match: { params } = {},
  location: { state: { title } = {} }
}) => {
  const { projectId } = params || {};
  return (
    <Col style={[styles.blockh]}>
      <NavHeader
        title={title}
        back
        history={history}
        rightComponent={
          <Button
            color={'light'}
            transparent
            onPress={() => {
              history.push({
                pathname: `/Report/${projectId}`
              });
            }}
          >
            <Icon name={'chart-pie-alt'} />
          </Button>
        }
      />
      <ScrollHeaderLayout
        heightRange={[0, 0]}
        onRefresh={async () => {
          !!fnRefresh && fnRefresh();
        }}
      >
        <Responsively
          layout={{ md: Col, sm: Row }}
          style={[styles.padding(10)]}
        >
          <Flex />
          <Image
            resizeMode={'contain'}
            style={[{ width: (696 * 50) / 333, height: 50 }]}
            source={require('../../image/simple-white-logo.png')}
          />
          <Flex />
        </Responsively>
        <ReactApollo.Query
          fetchPolicy={'cache-and-network'}
          query={GET_FORMS(projectId)}
        >
          {({ data, error, refetch }) => {
            fnRefresh = refetch;
            let isCacheAvailable = !!data && !!data.me;

            if (!data || !isCacheAvailable) return <Loading />;
            if (!!!!error) Alert.danger(errorParser(error));

            const { me } = data || {},
              project = (me || {})[`p_${projectId.replace(/-/g, '_')}`] || {},
              { description, forms = [] } = project || {};
            return (
              <ScrollView>
                <View style={[styles.padding(10, 10, 0)]}>
                  <Hyperlink
                    onPress={url => {
                      if (OS === 'web') window.open(url, '_blank');
                      else Linking.openURL(url);
                    }}
                    linkStyle={{
                      color: styles.getColorCode('primary-text')
                    }}
                  >
                    <Text style={[{ fontSize: 14 }]} color={'light-text'}>
                      {description}
                    </Text>
                  </Hyperlink>
                </View>
                {forms.length === 0 && (
                  <H5
                    color={'border'}
                    style={[styles.block, styles.padding(30, 10)]}
                  >
                    No Data
                  </H5>
                )}
                <Row wrap style={[styles.padding(5, 15, 5, 5)]}>
                  {forms.map(({ revision, ...form }, i) => (
                    <View
                      key={i}
                      style={[
                        styles.margin(5, 5),
                        styles.block,
                        { maxWidth: 450 }
                      ]}
                    >
                      <FormIcon
                        {...form}
                        projectId={projectId}
                        revision={revision || {}}
                        onSearchPress={() => {
                          const { id: formId = '' } = form;
                          history.push({
                            pathname: `/Search/${projectId}/${formId}`
                          });
                        }}
                        onCreatePress={async () => {
                          const { id: formId = '' } = form;
                          try {
                            await alert('', 'Create New Form?');
                            ActivityIndicator.show();
                            console.log({
                              projectFormId: formId
                            });
                            const {
                              data: { formRevisionCreate }
                            } = await client.mutate({
                              mutation: FORM_REVISION_CREATE,
                              variables: {
                                projectFormId: formId
                              }
                            });
                            const { id = '' } = formRevisionCreate || {};
                            history.push({
                              pathname: `/Revision/${projectId}/${formId}/${id}`,
                              state: {
                                title: ''
                              }
                            });
                          } catch (e) {
                            console.log(e);
                            Alert.danger(errorParser(e));
                          } finally {
                            ActivityIndicator.hide();
                          }
                        }}
                        onFormPress={async () => {
                          let { id: formId = '' } = form,
                            { id: revisionId = '' } = revision || {};

                          if (!revisionId || !formId) {
                            Alert.danger('No Revision');
                            return;
                          }
                          history.push({
                            pathname: `/Revision/${projectId}/${formId}/${revisionId}`,
                            state: {
                              title: ''
                            }
                          });
                        }}
                        onListPress={viewId => {
                          history.push({
                            pathname: `/Revisions/${projectId}/${form.id}/${viewId}`,
                            state: {
                              title: form.name
                            }
                          });
                        }}
                      />
                    </View>
                  ))}
                </Row>
              </ScrollView>
            );
          }}
        </ReactApollo.Query>
        <BottomPadder />
        <View style={[{ height: 75 }]} />
      </ScrollHeaderLayout>
    </Col>
  );
};
