import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from './layout';
import H1 from './h1';
import H3 from './h3';
import styles from '../styles';

export default class ErrorBoundary extends Component {
  state = { error: undefined };
  static getDerivedStateFromError(error) {
    console.log(error);
    return { error };
  }
  componentDidCatch(error, errorInfo) {
    const { onError } = this.props;
    onError(error, () => {
      this.setState({ error: undefined });
    });
  }
  render() {
    const { renderError, children } = this.props,
      { error } = this.state;
    if (error) return renderError(error);
    return children;
  }
}
ErrorBoundary.propTypes = {
  renderError: PropTypes.func,
  onError: PropTypes.func
};
ErrorBoundary.defaultProps = {
  renderError: error => (
    <Col style={[styles.fill, styles.padding(30)]} fx={'center'} fy={'center'}>
      <H1>Session Expired</H1>
      <H3 color={'secondary'}>Redirecting to Login...</H3>
    </Col>
  ),
  onError: _ => _
};
