import React from 'react';
import PropTypes from 'prop-types';
import UserField from './userField';
import TextField from './textField';
import { Label, FormGroup, Small } from '../../rnbc/components/form';
import Card, { CardBody } from '../../rnbc/components/card';
import Text from '../../rnbc/components/text';
import Button from '../../rnbc/components/button';
import { ApolloBoost } from '../../plugins';
import styles from '../../rnbc/styles';
import FieldLoading from './fieldLoading';
import Icon from '../../rnbc/icon';
import { Row } from '../../rnbc/components/layout';
import FieldUserGroupPositionsRemark from './FieldUserGroupPositionsRemark';
const gql = ApolloBoost.gql;

export const GET_SELECTABLE_USERS = (
  projectId,
  formId,
  revisionId,
  stageId,
  fieldId
) => gql`
  query(
    $cursor: ID
    $limits: Int
    $filter: UserFilter
  ) {
    me {
      id
      p_${projectId.replace(/-/g, '_')}: project(id: "${projectId}") {
        id
        f_${formId.replace(/-/g, '_')}: form(id: "${formId}") {
          id
          rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
            id
            s_${stageId.replace(/-/g, '_')}: stage(stageId: "${stageId}") {
              id
              fi_${fieldId.replace(/-/g, '_')}: field(fieldId: "${fieldId}") {
                id
                ... on NotifyField {
                  selectableUsers(
                    cursor: $cursor
                    limits: $limits
                    filter: $filter
                  ) {
                    nextCursor
                    totalCount
                    nodes {
                      id
                      name
                      email
                      position
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default class NotifyField extends FieldLoading {
  render() {
    const {
        label,
        message,
        users,
        requireNoted,
        disabled,
        onUserChange = _ => _,
        onMessageChange = _ => _,
        onPress,
        _sync,
        description,
        userGroup,
        userPositions,
        ...props
      } = this.props,
      { isOnline } = this.state;
    return (
      <FormGroup>
        {!!_sync && (
          <FieldLoading style={[styles.absolute({ top: -5, right: 0 })]} />
        )}
        {!!label && (
          <Row fy={'center'}>
            <Label style={[styles.marginY(5, !!description ? 0 : 5)]}>
              {label}
            </Label>
            <FieldUserGroupPositionsRemark
              userGroup={userGroup}
              userPositions={userPositions}
            />
          </Row>
        )}
        {!!description && (
          <Small style={[styles.marginBottom(5)]}>{description}</Small>
        )}
        <Card>
          <CardBody>
            <UserField
              label={'Target User(s)'}
              onChange={onUserChange}
              disabled={disabled}
              users={users}
              requireNoted={requireNoted}
              searchQuery={GET_SELECTABLE_USERS}
              {...props}
            />
            <TextField
              label={'Message'}
              text={message}
              onChange={onMessageChange}
              disabled={disabled}
            />
            <Button
              disabled={disabled || !isOnline}
              style={[{ width: '100%', maxWidth: 500 }]}
              onPress={onPress}
            >
              <Text>Send</Text>
              {!isOnline && (
                <Icon name={'wifi-slash'} size={styles.h5.fontSize} />
              )}
            </Button>
          </CardBody>
        </Card>
      </FormGroup>
    );
  }
}
NotifyField.propTypes = {
  label: PropTypes.string,
  message: PropTypes.string,
  users: PropTypes.any,
  requireNoted: PropTypes.bool,
  disabled: PropTypes.bool,
  onUserChange: PropTypes.func,
  onMessageChange: PropTypes.func,
  onPress: PropTypes.func,
  description: PropTypes.string
};
NotifyField.defaultProps = {
  onUserChange: _ => _,
  onMessageChange: _ => _
};
