import React, { Fragment } from 'react';
import Form, { FormGroup, Label, Input } from '../../../rnbc/components/form';
import HR from '../../../rnbc/components/hr';
import styles from '../../../rnbc/styles';
import TextField from '../../component/textField';
import EnumField from '../../component/enumField';
import ApprovalField from '../../component/approvalField';
import DateTimeSearchField from './_dateTimeSearchField';
import { BottomPadder } from '../../../rnbc/components/iphonePadder';
import Text from '../../../rnbc/components/text';

export default ({ fields, values, setFieldValue, settings = {} }) => {
  return (
    <Form style={[styles.padding(10)]}>
      {fields.map((field, i) => {
        const { id, __typename, label } = field,
          setting = { ...(settings[id] || {}), ...field };
        const { input, since, until } = values[id] || {};

        switch (__typename) {
          case 'DateTimeField':
            return (
              <DateTimeSearchField
                key={i}
                label={label}
                since={since}
                until={until}
                onChange={(since, until) => setFieldValue(id, { since, until })}
              />
            );
          case 'ChopField':
            return (
              <DateTimeSearchField
                key={i}
                label={`${label} (chopped at)`}
                since={since}
                until={until}
                onChange={(since, until) => setFieldValue(id, { since, until })}
              />
            );
          case 'UserField':
            return (
              <FormGroup key={i}>
                <Label>{label}</Label>
                <Input
                  value={input}
                  onChangeText={input => setFieldValue(id, { input })}
                  placeholder={"Search By User's Name"}
                />
              </FormGroup>
            );
          case 'SignatureField':
            return (
              <FormGroup key={i}>
                <Label>{label}</Label>
                <Input
                  value={input}
                  onChangeText={input => setFieldValue(id, { input })}
                  placeholder={'Signature By'}
                />
              </FormGroup>
            );
          case 'TextField':
            return (
              <FormGroup key={i}>
                <Label>{label}</Label>
                <Input
                  value={input}
                  onChangeText={input => setFieldValue(id, { input })}
                />
              </FormGroup>
            );
          case 'LocationField':
            return (
              <FormGroup key={i}>
                <Label>{label}</Label>
                <Input
                  value={input}
                  onChangeText={input => setFieldValue(id, { input })}
                  placeholder={'name/room/level/grid/area'}
                />
              </FormGroup>
            );
          case 'EnumField':
            return (
              <EnumField
                key={i}
                {...setting}
                value={input}
                onChange={input => setFieldValue(id, { input })}
              />
            );
          case 'SubmitButton':
            return (
              <Fragment key={i}>
                <HR style={[styles.marginX(-10)]} />
                <DateTimeSearchField
                  label={'Stage Submitted At'}
                  since={since}
                  until={until}
                  onChange={(since, until) =>
                    setFieldValue(id, { since, until })
                  }
                />
              </Fragment>
            );
          case 'WithdrawButton':
            return (
              <DateTimeSearchField
                key={i}
                label={'Form Withdrawn At'}
                important
                since={since}
                until={until}
                onChange={(since, until) => setFieldValue(id, { since, until })}
              />
            );
          case 'ApprovalField':
            const { numberOfButton } = field;
            return (
              <ApprovalField
                label={label}
                numberOfButton={numberOfButton}
                status={input}
                onChange={input => setFieldValue(id, { input })}
              />
            );
          case 'FileField':
            return (
              <FormGroup key={i}>
                <Label>{label}</Label>
                <Text style={[styles.color('note')]}>Nothing to search</Text>
              </FormGroup>
            );
          default:
            return null;
        }
      })}
      <BottomPadder />
    </Form>
  );
};
