export default {
  doc: 'application/msword',
  '*': 'application/octet-stream',
  pdf: 'application/pdf',
  ai: 'application/postscript',
  xls: 'application/vnd.ms-excel',
  xla: 'application/vnd.ms-excel',
  xlc: 'application/vnd.ms-excel',
  xlm: 'application/vnd.ms-excel',
  xlt: 'application/vnd.ms-excel',
  xlw: 'application/vnd.ms-excel',
  ppt: 'application/vnd.ms-powerpoint',
  csv: 'text/csv',
  gz: 'application/x-gzip',
  tar: 'application/x-tar',
  zip: 'application/zip',
  mp3: 'audio/mpeg',
  au: 'audio/basic',
  snd: 'audio/basic',
  mid: 'audio/mid',
  rmi: 'audio/mid',
  aif: 'audio/x-aiff',
  aifc: 'audio/x-aiff',
  aiff: 'audio/x-aiff',
  m3u: 'audio/x-mpegurl',
  ra: 'audio/x-pn-realaudio',
  ram: 'audio/x-pn-realaudio',
  wav: 'audio/x-wav',
  bmp: 'image/bmp',
  gif: 'image/gif',
  ief: 'image/ief',
  jpe: 'image/jpeg',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  svg: 'image/svg+xml',
  png: 'image/png',
  mp2: 'video/mpeg',
  mpa: 'video/mpeg',
  mpe: 'video/mpeg',
  mpeg: 'video/mpeg',
  mpg: 'video/mpeg',
  mpv2: 'video/mpeg',
  mp4: 'video/mp4',
  mov: 'video/quicktime',
  qt: 'video/quicktime',
  avi: 'video/x-msvideo',
  movie: 'video/x-sgi-movie'
};
