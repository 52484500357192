import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator as Loader, View, OS } from '../../plugins';
import styles from '../styles';
import Text from './text';
import FullWrapper from './fullWrapper';
import { Col } from './layout';

let inst;
export default class ActivityIndicator2 extends Component {
  state = {
    show: this.props.show || false,
    msg: ''
  };

  componentDidMount() {
    inst = this;
  }
  componentWillUnmount() {
    inst = null;
  }

  static show = (...args) => {
    !!inst && inst.show(...args);
  };

  static hide = () => {
    !!inst && inst.hide();
  };

  show(msg = '') {
    this.setState({ show: true, msg });
  }

  hide() {
    this.setState({ show: false });
  }

  render() {
    const { show: visibleFromProps, style } = this.props;
    const { show: visibleFromState, msg } = this.state;
    if (!visibleFromProps && !visibleFromState) return null;

    return (
      <FullWrapper
        style={[
          styles.column,
          styles.backgroundColor('rgba(0,0,0,0)'),
          styles.absolute({
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          }),
          { alignItems: 'flex-end', justifyContent: 'flex-end' }
        ]}
        pointerEvents={'none'}
      >
        <View
          style={[
            styles.padding(10, 25, styles.getIphoneBottomPadder(100).height),
            styles.rounded(5)
          ].concat(style)}
        >
          <Loader
            size={OS === 'ios' ? 'large' : 35}
            color={styles.getColorCode('primary')}
          />
          {!!msg && (
            <Text style={[styles.marginTop(5)]} color={'primary'}>
              {msg}
            </Text>
          )}
        </View>
      </FullWrapper>
    );
  }
}
ActivityIndicator2.propTypes = {
  show: PropTypes.bool,
  style: PropTypes.any
};
