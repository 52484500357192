import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ModalizedInput from './input';
import ScrollHeaderLayout from '../../scrollHeaderLayout';
import {
  Keyboard,
  ScrollView,
  TouchableOpacity,
  View
} from '../../../../plugins';
import Text from '../../text';
import Small from '../small';
import { Row, Col } from '../../layout';
import styles from '../../../styles';
import DoneBar from './doneBar';
import Button from '../../button';
import Loading from '../../loading';
import Icon from '../../../icon';
import List, { ListItem } from '../../list';
import { TopPadder } from '../../iphonePadder';

export default class ModalizedInputUsers extends ModalizedInput {
  handler = undefined;
  state = {
    ...this.state,
    value: [],
    search: ''
  };
  componentDidMount() {
    this._mounted = true;
  }
  componentWillUnmount() {
    this._mounted = false;
  }
  onSearchInput = search => {
    this.setState({ search });
    clearTimeout(this.handler);
    this.handler = setTimeout(this.onSearchTrigger.bind(this), 1000);
  };
  onDisplayerPress = () => {
    const { value } = this.props;
    this.setState({ show: true, value: [...value] });
  };
  onSearchTrigger = () => {
    clearTimeout(this.handler);
    if (!this._mounted) return;

    const { onSearchUpdate } = this.props;
    const { search } = this.state;
    onSearchUpdate(search);
  };
  onClearPress = async () => {
    this.setState({ search: '' });
    clearTimeout(this.handler);
    this.handler = setTimeout(this.onSearchTrigger.bind(this), 250);
  };
  onDonePress = () => {
    const { value } = this.state;
    const { onChange } = this.props;
    this.onRequestClose();
    onChange(value);
  };
  onUnsetUserPress = () => {
    const { onChange } = this.props;
    onChange([]);
    this.onRequestClose();
  };
  onUserRowPress = ({ email, active }) => {
    const { value } = this.state,
      { items, maximum, onChange } = this.props;
    if (!!active) {
      this.setState({ value: value.filter(_user => _user.email !== email) });
    } else {
      const user = items.find(_user => _user.email === email);

      if (maximum === 1) {
        onChange([user]);
        this.onRequestClose();
      } else {
        if (value.length < maximum) {
          this.setState({ value: value.concat(user) });
        }
      }
    }
  };
  renderDisplayContent() {
    const { value } = this.props;
    if (value.length === 0)
      return <Text color={'border'}>No User Selected</Text>;
    return (
      <Row>
        <Col>
          {value.slice(0, 5).map(({ name, position }, i) => (
            <Row key={i} fy={'center'} style={[i > 0 && styles.marginTop(20)]}>
              {this.renderUserIcon(name)}
              <Col style={[styles.marginLeft(10)]}>
                <Text>{name}</Text>
                <Small style={[styles.bold]} color={'danger'}>
                  {position}
                </Small>
              </Col>
            </Row>
          ))}
        </Col>
        {value.length > 1 && (
          <Text style={[styles.flex(1), { textAlign: 'right' }]}>
            {value.length} Users
          </Text>
        )}
      </Row>
    );
  }
  renderUserIcon(name = '') {
    const label = name.charAt(0).toUpperCase();
    return (
      <View
        style={[
          styles.center,
          styles.shadow,
          styles.backgroundColor('border'),
          styles.rounded(15),
          { width: 30, height: 30 }
        ]}
      >
        <Text>{label}</Text>
      </View>
    );
  }
  renderDoneBar() {
    const { search } = this.state;
    const { maximum } = this.props;
    return (
      <Fragment>
        <TopPadder />
        <DoneBar
          style={[styles.block, styles.shadow, styles.header]}
          leftWidget={
            <Button transparent onPress={this.onRequestClose}>
              <Icon name={'arrow-left'} />
            </Button>
          }
          inputProps={{
            value: search,
            autoFocus: true,
            multiline: false,
            placeholder: 'Search',
            onChangeText: this.onSearchInput,
            style: [styles.border(0)],
            onBlur: this.onSearchTrigger,
            onSubmitEditing: this.onSearchTrigger
          }}
          widget={
            <Fragment>
              {!!search && (
                <Button
                  transparent
                  color={'danger'}
                  onPress={this.onClearPress}
                >
                  <Icon name={'times'} />
                </Button>
              )}
              <Button transparent onPress={this.onSearchTrigger}>
                <Icon name={'search'} />
              </Button>
            </Fragment>
          }
          onDonePress={maximum > 1 && this.onDonePress}
        />
      </Fragment>
    );
  }
  renderWidget() {
    const { search, value } = this.state;
    const { items, onBottom, hasNARow } = this.props;
    let rows = [
      ...(!search
        ? value.map(user => ({
            ...user,
            active: true
          }))
        : []),
      ...items.map(user => {
        const checked = !!value.find(
          _user => !!_user && _user.email === user.email
        );
        return checked ? null : { ...user, active: false };
      })
    ];
    try {
      rows = rows.filter(
        user =>
          !!user &&
          new RegExp(search, 'i').test(
            `${user.name},${user.position},${user.email}`
          )
      );
    } catch (e) {}
    return (
      <ScrollHeaderLayout
        style={[styles.flex(1)]}
        heightRange={[0, 0]}
        onBottom={onBottom}
      >
        <List>
          {!!hasNARow && (
            <UserRow name={'N/A'} onPress={this.onUnsetUserPress} />
          )}
          {rows.length === 0 && (
            <Col fx={'center'} style={[styles.block, styles.padding(10)]}>
              <Text color={'border'}>No Data</Text>
            </Col>
          )}
          {rows.map(
            (user, i) =>
              !!user && (
                <UserRow
                  key={user.email}
                  {...user}
                  last={i === rows.length - 1}
                  onPress={() => this.onUserRowPress(user)}
                />
              )
          )}
        </List>
      </ScrollHeaderLayout>
    );
  }
}
const UserRow = ({
  active,
  last,
  name = '',
  position = '',
  email = '',
  onPress
}) => (
  <ListItem
    title={`${name} ${!!email ? `(${email})` : ''}`}
    titleColor={!!active ? 'primary' : 'dark'}
    description={position}
    descriptionColor={'danger'}
    icon={!!active ? 'check' : ''}
    iconColor={'primary'}
    border={!last}
    onPress={onPress}
  />
);
ModalizedInputUsers.propTypes = {
  value: PropTypes.array,
  hasNARow: PropTypes.bool,
  onChange: PropTypes.func,
  items: PropTypes.array,
  isItemLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  maximum: PropTypes.number,
  onSearchUpdate: PropTypes.func,
  onBottom: PropTypes.func
};
ModalizedInputUsers.defaultProps = {
  onChange: _ => _,
  onSearchUpdate: _ => _,
  onBottom: _ => _,
  hasNARow: false,
  value: [],
  items: [],
  isItemLoading: false,
  maximum: 1
};
