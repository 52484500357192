import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormSyncTaskWorker from './formSyncTaskWorker';
import Loading from '../../rnbc/components/loading';
import NetworkStatus from '../../rnbc/components/networkStatus';
import Icon from '../../rnbc/icon';
import styles from '../../rnbc/styles';

export default class FieldLoading extends Component {
  state = {
    isOnline: true
  };
  componentDidMount() {
    this._mounted = true;
    this.startNetworkCheckingInterval().then();
  }
  componentWillUnmount() {
    this._mounted = false;
  }

  startNetworkCheckingInterval = async () => {
    if (!this._mounted) return;
    try {
      await this.updateNetworkStatus();
    } catch (e) {
    } finally {
      setTimeout(this.startNetworkCheckingInterval, 1000);
    }
  };

  updateNetworkStatus = async () => {
    this.setState({
      isOnline: NetworkStatus.isOnline()
    });
  };

  render() {
    const { style, color } = this.props,
      { isOnline } = this.state;
    if (isOnline)
      return (
        <Loading
          iosSize={'small'}
          style={[styles.padding(0)].concat(style)}
          color={color}
        />
      );
    return (
      <Icon
        style={style}
        name={'wifi-slash'}
        color={color}
        size={styles.h5.fontSize}
      />
    );
  }
}
FieldLoading.propTypes = {
  style: PropTypes.any,
  color: PropTypes.string
};
FieldLoading.defaultProps = {
  color: 'primary'
};
