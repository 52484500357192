import React from 'react';
import { Svg, Path } from '../../../plugins';

export default ({ style, color, size }) => {
  return (
    <Svg
      style={style}
      width={size}
      height={size}
      viewBox={'0 0 640 512'}
      version='1.1'
    >
      <Path
        fill={color}
        d='M324.2 320.4c-1.4-.1-2.8-.4-4.2-.4-44.2 0-80 35.8-80 80s35.8 80 80 80 80-35.8 80-80c0-8-1.5-15.5-3.7-22.8zM320 448c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM3.8 158c-4.9 4.7-5.1 12.5-.3 17.3l5.7 5.7c4.6 4.6 12.1 4.7 16.8.3 19.1-18.1 39.5-33.9 60.8-47.8l-26.4-20.8C40.8 126.3 21.7 141 3.8 158zM614 181.3c4.7 4.5 12.2 4.4 16.8-.3l5.7-5.7c4.8-4.8 4.7-12.6-.3-17.3C503.6 32.2 314.1.6 152.9 63.3l30.2 23.8C328.9 37.9 495.8 69.2 614 181.3zm-297.5 10.8l44.6 35.2c51.8 7.7 101.8 29.8 143.3 66.7 4.8 4.3 12.2 4 16.6-.7l5.5-5.8c4.7-4.9 4.4-12.7-.7-17.2-59.4-53-134.4-79-209.3-78.2zM637 485.2L23 1.8C19.6-1 14.5-.5 11.8 3l-10 12.5C-1 19-.4 24 3 26.7l614 483.5c3.4 2.8 8.5 2.2 11.2-1.2l10-12.5c2.8-3.5 2.3-8.5-1.2-11.3zM114 270.3c-5 4.5-5.3 12.3-.6 17.2l5.5 5.8c4.5 4.7 11.8 5 16.7.7 25.8-23 55.7-40.9 88.1-52.8L195 218.6c-29 12.7-56.4 29.8-81 51.7z'
      />
    </Svg>
  );
};
