import React from 'react';
import { ReactApollo } from '../../../plugins';
import { Flex, Row, Responsively, Col } from '../../../rnbc/components/layout';
import Loading from '../../../rnbc/components/loading';
import RefreshButton from '../../../rnbc/components/refreshButton';
import Dropdown from '../../../rnbc/components/dropdown';
import Text from '../../../rnbc/components/text';
import TextSkeleton from '../../../rnbc/components/textSkeleton';
import { GET_BADGES } from './_gql';
import styles from '../../../rnbc/styles';
import Button from '../../../rnbc/components/button';
import NavHeader from '../../../rnbc/components/navHeader';
import Pager from './_pager';

export default ({
  cursors = [],
  nextCursor,
  totalCount = 0,
  currentCount = 0,
  projectId,
  formId,
  filterId,
  filters = [],
  onFilterChange = _ => _,
  loading,
  onRefreshPress = _ => _,
  onNextPress = _ => _,
  onPrevPress = _ => _,
  onFirstPress = _ => _
}) => {
  const currentFilter = filters.find(filter => filter.id === filterId) || {};
  return (
    <Responsively
      layout={{ md: Row, sm: Col }}
      style={{
        md: [styles.backgroundColor('primary'), styles.header],
        sm: [
          styles.backgroundColor('primary'),
          { height: styles.header.height * 2 }
        ]
      }}
    >
      <ReactApollo.Query
        query={GET_BADGES(projectId, formId, filters.map(view => view.id))}
        fetchPolicy={
          NavHeader.lastBackReferrer.match('/Revision/')
            ? 'cache-first'
            : 'cache-and-network'
        }
      >
        {({ loading, data }) => {
          if (!!loading && !data) return <Loading />;
          const { me } = data || {},
            form = ((me || {})[`p_${projectId.replace(/-/g, '_')}`] || {})[
              `f_${formId.replace(/-/g, '_')}`
            ];

          const currentFilterKey = `revs_${(currentFilter.id || '').replace(
              /\-/g,
              '_'
            )}`,
            currentFilterCount = ((form || {})[currentFilterKey] || []) - 0;

          return (
            <Dropdown
              title={
                'Filter: ' +
                (currentFilter.label || '') +
                (currentFilterCount ? ` (${currentFilterCount})` : '')
              }
            >
              {filters.map(({ label, id }, i) => {
                const key = `revs_${id.replace(/\-/g, '_')}`;
                const count = ((form || {})[key] || []) - 0;
                return (
                  <Button
                    key={i}
                    onPress={() => {
                      onFilterChange(filters[i]);
                    }}
                    transparent
                    style={[
                      styles.margin(0),
                      styles.rounded(0),
                      { justifyContent: 'flex-start' }
                    ]}
                    color={'light'}
                  >
                    <Text color={'primary'}>{label}</Text>
                    {!!count && (
                      <Text color={'primary'} style={[styles.marginLeft(5)]}>
                        ({count})
                      </Text>
                    )}
                  </Button>
                );
              })}
            </Dropdown>
          );
        }}
      </ReactApollo.Query>
      <Row fy={'center'} flex={1} style={[styles.header]}>
        <Pager
          style={[styles.flex(1)]}
          cursors={cursors}
          nextCursor={nextCursor}
          totalCount={totalCount}
          currentCount={currentCount}
          onNextPress={onNextPress}
          onPrevPress={onPrevPress}
          onFirstPress={onFirstPress}
        />
        <RefreshButton
          isLoading={loading}
          color={'light'}
          onPress={onRefreshPress}
        />
      </Row>
    </Responsively>
  );
};

export const FilterSkeleton = () => {
  return (
    <Row
      style={[styles.block, styles.backgroundColor('primary'), styles.header]}
    >
      <Flex style={[styles.padding(10)]}>
        <TextSkeleton rounded={50} opacity={0.1} />
      </Flex>
      <RefreshButton color={'light'} isLoading={true} />
    </Row>
  );
};
