import React, { Component } from 'react';
import {
  AsyncStorage,
  KeyboardAwareScrollView,
  OS,
  Image,
  DeviceInfo,
  Config,
  CompareVersions,
  Alert as AlertModal,
  Linking,
  Sentry
} from '../../../plugins';
import Button from '../../../rnbc/components/button';
import Form, {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAppend,
  Small
} from '../../../rnbc/components/form';
import Formilize from '../../../rnbc/components/formilize';
import Text from '../../../rnbc/components/text';
import H5 from '../../../rnbc/components/h5';
import { Col, Row, Flex } from '../../../rnbc/components/layout';
import Icon from '../../../rnbc/icon';
import styles from '../../../rnbc/styles';
import {
  client,
  checkTokenAvailable,
  clear_ROOT_MUTATION
} from '../../shared/apollo';
import { errorParser } from '../../shared/errorParser';
import Alert from '../../../rnbc/components/alert';
import OneSignalWorker from '../../../rnbc/components/oneSignalWorker';
import FileTaskWorker from '../../component/fileTaskWorker';
import FormSyncTaskWorker from '../../component/formSyncTaskWorker';
import { LOGIN } from './_gql';
import ActivityIndicator from '../../../rnbc/components/activityIndicator';
import FullWrapper from '../../../rnbc/components/fullWrapper';
import * as Biometric from '../../../rnbc/components/biometric';
import { GET_VERSION } from './_gql';

const isTC = !!(Config.REACT_APP_IS_TC - 0);

export default class extends Component {
  state = {
    defaultEmail: '',
    token: '',
    sensorType: null
  };
  async componentDidMount() {
    const defaultEmail = await AsyncStorage.getItem('email'),
      token = await AsyncStorage.getItem('token');
    this.setState({
      defaultEmail,
      token,
      sensorType: await Biometric.sensorType()
    });
    ActivityIndicator.show();
    await new Promise(resolve => setTimeout(resolve, 500));
    await FileTaskWorker.addTask();
    await FormSyncTaskWorker.addTask();
    ActivityIndicator.hide();

    if (!!token) await this.verifyIdentity();

    try {
      if (window.crash_test) window.location.reload(true);
    } catch (e) {}
  }
  updateSentryScope = ({ user }) => {
    try {
      Sentry.configureScope(scope => {
        !!user && scope.setUser({ id: user });
      });
    } catch (e) {}
  };
  verifyIdentity = async () => {
    const { history } = this.props;
    const { defaultEmail } = this.state;
    this.updateSentryScope({ user: defaultEmail });
    const token = await AsyncStorage.getItem('token');
    if (checkTokenAvailable(token)) {
      if (!!token && (await Biometric.simple(`Authorize to ${defaultEmail}`))) {
        !!isTC && (await client.resetStore());
        history.replace('/Projects');
        this.handleVersion();
      }
    } else {
      Alert.danger('Login Expired');
      this.setState({ token: '' });
    }
  };

  handleVersion = async () => {
    try {
      const { data: { version } = {} } = await client.query({
        query: GET_VERSION,
        fetchPolicy: 'no-cache'
      });
      const { current, alertMessage } = version || {};
      const currentVersion = await DeviceInfo.getVersion();
      if (CompareVersions(currentVersion, current) === -1) {
        switch (OS) {
          case 'ios':
            await new Promise((resolve, reject) =>
              AlertModal.alert(
                '',
                alertMessage || 'Find Newer Version',
                [
                  {
                    text: 'OK',
                    onPress: reject
                  }
                ],
                { cancelable: false }
              )
            );
            // Alert.info('Please Update from TestFlight!');
            // const res = await fetch(
            //     `https://itunes.apple.com/hk/lookup?bundleId=${await DeviceInfo.getBundleId()}`
            //   ),
            //   { results } = (await res.json()) || {},
            //   { trackId } = (results || [])[0] || {};
            // await Linking.openURL(
            //   `itms-apps://itunes.apple.com/hk/app/id${trackId}?mt=8`
            // );
            break;
          case 'android':
            await new Promise((resolve, reject) =>
              AlertModal.alert(
                '',
                alertMessage || 'Find Newer Version',
                [
                  { text: 'Ignore', onPress: reject },
                  { text: 'Update', onPress: resolve }
                ],
                { cancelable: false }
              )
            );
            await Linking.openURL(
              `http://play.google.com/store/apps/details?id=${await DeviceInfo.getBundleId()}`
            );
            break;
          case 'web':
            break;
        }
      }
    } catch (e) {}
  };

  render() {
    const { history } = this.props,
      { defaultEmail, token, sensorType } = this.state;
    return (
      <KeyboardAwareScrollView
        scrollEnabled={OS === 'web'}
        style={[styles.backgroundColor('black')]}
      >
        <FullWrapper style={[styles.padding(10), styles.row, styles.center]}>
          <Col style={[styles.fill, { maxWidth: 500 }]}>
            <Flex>
              <Image
                style={[styles.fill]}
                resizeMode={'contain'}
                source={require('../../image/simple-black-logo.png')}
              />
            </Flex>
            <Formilize
              initialValues={{
                email: defaultEmail,
                password: ''
              }}
              onSubmit={async values => {
                try {
                  await AsyncStorage.removeItem('token');
                  const oneSignalPlayerId = OneSignalWorker.getPlayerId();
                  console.log({
                    ...values,
                    oneSignalPlayerId
                  });
                  const {
                    data: { signin }
                  } = await client.mutate({
                    mutation: LOGIN,
                    variables: {
                      ...values,
                      oneSignalPlayerId: oneSignalPlayerId
                        ? `${isTC ? 'TC' : 'DEFAULT'}/${oneSignalPlayerId}`
                        : undefined
                    },
                    fetchPolicy: 'no-cache'
                  });
                  if (!signin) throw new Error('Login Failed');
                  await AsyncStorage.setItem('token', `${signin}`);
                  await AsyncStorage.setItem('email', `${values.email}`);
                  this.updateSentryScope({ user: values.email });
                  !!isTC && (await client.resetStore());
                  await clear_ROOT_MUTATION();
                  history.replace('/Projects');
                  this.handleVersion();
                } catch (e) {
                  console.log(e);
                  Alert.danger(errorParser(e));
                }
              }}
            >
              {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
                <Form
                  style={[styles.flex(1), styles.column, styles.padding(20)]}
                >
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAppend>
                        <Icon name={'user'} />
                      </InputGroupAppend>
                      <Input
                        style={[styles.flex(1)]}
                        value={values.email}
                        disabled={isSubmitting}
                        autoCompleteType={'email'}
                        keyboardType={'email-address'}
                        autoFocus={true}
                        onChangeText={value => {
                          setFieldValue('email', value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAppend>
                        <Icon name={'lock-alt'} />
                      </InputGroupAppend>
                      <Input
                        style={[styles.flex(1)]}
                        value={values.password}
                        disabled={isSubmitting}
                        autoCompleteType={'password'}
                        secureTextEntry={true}
                        onChangeText={value => setFieldValue('password', value)}
                        onSubmitEditing={handleSubmit}
                      />
                    </InputGroup>
                  </FormGroup>
                  <Button onPress={handleSubmit} loading={isSubmitting}>
                    <Text>LOGIN</Text>
                  </Button>
                  {!!token &&
                    {
                      TouchID: (
                        <Button
                          transparent
                          color={'light'}
                          style={[styles.marginRight(5)]}
                          onPress={this.verifyIdentity}
                        >
                          <Icon name={'fingerprint'} />
                          <Text>Touch ID</Text>
                        </Button>
                      ),
                      FaceID: (
                        <Button
                          transparent
                          color={'light'}
                          onPress={this.verifyIdentity}
                        >
                          <Icon
                            name={'faceid'}
                            size={24}
                            style={[styles.marginRight(5)]}
                          />
                          <Text>Face ID</Text>
                        </Button>
                      )
                    }[sensorType]}
                  <Flex />
                  {OS === 'ios' && (
                    <Row style={[styles.marginBottom(30)]} fx={'flex-end'}>
                      <Button
                        transparent
                        onPress={() => {
                          history.push('/Register');
                        }}
                        disabled={isSubmitting}
                      >
                        <H5>Quick Sign Up</H5>
                        <Icon name={'arrow-right'} />
                      </Button>
                    </Row>
                  )}
                  <Small color={'light'} style={[{ textAlign: 'center' }]}>
                    v{DeviceInfo.getVersion()}
                  </Small>
                </Form>
              )}
            </Formilize>
          </Col>
        </FullWrapper>
      </KeyboardAwareScrollView>
    );
  }
}
