import React from 'react';
import Tooltip from '../../rnbc/components/toolTips';
import Text from '../../rnbc/components/text';
import { Row, Col } from '../../rnbc/components/layout';
import styles from '../../rnbc/styles';
import { OS } from '../../plugins';

export default ({ userGroup, userPositions = [] }) => {
  const hasUserGroupCriteria = !!userGroup,
    hasUserPositionsCriteria = !!userPositions && userPositions.length > 0;

  if (!hasUserGroupCriteria && !hasUserPositionsCriteria) return null;
  return (
    <Tooltip mode={OS === 'web' ? 'modal' : 'drawer'}>
      {!!hasUserGroupCriteria && (
        <Row>
          <Text style={[styles.marginRight(5)]}>Group:</Text>
          <Text>{userGroup}</Text>
        </Row>
      )}
      {!!hasUserPositionsCriteria && (
        <Row>
          <Text style={[styles.marginRight(5)]}>Positions:</Text>
          <Col>
            {userPositions.map((pos, i) => (
              <Text key={i}>{pos}</Text>
            ))}
          </Col>
        </Row>
      )}
    </Tooltip>
  );
};
