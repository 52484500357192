import { variations as approvalVariations } from '../../component/approvalField';
import moment from 'moment';

export default (field, _row) => {
  const { __typename } = field;
  switch (__typename) {
    case 'UserField':
      const users = field.users || [];
      if (users.length > 0) {
        if (users.length > 1) return `${users[0].name} (${users.length} users)`;
        return users[0].name;
      }
      return '';
    case 'ApprovalField':
      const variations = approvalVariations[field.numberOfButton || 4];
      if (field.status === 'REJECTED') {
        _row._color = 'danger';
        if (!_row.hasResubmittingRevision) _row._hasResubmitButton = true;
      }
      return (variations.find(({ value }) => value === field.status) || {})
        .label;
    case 'LocationField':
      const { x, y, radius, latitude, longitude, altitude, ...others } =
        field.location || {};
      return Object.keys(others)
        .map(key => (!~['__typename'].indexOf(key) ? others[key] : ''))
        .filter(_ => _)
        .join(' ');
    case 'FileField':
      const files = field.files || [];
      if (files.length > 0) {
        if (files.length > 1) return `${files.length} files`;
        return files[0].key;
      }
      return '';
    case 'SignatureField':
      return !!field.updatedBy && !!field.updatedBy.name
        ? field.updatedBy.name
        : '';
    case 'DateTimeField':
      if (!!field.dateTime)
        return moment(field.dateTime).format('YYYY-MM-DD hh:mm A');
      return '';
    case 'TextField':
      return field.text;
    case 'EnumField':
      return field.value;
    case 'ChopField':
      if (!!field.chopped && !!field.updatedAt)
        return moment(field.updatedAt).format('YYYY-MM-DD hh:mm A');
      return '';
    case 'WithdrawButton':
      if (!!_row.withdrawnBy) {
        if (!!_row.finalized)
          _row._textStyle = { textDecorationLine: 'line-through' };
        return _row.withdrawnBy.name;
      }
      return '';
    case 'SubmitButton':
      if (!!field.updatedAt)
        return moment(field.updatedAt).format('YYYY-MM-DD hh:mm A');
      return '';
  }
  return field.value;
};
