import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { ReactApollo } from '../../plugins';
import Loading from './loading';
import Text from './text';
import Alert from './alert';
import styles from '../styles';
import { Col } from './layout';
import Button from './button';

export default class CursorBasedList extends Component {
  state = {
    caches: [],
    cursor: undefined
  };
  componentWillReceiveProps(nextProps) {
    if (isDifferent(nextProps.filter, this.props.filter))
      this.setState({ cursor: undefined });

    function isDifferent(obj1, obj2) {
      if (!obj1 && !obj2) return false;
      else if (!obj1 && !!obj2) return true;
      else if (!!obj1 && !obj2) return true;
      else if (Object.keys(obj1).length !== Object.keys(obj2).length)
        return true;
      for (let key of Object.keys(obj1)) {
        if (obj1[key] !== obj2[key]) {
          return true;
        }
      }
      return false;
    }
  }

  render() {
    const { caches, cursor } = this.state;
    const {
      style,
      query,
      fetchPolicy,
      getConnection,
      limits,
      variables,
      filter,
      renderItem,
      children
    } = this.props;

    const functionMode = typeof children === 'function';
    return (
      <Fragment>
        {caches.map(renderItem)}
        <ReactApollo.Query
          query={query}
          fetchPolicy={caches.length === 0 ? fetchPolicy : 'network-only'}
          variables={{
            cursor,
            limits,
            filter,
            ...variables
          }}
        >
          {({ loading, data, error }) => {
            let isCacheAvailable = false;
            if (!!data && caches.length === 0) {
              try {
                getConnection(data);
                isCacheAvailable = true;
              } catch (e) {}
            }

            if (!!loading && !isCacheAvailable) {
              if (functionMode)
                return children({
                  loading,
                  style,
                  caches,
                  nodes: [],
                  items: caches.concat(),
                  nextCursor: null,
                  totalCount: caches.length,
                  loadMore: () => {},
                  refresh: () => {}
                });
              return <Loading style={[styles.block]} />;
            }

            try {
              if (!!error) throw new Error(error.message);
              let connection = { nextCursor: null, totalCount: 0, nodes: [] };
              try {
                connection = getConnection(data);
              } catch (e) {}
              const { nextCursor, totalCount, nodes } = connection;

              if (functionMode)
                return children({
                  loading,
                  caches,
                  style,
                  nodes,
                  items: caches.concat(nodes),
                  nextCursor,
                  totalCount,
                  loadMore: () => {
                    !!nextCursor &&
                      this.setState({
                        cursor: nextCursor,
                        caches: caches.concat(nodes)
                      });
                  },
                  refresh: () => {
                    this.setState({
                      cursor: undefined,
                      caches: []
                    });
                  }
                });
              return (
                <Fragment>
                  {nodes.map(renderItem)}
                  {totalCount === 0 && (
                    <Col
                      fx={'center'}
                      style={[styles.block, styles.padding(10)]}
                    >
                      <Text color={'border'}>No Data</Text>
                    </Col>
                  )}
                  {!!nextCursor && (
                    <Button
                      transparent
                      style={[styles.block]}
                      onPress={() => {
                        this.setState({
                          cursor: nextCursor,
                          caches: caches.concat(nodes)
                        });
                      }}
                    >
                      <Text>Load More</Text>
                    </Button>
                  )}
                </Fragment>
              );
            } catch (e) {
              // Alert.danger(e.message.replace(/GraphQL error:/gi, ''));
              if (functionMode)
                return children({
                  loading,
                  style,
                  caches,
                  nodes: [],
                  items: caches.concat(),
                  nextCursor: null,
                  totalCount: caches.length,
                  loadMore: () => {},
                  refresh: () => {}
                });
              return (
                <Col fx={'center'} style={[styles.block, styles.padding(10)]}>
                  <Text color={'border'}>No Data</Text>
                </Col>
              );
            }
          }}
        </ReactApollo.Query>
      </Fragment>
    );
  }
}

CursorBasedList.propTypes = {
  query: PropTypes.any,
  fetchPolicy: PropTypes.string,
  getConnection: PropTypes.func,
  limits: PropTypes.number,
  filter: PropTypes.object,
  variables: PropTypes.object,
  renderItem: PropTypes.func,
  style: PropTypes.any
};
CursorBasedList.defaultProps = {
  getConnection: _ => _,
  fetchPolicy: 'cache-and-network',
  limits: 20,
  variables: {},
  renderItem: (item, i) => null
};
