import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, OS, Orientation } from '../../plugins';
import styles from '../styles';

export default class FullWrapper extends Component {
  handler = undefined;
  state = {
    width: styles.vw(1).width,
    height: styles.vh(1).height
  };
  componentDidMount() {
    this._mounted = true;
    if (OS === 'web') {
      window.addEventListener('orientationchange', this.onOrientationChange);
      window.addEventListener('resize', this.onOrientationChange);
    } else if (!!Orientation)
      Orientation.addOrientationListener(this.onOrientationChange);
  }
  componentWillUnmount() {
    this._mounted = false;
    if (OS === 'web') {
      window.addEventListener('orientationchange', this.onOrientationChange);
      window.removeEventListener('resize', this.onOrientationChange);
    } else if (!!Orientation)
      Orientation.removeOrientationListener(this.onOrientationChange);
  }
  onOrientationChange = () => {
    clearTimeout(this.handler);
    this.handler = setTimeout(this.onResize, 500);
  };
  onResize = () => {
    const { onResize } = this.props;
    if (!this._mounted) return;

    const width = styles.vw(1).width,
      height = styles.vh(1).height;
    this.setState({
      width,
      height
    });
    onResize({
      width,
      height
    });
  };

  render() {
    const { children, style, ...props } = this.props,
      { width, height } = this.state;
    if (typeof children === 'function') return children({ width, height });
    return (
      <View style={[{ width, height }].concat(style)} {...props}>
        {children}
      </View>
    );
  }
}
FullWrapper.propTypes = {
  onResize: PropTypes.func
};
FullWrapper.defaultProps = {
  onResize: _ => _
};
