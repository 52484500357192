import React, { Fragment } from 'react';
import H4 from '../../rnbc/components/h4';
import HR from '../../rnbc/components/hr';

export default ({ label }) => (
  <Fragment>
    <H4>{label}</H4>
    <HR />
  </Fragment>
);
