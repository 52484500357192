import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { OS, ScrollView, TouchableOpacity, View } from '../../../../plugins';
import List, { ListItem } from '../../list';
import ModalizedInput from './input';
import DoneBar from './doneBar';
import styles from '../../../styles';
import Button from '../../button';
import Icon from '../../../icon';
import { Col } from '../../layout';
import Small from '../small';
import { BottomPadder, TopPadder } from '../../iphonePadder';

export default class ModalizedInputSelect extends ModalizedInput {
  state = {
    ...this.state,
    search: this.props.value || ''
  };
  onSearchChangeText = search => {
    this.setState({ search });
  };
  onSubmitEditing = search => {
    const { onChangeText } = this.props;
    this.setState({ search });
    onChangeText && onChangeText(search);
    this.onRequestClose();
  };
  onClearPress = () => {
    this.setState({ search: '' });
  };
  renderDisplayer() {
    const {
      style,
      multiline,
      disabled,
      issuer,
      entitle,
      issuedAt
    } = this.props;
    return (
      <TouchableOpacity
        activeOpacity={!disabled ? 0.5 : 1}
        onPress={!disabled && this.onDisplayerPress}
        style={[
          styles.row,
          styles.border(1),
          styles.rounded(5),
          styles.backgroundColor(!disabled ? 'white' : 'bg'),
          styles.padding(10, 12),
          { minHeight: 35, position: 'relative' }
        ].concat(style)}
      >
        <Col flex={1}>{this.renderDisplayContent()}</Col>
        <Col
          fx={'flex-end'}
          fy={'flex-end'}
          style={[styles.padding(0, -7, 0, 0)]}
        >
          <Small>{issuer}</Small>
          <Small style={[styles.color('danger'), styles.bold]}>{entitle}</Small>
          <Small>{issuedAt}</Small>
        </Col>
      </TouchableOpacity>
    );
  }
  renderDoneBar() {
    const { autoFocus, multiline } = this.props,
      { search } = this.state;
    return (
      <Fragment>
        <TopPadder />
        <DoneBar
          leftWidget={
            <Button transparent onPress={this.onRequestClose}>
              <Icon name={'arrow-left'} />
            </Button>
          }
          onDonePress={() => this.onSubmitEditing(search)}
          widget={
            !!search && (
              <Button transparent color={'danger'} onPress={this.onClearPress}>
                <Icon name={'times'} />
              </Button>
            )
          }
          inputProps={{
            value: search,
            autoFocus,
            multiline,
            onChangeText: this.onSearchChangeText,
            onSubmitEditing: !multiline && (() => this.onSubmitEditing(search)),
            style: [styles.border(0)]
          }}
        />
      </Fragment>
    );
  }
  renderWidget() {
    const { items, multiline } = this.props,
      { search } = this.state;
    let filtered = items;
    try {
      filtered = items.filter(str => new RegExp(search, 'i').test(str));
    } catch (e) {}

    return (
      <ScrollView>
        <List>
          {filtered.length === 0 && (
            <ListItem
              title={search || ''}
              style={[!!multiline && { height: 'auto' }]}
              titleColor={'primary'}
              icon={''}
              border={false}
              onPress={() => this.onSubmitEditing(search)}
            />
          )}
          {filtered.map((str, i) => (
            <ListItem
              key={i}
              title={str}
              style={[!!multiline && { height: 'auto' }]}
              titleColor={'primary'}
              icon={''}
              border={i !== filtered.length - 1}
              onPress={() => this.onSubmitEditing(str)}
            />
          ))}
        </List>
        <BottomPadder />
      </ScrollView>
    );
  }
}
ModalizedInputSelect.propTypes = {
  ...ModalizedInput.propTypes,
  items: PropTypes.array,
  issuer: PropTypes.string,
  entitle: PropTypes.string,
  issuedAt: PropTypes.string
};
ModalizedInputSelect.defaultProps = {
  ...ModalizedInput.defaultProps,
  value: '',
  issuer: '',
  entitle: '',
  issuedAt: '',
  items: [
    'test1',
    'test2',
    'test3',
    'test4',
    'test5',
    'test6',
    'test7',
    'test8',
    'test9',
    'test10'
  ]
};
