import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator as Loader, View, OS } from '../../plugins';
import styles from '../styles';
import { Col } from './layout';

let inst;
export default class ActivityIndicator extends Component {
  state = {
    show: this.props.show || false
  };

  componentDidMount() {
    inst = this;
  }
  componentWillUnmount() {
    inst = null;
  }

  static show = () => {
    !!inst && inst.show();
  };

  static hide = () => {
    !!inst && inst.hide();
  };

  show() {
    this.setState({ show: true });
  }

  hide() {
    this.setState({ show: false });
  }

  render() {
    const { show: visibleFromProps, style } = this.props;
    const { show: visibleFromState } = this.state;
    if (!visibleFromProps && !visibleFromState) return null;

    return (
      <Col
        fx={'center'}
        fy={'center'}
        style={[
          styles.backgroundColor('rgba(0,0,0,0)'),
          styles.absolute({ top: 0, left: 0, right: 0, bottom: 0 })
        ]}
      >
        <View style={[styles.padding(10, 25), styles.rounded(5)].concat(style)}>
          <Loader
            size={OS === 'ios' ? 'large' : 35}
            color={styles.getColorCode('primary')}
          />
        </View>
      </Col>
    );
  }
}
ActivityIndicator.propTypes = {
  show: PropTypes.bool,
  style: PropTypes.any
};
