import React, { Component } from 'react';
import styles from '../../../rnbc/styles';
import { Responsively, Col, Row } from '../../../rnbc/components/layout';
import Loading from '../../../rnbc/components/loading';
import H1 from '../../../rnbc/components/h1';
import Text from '../../../rnbc/components/text';
import { BottomPadder } from '../../../rnbc/components/iphonePadder';
import Card, { CardBody } from '../../../rnbc/components/card';
import ChartPie from '../../../rnbc/components/chart/pie';
import List, { ListItem } from '../../../rnbc/components/list';
import NavHeader from '../../../rnbc/components/navHeader';
import { ReactApollo, ScrollView, View } from '../../../plugins';
import { GET_REPORT } from './_gql';
import {
  InputGroup,
  InputGroupAppend,
  ModalizedInputDate
} from '../../../rnbc/components/form';
import moment from 'moment';
import Icon from '../../../rnbc/icon';
import _ from 'lodash';

export default class extends Component {
  state = {
    random: 0,
    fromDay: moment()
      .day(0)
      .startOf('day')
      .toISOString(),
    toDay: moment()
      .startOf('day')
      .toISOString()
  };
  render() {
    const { history, match: { params } = {} } = this.props,
      { projectId } = params || {},
      { fromDay, toDay, random } = this.state;
    return (
      <Col style={[styles.blockh]}>
        <NavHeader title={'Report'} back history={history} />
        <ScrollView>
          <View style={[styles.padding(20, 10)]}>
            <InputGroup style={[styles.block]}>
              <InputGroupAppend>
                <Icon name={'calendar-alt'} />
                <Text style={[styles.marginLeft(10)]}>From</Text>
              </InputGroupAppend>
              <ModalizedInputDate
                value={fromDay}
                style={[styles.flex(1)]}
                onChange={fromDay =>
                  this.setState({ fromDay, random: Math.random() })
                }
              />
              <InputGroupAppend>
                <Text>To</Text>
              </InputGroupAppend>
              <ModalizedInputDate
                value={toDay}
                style={[styles.flex(1)]}
                onChange={toDay =>
                  this.setState({ toDay, random: Math.random() })
                }
              />
            </InputGroup>
          </View>
          <ReactApollo.Query
            key={random}
            query={GET_REPORT}
            fetchPolicy={'cache-and-network'}
            variables={{
              projectId,
              startedAt: moment(fromDay).format('YYYY-MM-DD'),
              startedThru: moment(toDay).format('YYYY-MM-DD'),
              weekStartedAt: moment()
                .day(0)
                .format('YYYY-MM-DD'),
              now: moment().format('YYYY-MM-DD')
            }}
          >
            {({ loading, data }) => {
              if (!!loading && !data) return <Loading />;
              const { me } = data || {};
              const NOS_OF_FAILED = getMetricDataFromMe(
                'NOS_OF_FAILED',
                me
              ).reduce((reducer, { value }) => reducer + (value - 0), 0);
              const NOS_OF_SUBMITTED = getMetricDataFromMe(
                'NOS_OF_SUBMITTED',
                me
              ).reduce((reducer, { value }) => reducer + (value - 0), 0);
              const NOS_OF_PENDING_INSPECTION = getMetricDataFromMe(
                'NOS_OF_PENDING_INSPECTION',
                me
              ).reduce((reducer, { value }) => reducer + (value - 0), 0);
              const NOS_OF_AWAITING_REVIEW = getMetricDataFromMe(
                'NOS_OF_AWAITING_REVIEW',
                me
              ).reduce((reducer, { value }) => reducer + (value - 0), 0);
              const NOS_OF_PASSED = getMetricDataFromMe(
                'NOS_OF_PASSED',
                me
              ).reduce((reducer, { value }) => reducer + (value - 0), 0);
              const NQAA = getMetricDataFromMe('NQAA', me).reduce(
                (reducer, { value }) => reducer + (value - 0),
                0
              );
              const AVERAGE_PASS_RATE =
                (100 *
                  getMetricDataFromMe('AVERAGE_PASS_RATE', me).reduce(
                    (reducer, { value }) => reducer + (value - 0),
                    0
                  )) /
                (getMetricDataFromMe('AVERAGE_PASS_RATE', me).length || 1);
              const AVERAGE_PROGRESSING_TIME =
                getMetricDataFromMe('AVERAGE_PROGRESSING_TIME', me).reduce(
                  (reducer, { value }) => reducer + (value - 0),
                  0
                ) /
                (getMetricDataFromMe('AVERAGE_PROGRESSING_TIME', me).length ||
                  1);
              const TOTAL_AVERAGE_PASS_RATE =
                (getMetricDataFromMe('TOTAL_AVERAGE_PASS_RATE', me)[0] || {})
                  .value || 0;

              const SUB_DISCIPLINES = _.groupBy(
                getMetricDataFromMe('SUB_DISCIPLINES', me),
                'name'
              );
              Object.keys(SUB_DISCIPLINES).forEach(key => {
                const arr = SUB_DISCIPLINES[key];
                SUB_DISCIPLINES[key] = arr.reduce(
                  (reducer, { value }) => reducer + (value - 0),
                  0
                );
              });
              const SUB_DISCIPLINES_TOTAL = Object.values(
                SUB_DISCIPLINES
              ).reduce((reducer, value) => reducer + (value - 0), 0);

              return (
                <Col>
                  <Responsively
                    layout={{
                      xs: Col,
                      sm: Row
                    }}
                    spacing={10}
                    style={[styles.padding(10)]}
                  >
                    <Card style={[styles.flex(1)]}>
                      <CardBody>
                        <H1>{NOS_OF_FAILED}</H1>
                        <Text>Failed</Text>
                      </CardBody>
                    </Card>
                    <Card style={[styles.flex(1)]}>
                      <CardBody>
                        <H1>{NOS_OF_SUBMITTED}</H1>
                        <Text>Submitted</Text>
                      </CardBody>
                    </Card>
                    <Card style={[styles.flex(1)]}>
                      <CardBody>
                        <H1>{NOS_OF_PENDING_INSPECTION}</H1>
                        <Text>Pending Inspection</Text>
                      </CardBody>
                    </Card>
                    <Card style={[styles.flex(1)]}>
                      <CardBody>
                        <H1>{NOS_OF_AWAITING_REVIEW}</H1>
                        <Text>Awaiting Review</Text>
                      </CardBody>
                    </Card>
                    <Card style={[styles.flex(1)]}>
                      <CardBody>
                        <H1>{NOS_OF_PASSED}</H1>
                        <Text>Passed Review</Text>
                      </CardBody>
                    </Card>
                  </Responsively>
                  <Responsively
                    layout={{ lg: Row, default: Col }}
                    style={{ flexWrap: 'wrap' }}
                  >
                    <View>
                      <ChartPie
                        data={[
                          { label: 'Failed', value: NOS_OF_FAILED },
                          { label: 'Submitted', value: NOS_OF_SUBMITTED },
                          {
                            label: 'Pending Inspection',
                            value: NOS_OF_PENDING_INSPECTION
                          },
                          {
                            label: 'Awaiting Review',
                            value: NOS_OF_AWAITING_REVIEW
                          },
                          { label: 'Passed', value: NOS_OF_PASSED }
                        ]}
                      />
                    </View>
                    <List style={[styles.flex(1), { minWidth: 300 }]}>
                      {Object.keys(SUB_DISCIPLINES)
                        .filter(key => !!SUB_DISCIPLINES[key])
                        .map((key, i, arr) => {
                          const value = SUB_DISCIPLINES[key];
                          const percentage =
                            (100 * value) / (SUB_DISCIPLINES_TOTAL || 1);
                          return (
                            <ListItem
                              key={i}
                              title={key}
                              border={i < arr.length - 1}
                              value={`${value} (${percentage.toFixed(0)}%)`}
                            />
                          );
                        })}
                    </List>
                  </Responsively>
                  <Responsively
                    layout={{ xs: Col, sm: Row }}
                    spacing={10}
                    style={[styles.padding(10)]}
                  >
                    <Card style={[styles.flex(1)]}>
                      <CardBody>
                        <Text>NQAA per Week</Text>
                        <H1>{NQAA}</H1>
                      </CardBody>
                    </Card>
                    <Card style={[styles.flex(1)]}>
                      <CardBody>
                        <Text>Average Pass Rate</Text>
                        <H1>{AVERAGE_PASS_RATE.toFixed(0) + '%'}</H1>
                      </CardBody>
                    </Card>
                    <Card style={[styles.flex(1)]}>
                      <CardBody>
                        <Text>Average Progressing Time</Text>
                        <H1>
                          {(AVERAGE_PROGRESSING_TIME - 0 || 0).toFixed(1)}D
                        </H1>
                      </CardBody>
                    </Card>
                    <Card style={[styles.flex(1)]}>
                      <CardBody>
                        <Text>Total Pass Rate</Text>
                        <H1>
                          {(100 * (TOTAL_AVERAGE_PASS_RATE - 0)).toFixed(0) +
                            '%'}
                        </H1>
                      </CardBody>
                    </Card>
                  </Responsively>
                </Col>
              );
            }}
          </ReactApollo.Query>
          <View style={[{ height: 100 }]} />
          <BottomPadder />
        </ScrollView>
      </Col>
    );
  }
}

function getMetricDataFromMe(metric, me) {
  return ((me || {})[metric] || {}).data || [];
}
