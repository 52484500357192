import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../rnbc/styles';
import NavHeader from '../../../rnbc/components/navHeader';
import Form, { Small } from '../../../rnbc/components/form';
import Formilize from '../../../rnbc/components/formilize';
import Alert from '../../../rnbc/components/alert';
import { OS, ScrollView, StatusBar } from '../../../plugins';
import { client } from '../../shared/apollo';
import { FORM_REVISION_CONFIDENTIAL_FILE_SET } from './_gql';
import { errorParser } from '../../shared/errorParser';
import FileTaskWorker from '../../component/fileTaskWorker';
import { FileInputMultipleS3_2 } from '../../../rnbc/components/form';
import FullWrapper from '../../../rnbc/components/fullWrapper';

export default class _ConfidentialFiles extends Component {
  pointer = 0;
  onFieldUpdate = async (_, _files) => {
    /* trigger for description update only */
    const { revisionId } = this.props;
    const file = _files[this.pointer];
    if (!!file) delete file.__typename;
    try {
      const variables = {
        index: this.pointer,
        file
      };
      console.log(variables);
      const res = await client.mutate({
        mutation: FORM_REVISION_CONFIDENTIAL_FILE_SET(revisionId),
        variables
      });
      console.log(res);
    } catch (e) {
      console.log(e);
      Alert.danger(errorParser(e));
    }
  };
  render() {
    const { onBackPress, files, revisionId, count } = this.props;
    const filesPatchedFromCache = Array(count)
      .fill(null)
      .map((_, i) => {
        return FileTaskWorker.getCache({ revisionId, i }) || files[i];
      });
    return (
      <FullWrapper>
        <StatusBar barStyle={'light-content'} />
        <NavHeader
          back
          onBackPress={onBackPress}
          color={'light'}
          title={'Client Confidential'}
        />
        <ScrollView
          style={[styles.padding(10), styles.backgroundColor('white')]}
        >
          <Formilize
            initialValues={{ files: filesPatchedFromCache }}
            onFieldUpdate={this.onFieldUpdate}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FileInputMultipleS3_2
                  style={[{ maxWidth: 500 }]}
                  count={count}
                  value={values.files}
                  maxSize={1048576 * 10}
                  onFileSelect={async (files, i) => {
                    const insertedIds = [].concat(i);

                    this.pointer = insertedIds[0];
                    setFieldValue('files', files, false);

                    for (const i of insertedIds) {
                      /* File Task Worker Flow */
                      if (OS === 'web') {
                        FileTaskWorker.addTask({
                          revisionId,
                          file: files[i],
                          i
                        }).then();
                      } else {
                        const { uri, name, type } = files[i];
                        FileTaskWorker.addTask({
                          revisionId,
                          file: { uri, name, type },
                          i
                        }).then();
                      }
                    }
                  }}
                  onChange={(files, i) => {
                    this.pointer = i;
                    setFieldValue('files', files);
                  }}
                />
                <Small>* Maximum allowed file size: 100MB</Small>
                <Small>
                  * Maximum pages for Attachment is 100 pages, exceeding this
                  will prevent the system from creating the final PDF cover
                  sheet with all information.
                </Small>
              </Form>
            )}
          </Formilize>
        </ScrollView>
      </FullWrapper>
    );
  }
}
_ConfidentialFiles.propTypes = {
  onBackPress: PropTypes.func,
  revisionId: PropTypes.string,
  files: PropTypes.array,
  count: PropTypes.number
};
_ConfidentialFiles.defaultProps = {
  onBackPress: _ => _,
  count: 20
};
