import React from 'react';
import { Row } from '../../../rnbc/components/layout';
import Button from '../../../rnbc/components/button';
import Icon from '../../../rnbc/icon';
import Text from '../../../rnbc/components/text';
import styles from '../../../rnbc/styles';
export const limits = 100;

export default ({
  color = 'light',
  style = [],
  cursors = [],
  nextCursor,
  totalCount = 0,
  currentCount = 0,
  onNextPress = _ => _,
  onPrevPress = _ => _,
  onFirstPress = _ => _
}) => {
  const enabledColor = color,
    disabledColor = color === 'light' ? 'dark' : 'light';
  return (
    <Row style={style} fy={'center'} fx={'flex-end'}>
      <Button
        transparent
        color={cursors.length === 0 ? disabledColor : enabledColor}
        disabled={cursors.length === 0}
        onPress={onFirstPress}
      >
        <Icon name={'chevron-double-left'} />
      </Button>
      <Button
        transparent
        color={cursors.length === 0 ? disabledColor : enabledColor}
        disabled={cursors.length === 0}
        onPress={onPrevPress}
      >
        <Icon name={'chevron-left'} />
      </Button>
      <Button
        transparent
        color={!nextCursor ? disabledColor : enabledColor}
        disabled={!nextCursor}
        onPress={onNextPress}
      >
        <Icon name={'chevron-right'} />
      </Button>
      <Text style={[styles.color(enabledColor)]}>
        {cursors.length * limits + 1}-
        {Math.min(cursors.length * limits + currentCount, totalCount)} of{' '}
        {totalCount}
      </Text>
    </Row>
  );
};
