import React from 'react';
import { Row } from './layout';
import styles from '../styles';

export default ({
  style,
  outline = false,
  disabled,
  color = 'primary',
  children
}) => {
  let buttons = children
    .reduce((reducer, child) => {
      return reducer.concat(child);
    }, [])
    .filter(_ => _);

  buttons = buttons.map((button, i) => {
    const isFirst = i === 0,
      isLast = i === buttons.length - 1;
    return React.cloneElement(button, {
      key: i,
      color: button.props.color || color,
      outline: button.props.outline || outline,
      disabled: button.props.disabled || disabled,
      style: [
        {
          borderTopLeftRadius: isFirst ? 5 : 0,
          borderBottomLeftRadius: isFirst ? 5 : 0,
          borderTopRightRadius: isLast ? 5 : 0,
          borderBottomRightRadius: isLast ? 5 : 0,
          borderLeftWidth: isFirst ? 1 : 0
        }
      ].concat(button.props.style)
    });
  });
  return <Row style={style}>{buttons}</Row>;
};
