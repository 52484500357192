import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Animated,
  StyleSheet,
  Image,
  PanResponder,
  ScrollView,
  View
} from '../../plugins';
import { PullRefresher, RepeatRefresher } from './refresher';
import styles from '../styles';

export default class ScrollHeaderLayout extends Component {
  constructor(props) {
    super(props);
    let { onRefresh } = props;
    this.state = {
      refreshing: false,
      isTop: false,
      isBottom: false,
      y: new Animated.Value(0),
      refreshHeight: new Animated.Value(0),
      scrollViewHeight: 0,
      contentHeight: 0
    };

    this.panResponderProps = onRefresh
      ? PanResponder.create({
          onStartShouldSetPanResponder: this.onStartShouldSetPanResponder.bind(
            this
          ),
          onStartShouldSetPanResponderCapture: this.onStartShouldSetPanResponderCapture.bind(
            this
          ),
          onMoveShouldSetPanResponder: this.onMoveShouldSetPanResponder.bind(
            this
          ),
          onMoveShouldSetPanResponderCapture: this.onMoveShouldSetPanResponderCapture.bind(
            this
          ),
          onPanResponderMove: this.onPanResponderMove.bind(this),
          onPanResponderRelease: this.onPanResponderRelease.bind(this),
          onPanResponderTerminate: this.onPanResponderTerminate.bind(this)
        }).panHandlers
      : {};

    this.onScroll = this.onScroll.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onScrollEndDrag = this.onScrollEndDrag.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }

  async onRefresh() {
    let { onRefresh } = this.props,
      { refreshing } = this.state;
    if (refreshing) return;

    await this.setState({ refreshing: true });
    await this.refresherStart();
    try {
      await onRefresh();
    } catch (e) {}
    await this.setState({ refreshing: false });
    await this.refresherEnd();
  }

  //region Refresher Handler
  refresherStart() {
    return new Promise(resolve => {
      let { pullThreshold } = this.props,
        { refreshHeight } = this.state;
      Animated.spring(refreshHeight, {
        toValue: -pullThreshold
      }).start(resolve);
    });
  }
  refresherEnd() {
    return new Promise(resolve => {
      let { refreshHeight } = this.state;
      this.setState({ isTop: false });
      Animated.spring(refreshHeight, {
        toValue: 0
      }).start(resolve);
    });
  }
  //endregion

  //region Pan Respond Handler
  onStartShouldSetPanResponder() {
    let { isTop } = this.state;
    return isTop;
  }
  onStartShouldSetPanResponderCapture() {
    return false;
  }
  onMoveShouldSetPanResponder() {
    return this.onStartShouldSetPanResponder();
  }
  onMoveShouldSetPanResponderCapture() {
    return false;
  }
  onPanResponderMove(e, gestureState) {
    let { y, refreshHeight, refreshing } = this.state;

    if (refreshing) return;
    if ((gestureState.dy >= 0 && y._value === 0) || refreshHeight._value > 0) {
      refreshHeight.setValue(-1 * gestureState.dy * 0.5);
    } else {
      let targetY = -1 * (gestureState.dy + gestureState.vy);
      this.refs.ContentRef &&
        this.refs.ContentRef.scrollTo({ y: targetY, animated: true });
      this.setState({ isTop: false });
    }
  }
  onPanResponderRelease() {
    let { pullHeight } = this.props,
      { refreshing, refreshHeight, y } = this.state;
    if (refreshing) return;
    if (refreshHeight._value <= -pullHeight) {
      this.onRefresh();
    } else if (refreshHeight._value <= 0) {
      this.refresherEnd();
    }

    if (y._value > 0) {
      this.setState({ isTop: false });
    }
  }
  onPanResponderTerminate() {
    this.onPanResponderRelease();
  }
  //endregion

  //region Scroll Handler
  onScroll(e) {
    let { y, isTop } = this.state;

    this.handleBottom(e);
    Animated.event([
      {
        nativeEvent: {
          contentOffset: { y }
        }
      }
    ])(e);
    if (y._value > 0 && isTop) this.setState({ isTop: false });
  }
  handleBottom(e) {
    let { onBottom } = this.props,
      { isBottom } = this.state;
    if (!onBottom) return;

    let isNearBottom = isCloseToBottom(e.nativeEvent);
    if (isNearBottom && !isBottom) {
      this.setState({ isBottom: true });
      onBottom();
    } else if (!isNearBottom && isBottom) {
      this.setState({ isBottom: false });
    }
    function isCloseToBottom({
      layoutMeasurement,
      contentOffset,
      contentSize
    }) {
      const paddingToBottom = 10;
      return (
        layoutMeasurement.height + contentOffset.y >=
        contentSize.height - paddingToBottom
      );
    }
  }
  async onScrollViewLayout({
    nativeEvent: {
      layout: { height }
    }
  }) {
    this.setState({ scrollViewHeight: height });
    await new Promise(resolve => setTimeout(resolve, 0));
    this.handleInitialBottom();
  }
  async onContentLayout({
    nativeEvent: {
      layout: { height }
    }
  }) {
    this.setState({ contentHeight: height });
    await new Promise(resolve => setTimeout(resolve, 0));
    this.handleInitialBottom();
  }
  handleInitialBottom() {
    let { onBottom } = this.props;
    const { scrollViewHeight, contentHeight } = this.state;
    if (!contentHeight || !scrollViewHeight) return;
    const triggerBottom = scrollViewHeight > contentHeight;
    if (triggerBottom) onBottom && onBottom();
  }
  onTouchEnd() {
    let { y, isTop } = this.state;
    if (y._value <= 0 && !isTop) {
      this.setState({ isTop: true });
    }
  }
  onScrollEndDrag() {
    this.onTouchEnd();
  }
  //endregion

  //region Render
  render() {
    const { style } = this.props;
    return (
      <View
        pointerEvents={'auto'}
        style={[styles.fill].concat(style)}
        {...this.panResponderProps}
      >
        {this.renderBody()}
        {this.renderHeader()}
      </View>
    );
  }
  renderBody() {
    let { children, onRefresh } = this.props,
      { isTop, refreshing } = this.state;
    return (
      <ScrollView
        scrollEventThrottle={16}
        scrollEnabled={!isTop || refreshing || !onRefresh}
        ref={'ContentRef'}
        onScroll={this.onScroll}
        onScrollEndDrag={this.onScrollEndDrag}
        onTouchEnd={this.onTouchEnd}
        onLayout={this.onScrollViewLayout.bind(this)}
      >
        {this.renderRefresher()}
        <View onLayout={this.onContentLayout.bind(this)}>{children}</View>
      </ScrollView>
    );
  }
  renderRefresher() {
    let {
        pullHeight,
        pullThreshold,
        heightRange: [marginTop]
      } = this.props,
      { refreshHeight, refreshing } = this.state;
    const aniHeight = refreshHeight.interpolate({
      inputRange: [-1, 0],
      outputRange: [1, 0]
    });
    const aniPullProgress = refreshHeight.interpolate({
      inputRange: [-pullHeight, 0],
      outputRange: [1, 0]
    });
    return (
      <Animated.View
        style={[styles.margin(marginTop, 0, 0), { height: aniHeight }]}
      >
        {refreshing ? (
          <RepeatRefresher style={[_styles.refresher]} />
        ) : (
          <PullRefresher
            style={[_styles.refresher]}
            progress={aniPullProgress}
          />
        )}
      </Animated.View>
    );
  }

  renderHeader() {
    let {
        renderHeader,
        heightRange: [max, min],
        headerImg
      } = this.props,
      { y } = this.state;
    let yNormalized = y.interpolate({
      inputRange: [0, max - min],
      outputRange: [0, 1],
      extrapolateRight: 'clamp'
    });
    let aniHeight = yNormalized.interpolate({
      inputRange: [0, 1],
      outputRange: [max, min]
    });
    return (
      <Animated.View style={[_styles.header, { height: aniHeight }]}>
        {!!headerImg && <Image source={headerImg} style={[styles.overlay]} />}
        {renderHeader(yNormalized)}
      </Animated.View>
    );
  }
  //endregion
}

const _styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    overflow: 'hidden'
  },
  refresher: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
});
ScrollHeaderLayout.propTypes = {
  heightRange: PropTypes.array,
  renderHeader: PropTypes.func,
  headerImg: Image.propTypes.source,
  onRefresh: PropTypes.func,
  onBottom: PropTypes.func,
  pullThreshold: PropTypes.number,
  pullHeight: PropTypes.number,
  style: PropTypes.any
};

ScrollHeaderLayout.defaultProps = {
  heightRange: [250, 100],
  renderHeader: _ => null,
  headerImg: null,
  onBottom: null,
  pullThreshold: 50,
  pullHeight: 80
};
