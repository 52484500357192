import { Biometrics } from '../../plugins';

export const sensorType = async () => {
  return await Biometrics.isSensorAvailable();
};

export const simple = async msg => {
  const type = await Biometrics.isSensorAvailable();
  if (!type) return false;
  msg =
    msg ||
    `Confirm ${
      { [Biometrics.TouchID]: 'Fingerprint', [Biometrics.FaceID]: 'Face ID' }[
        type
      ]
    }`;
  try {
    await Biometrics.simplePrompt(msg);
    return true;
  } catch (e) {
    return false;
  }
};
