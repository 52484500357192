import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../styles';
import ModalizedInput from './input';
import { Small } from '../../form';
import { Modal, TouchableOpacity } from '../../../../plugins';
import { Col, Flex, Row } from '../../layout';
import Text from '../../text';
import Icon from '../../../icon';
import WheelSelect from '../../wheelSelect';
import moment from 'moment';
import H5 from '../../h5';
import Button from '../../button';
import FullWrapper from '../../fullWrapper';

const tmp = moment();
const now = {
  year: tmp.year(),
  month: tmp.month() + 1,
  day: tmp.date()
};

export default class ModalizedInputDate extends ModalizedInput {
  state = {
    ...this.state,
    cache: undefined,
    dataYears: Array(10)
      .fill(null)
      .map((_, i) => {
        const startYear = this.props.minDay
          ? moment(this.props.minDay || undefined).year() || moment().year() - 4
          : moment().year() - 4;
        return {
          label: startYear + i,
          value: startYear + i
        };
      }),
    dataMonths: [],
    dataDays: []
  };
  onDisplayerPress = () => {
    const { value } = this.props;

    this.setState({
      show: true,
      cache: value || moment().toISOString()
    });
  };
  onYearChange = year => {
    if (!year) return;
    const { cache } = this.state;
    const tmp = moment(cache).year(year);
    const dataMonths = this.getDataMonth(year);
    const dataDays = this.getDataDay(year, tmp.month());
    this.setState({ dataMonths, dataDays, cache: tmp.toISOString() });
  };
  onMonthChange = month => {
    if (!month) return;
    const { cache } = this.state;
    const tmp = moment(cache).month(month - 1);
    const dataDays = this.getDataDay(tmp.year(), month - 1);
    if (tmp.date() > dataDays.length + 1) tmp.date(dataDays.length + 1);
    this.setState({
      dataDays,
      cache: tmp.toISOString()
    });
  };
  onDayChange = day => {
    if (!day) return;
    const { cache } = this.state;
    const tmp = moment(cache).date(day);
    this.setState({ cache: tmp.toISOString() });
  };
  onTomorrowPress = async () => {
    let tmp = moment().add(1, 'day');
    this.setState({
      dataDays: this.getDataDay(tmp.year(), tmp.month())
    });
    await new Promise(e => setTimeout(e, 0));
    this.setState({ cache: tmp.toISOString() });
  };
  onTodayPress = async () => {
    let tmp = moment();
    this.setState({
      dataDays: this.getDataDay(tmp.year(), tmp.month())
    });
    await new Promise(e => setTimeout(e, 0));
    this.setState({ cache: tmp.toISOString() });
  };
  onConfirmPress = () => {
    const { onChange, minDay } = this.props,
      { cache } = this.state;
    let _cache = cache;
    if (!!minDay && moment(minDay).isAfter(moment(cache))) {
      _cache = moment(minDay).toISOString();
    }
    onChange(_cache);
    this.setState({ cache: _cache });
    this.onRequestClose();
  };

  componentDidMount() {
    const { value } = this.props;
    const valueYear = moment(value || undefined).year(),
      valueMonth = moment(value || undefined).month();

    this.setState({
      dataMonths: this.getDataMonth(valueYear),
      dataDays: this.getDataDay(valueYear, valueMonth)
    });
  }
  getDataMonth(year) {
    const { minDay } = this.props,
      { dataMonths } = this.state;
    try {
      let minMonth = minDay ? moment(minDay).month() + 1 : 1;
      if (year !== moment(minDay).year()) minMonth = 1;

      return Array(12)
        .fill(null)
        .map((_, i) => {
          if (i + 1 >= minMonth)
            return {
              label: i + 1,
              value: i + 1
            };
        })
        .filter(_ => _);
    } catch (e) {
      return dataMonths;
    }
  }
  getDataDay(year, month) {
    const { minDay } = this.props,
      { dataDays } = this.state;
    try {
      const needToHideMinDay =
        !!minDay &&
        year === moment(minDay).year() &&
        month === moment(minDay).month();

      let _month = month || 0;
      const days = moment()
        .month(_month)
        .daysInMonth();
      const needToMarkToday = year === now.year && _month === now.month - 1;

      return Array(days)
        .fill(null)
        .map((_, i) => {
          if (needToHideMinDay && i + 1 < moment(minDay).date()) {
            return null;
          }
          return {
            label: i + 1,
            value: i + 1,
            style: needToMarkToday &&
              now.day === i + 1 && [styles.color('primary')]
          };
        })
        .filter(_ => _);
    } catch (e) {
      console.log(e);
      return dataDays;
    }
  }
  componentWillUnmount() {}
  renderDisplayer() {
    const { value, style, displayFormat, disabled } = this.props;
    return (
      <TouchableOpacity
        activeOpacity={!disabled ? 0.5 : 1}
        onPress={!disabled && this.onDisplayerPress}
        style={[
          styles.border(1),
          styles.rounded(5),
          styles.backgroundColor(!disabled ? 'white' : 'bg'),
          styles.padding(10, 12),
          { minHeight: 35 }
        ].concat(style)}
      >
        <Text>{!!value ? moment(value).format(displayFormat) : ''}</Text>
      </TouchableOpacity>
    );
  }
  renderModal() {
    const { show } = this.state;
    if (!show) return null;

    return (
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        onRequestClose={this.onRequestClose}
      >
        <FullWrapper
          style={[
            {
              backgroundColor: 'rgba(0,0,0,0.5)'
            }
          ]}
        >
          {this.renderRegionButton()}
          <Row style={[styles.padding(10)]}>
            {this.renderRegionButton()}
            <Col
              style={[
                styles.block,
                styles.shadow,
                styles.padding(10),
                styles.rounded(5),
                styles.backgroundColor('white'),
                { maxWidth: 500 }
              ]}
            >
              <Row fy={'center'}>{this.renderWheels()}</Row>
              {this.renderUtils()}
            </Col>
            {this.renderRegionButton()}
          </Row>
          {this.renderRegionButton()}
        </FullWrapper>
      </Modal>
    );
  }
  renderWheels() {
    const { dataYears, dataMonths, dataDays, cache } = this.state;
    const tmp = moment(cache),
      year = tmp.year(),
      month = tmp.month() + 1,
      day = tmp.date();
    return (
      <Fragment>
        <WheelSelect
          value={year}
          onChange={this.onYearChange}
          items={dataYears}
          height={styles.vh(0.5).height}
          style={[styles.flex(1)]}
        />
        <H5 style={[styles.marginX(-5)]}>-</H5>
        <WheelSelect
          value={month}
          onChange={this.onMonthChange}
          items={dataMonths}
          height={styles.vh(0.5).height}
          style={[styles.flex(1)]}
        />
        <H5 style={[styles.marginX(-5)]}>-</H5>
        <WheelSelect
          value={day}
          onChange={this.onDayChange}
          items={dataDays}
          height={styles.vh(0.5).height}
          style={[styles.flex(1)]}
        />
      </Fragment>
    );
  }
  renderUtils() {
    const { shortcut } = this.props;
    return (
      <Row>
        {!!shortcut && (
          <Button transparent onPress={this.onTodayPress}>
            <Small>Today</Small>
          </Button>
        )}
        {!!shortcut && (
          <Button transparent onPress={this.onTomorrowPress}>
            <Small>Tomorrow</Small>
          </Button>
        )}
        <Flex />
        <Button
          transparent
          style={[styles.marginRight(10)]}
          color={'danger'}
          onPress={this.onRequestClose}
        >
          <Icon name={'times'} />
        </Button>
        <Button transparent onPress={this.onConfirmPress}>
          <Icon name={'check'} />
        </Button>
      </Row>
    );
  }
  renderRegionButton() {
    return (
      <TouchableOpacity
        activeOpacity={0.5}
        onPress={this.onRequestClose}
        style={[styles.flex(1)]}
      />
    );
  }
}
ModalizedInputDate.propTypes = {
  shortcut: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  displayFormat: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  minDay: PropTypes.string
};
ModalizedInputDate.defaultProps = {
  onChange: _ => _,
  displayFormat: 'DD-MMM-YYYY',
  shortcut: true
};
