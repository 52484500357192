import React, { PureComponent } from 'react';
import { BackHandler, Orientation } from '../../plugins';
import PropTypes from 'prop-types';
import { Row, Col } from './layout';
import Drawer from './drawer';
import Icon from '../icon';
import styles from '../styles';
import Button from './button';
import { TopPadder } from './iphonePadder';
import H4 from './h4';

const list = [];
let lastBackReferrer = '';
export default class NavHeader extends PureComponent {
  identity = Math.random();
  onDrawerPress = () => {
    Drawer.toggle();
  };
  static get lastBackReferrer() {
    return lastBackReferrer;
  }
  static set lastBackReferrer(v) {
    lastBackReferrer = v;
  }
  static resetLastBackReferrer() {
    lastBackReferrer = '';
  }

  componentDidMount() {
    this.backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackPress
    );
    Orientation.addOrientationListener(this.onOrientationChange);
    list.push(this);
  }

  componentWillUnmount() {
    this.backHandler.remove();
    Orientation.removeOrientationListener(this.onOrientationChange);
    const id = list.findIndex(nav => nav.identity === this.identity);
    if (id !== -1) {
      list.splice(id, 1);
    }
  }

  onOrientationChange = () => {};

  handleBackPress = () => {
    const last = list[list.length - 1];
    if (last) {
      last.onBackPress();
    }
    return true;
  };

  onBackPress = async () => {
    const { history, onBackPress } = this.props;
    try {
      if (!!onBackPress) onBackPress();
      else if (!!history) {
        lastBackReferrer = history.location.pathname;
        history.goBack();
      }
    } catch (e) {}
  };

  render() {
    const {
      color,
      drawer,
      back,
      leftComponent,
      rightComponent,
      title
    } = this.props;
    return (
      <Col style={[styles.shadow, styles.backgroundColor(color)]}>
        <TopPadder />
        <Row fy={'center'} style={[styles.header, styles.padding(5)]}>
          <Row fx={'flex-start'} flex={1}>
            {leftComponent ||
              (!!drawer && (
                <Button
                  transparent
                  color={`anti-${color}`}
                  style={[styles.paddingRight(30)]}
                  onPress={this.onDrawerPress}
                >
                  <Icon name={'list-ul'} />
                </Button>
              )) ||
              (!!back && (
                <Button
                  transparent
                  color={`anti-${color}`}
                  style={[styles.paddingRight(30)]}
                  onPress={this.onBackPress}
                >
                  <Icon name={'arrow-left'} />
                </Button>
              ))}
          </Row>
          {typeof title === 'string' ? (
            <H4
              color={`anti-${color}`}
              numberOfLines={1}
              style={[
                {
                  maxWidth: styles.vw(1).width - 100
                }
              ]}
            >
              {title}
            </H4>
          ) : (
            <Row fy={'center'}>{title}</Row>
          )}

          <Row fx={'flex-end'} fy={'center'} flex={1}>
            {rightComponent}
          </Row>
        </Row>
      </Col>
    );
  }
}

NavHeader.propTypes = {
  history: PropTypes.any,
  title: PropTypes.string,
  color: PropTypes.string,
  drawer: PropTypes.bool,
  leftComponent: PropTypes.any,
  back: PropTypes.bool,
  onBackPress: PropTypes.func,
  rightComponent: PropTypes.any
};
NavHeader.defaultProps = {
  title: '',
  color: 'primary'
};
