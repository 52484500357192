import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Label,
  ModalizedSelect,
  ModalizedInputUsers,
  Checkbox,
  InputGroup,
  InputGroupAppend,
  Small
} from '../../rnbc/components/form';
import Text from '../../rnbc/components/text';
import styles from '../../rnbc/styles';
import { ApolloBoost } from '../../plugins';
import CursorBasedList from '../../rnbc/components/cursorBasedList';
import Loading from '../../rnbc/components/loading';
import { Row } from '../../rnbc/components/layout';
import FieldUserGroupPositionsRemark from './FieldUserGroupPositionsRemark';
const gql = ApolloBoost.gql;

export const GET_SELECTABLE_USERS = (
  projectId,
  formId,
  revisionId,
  stageId,
  fieldId
) => gql`
  query(
    $cursor: ID
    $limits: Int
    $filter: UserFilter
  ) {
    me {
      id
      p_${projectId.replace(/-/g, '_')}: project(id: "${projectId}") {
        id
        f_${formId.replace(/-/g, '_')}: form(id: "${formId}") {
          id
          rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
            id
            s_${stageId.replace(/-/g, '_')}: stage(stageId: "${stageId}") {
              id
              fi_${fieldId.replace(/-/g, '_')}: field(fieldId: "${fieldId}") {
                id
                ... on UserField {
                  selectableUsers(
                    cursor: $cursor
                    limits: $limits
                    filter: $filter
                  ) {
                    nextCursor
                    totalCount
                    nodes {
                      id
                      name
                      email
                      position
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default class UserField extends Component {
  state = {
    search: ''
  };
  render() {
    const {
        me,
        projectId,
        formId,
        revisionId,
        stageId,
        id: fieldId,
        label,
        noted,
        requireNoted,
        onAcknowledge,
        users: _users,
        disabled,
        selectableUserCount,
        onChange,
        required,
        searchQuery,
        _sync,
        description,
        userGroup,
        userPositions
      } = this.props,
      { search } = this.state;
    const users = _users || [];
    let allowNoted = !!me && !!users.find(user => user.email === me.email);
    const fixedFieldId = (fieldId || '').replace(
      new RegExp(`^(.*)-${revisionId}$`),
      '$1'
    );

    return (
      <FormGroup>
        {!!_sync && (
          <Loading style={[styles.absolute({ top: -5, right: 0 })]} />
        )}
        {!!label && (
          <Row fy={'center'}>
            <Label style={[styles.marginY(5, !!description ? 0 : 5)]}>
              {label} <Text color={'danger'}>{!!required && '**'}</Text>
            </Label>
            <FieldUserGroupPositionsRemark
              userGroup={userGroup}
              userPositions={userPositions}
            />
          </Row>
        )}
        {!!description && (
          <Small style={[styles.marginBottom(5)]}>{description}</Small>
        )}
        <InputGroup>
          {!disabled && (
            <CursorBasedList
              filter={{
                search: !!search
                  ? search
                      .split(' ')
                      .map(str => `name:*${str}* OR email:*${str}*`)
                      .join(' OR ')
                  : ''
              }}
              getConnection={data => {
                const project =
                    (data.me || {})[`p_${projectId.replace(/-/g, '_')}`] || {},
                  form = project[`f_${formId.replace(/-/g, '_')}`] || {},
                  revision = form[`rev_${revisionId.replace(/-/g, '_')}`] || {},
                  stage = revision[`s_${stageId.replace(/-/g, '_')}`] || {},
                  field = stage[`fi_${fixedFieldId.replace(/-/g, '_')}`] || {};
                return field.selectableUsers;
              }}
              query={searchQuery(
                projectId,
                formId,
                revisionId,
                stageId,
                fixedFieldId
              )}
            >
              {({ items, nextCursor, loadMore, loading, style }) => (
                <ModalizedInputUsers
                  maximum={selectableUserCount || 1}
                  style={[styles.flex(1)].concat(style)}
                  value={users.map(({ email, name, position }) => ({
                    email,
                    name,
                    position
                  }))}
                  isItemLoading={loading}
                  items={items}
                  onSearchUpdate={search => {
                    this.setState({
                      search: !!search && search.length >= 3 ? search : ''
                    });
                  }}
                  onBottom={!!nextCursor && loadMore}
                  onChange={users => {
                    onChange(users);
                  }}
                />
              )}
            </CursorBasedList>
          )}
          {!!disabled && (
            <ModalizedInputUsers
              disabled
              maximum={selectableUserCount || 1}
              style={[styles.flex(1)]}
              value={users.map(({ email, name, position }) => ({
                email,
                name,
                position
              }))}
              items={[]}
            />
          )}

          {!!requireNoted && users.length > 0 && (
            <InputGroupAppend>
              <Checkbox
                disabled={!allowNoted}
                label={'Noted'}
                checked={noted}
                onPress={() => onAcknowledge(true)}
              />
            </InputGroupAppend>
          )}
        </InputGroup>
      </FormGroup>
    );
  }
}
UserField.propTypes = {
  projectId: PropTypes.string,
  formId: PropTypes.string,
  version: PropTypes.string,
  revisionId: PropTypes.string,
  stageId: PropTypes.string,
  label: PropTypes.string,
  noted: PropTypes.bool,
  requireNoted: PropTypes.bool,
  onAcknowledge: PropTypes.func,
  users: PropTypes.array,
  disabled: PropTypes.bool,
  selectableUserGroup: PropTypes.string,
  selectablePositions: PropTypes.array,
  selectableUserCount: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  searchQuery: PropTypes.any,
  description: PropTypes.string
};
UserField.defaultProps = {
  searchQuery: GET_SELECTABLE_USERS
};
