import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Flex } from './layout';
import Text from './text';
import {
  RNImage,
  Image,
  TouchableOpacity,
  View,
  StyleSheet,
  OS
} from '../../plugins';
import Icon from '../icon';
import styles from '../styles';

const BorderWidth = StyleSheet.hairlineWidth;

export class ListItem extends Component {
  render() {
    const {
      source,
      description,
      onPress,
      border,
      borderColor,
      style
    } = this.props;
    return (
      <View style={styles.padding(0, 0, 0, 10)}>
        <TouchableOpacity
          onPress={onPress}
          activeOpacity={onPress ? 0.5 : 1}
          style={[
            styles.row,
            styles.padding(10),
            {
              borderBottomWidth: border ? BorderWidth : 0,
              borderBottomColor: styles.getColorCode(borderColor),
              height: description || source ? 60 : 45
            }
          ].concat(style)}
        >
          {this.renderThumbnail()}
          {this.renderBody()}
          {this.renderValue()}
        </TouchableOpacity>
      </View>
    );
  }
  renderThumbnail() {
    const { source } = this.props;
    if (!source) return null;
    if (OS === 'web')
      return (
        <RNImage
          source={source}
          resizeMode={'cover'}
          style={[
            styles.marginRight(10),
            styles.rounded(5),
            { height: '100%', minWidth: 35 }
          ]}
        />
      );
    else
      return (
        <Image
          source={source}
          resizeMode={'cover'}
          style={[
            styles.marginRight(10),
            styles.rounded(5),
            { aspectRatio: 1, height: '100%' }
          ]}
        />
      );
  }
  renderBody() {
    const { title, titleColor, description, descriptionColor } = this.props;
    return (
      <Col flex={1} fy={'center'}>
        {!!title &&
          (typeof title === 'string' ? (
            <Text color={titleColor} numberOfLines={1}>
              {title}
            </Text>
          ) : (
            title
          ))}
        {!!description && (
          <Text
            color={descriptionColor}
            style={[styles.flex(1), styles.small]}
            numberOfLines={1}
          >
            {description}
          </Text>
        )}
      </Col>
    );
  }
  renderValue() {
    const { value, onPress, icon, iconColor } = this.props;
    return (
      <Col fy={'center'} style={[styles.marginLeft(10)]}>
        {value ? (
          <Text color={'dark'}>{value}</Text>
        ) : (
          onPress && <Icon name={icon} color={iconColor} />
        )}
      </Col>
    );
  }
}
ListItem.propTypes = {
  source: PropTypes.any,
  title: PropTypes.any,
  titleColor: PropTypes.string,
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  onPress: PropTypes.func,
  value: PropTypes.string,
  border: PropTypes.bool,
  borderColor: PropTypes.string,
  style: PropTypes.any,
  icon: PropTypes.string,
  iconColor: PropTypes.string
};
ListItem.defaultProps = {
  titleColor: 'dark',
  descriptionColor: 'secondary',
  border: true,
  borderColor: 'border',
  icon: 'arrow-right',
  iconColor: 'secondary'
};

export default ({ style, children }) => {
  return <Col style={style}>{children}</Col>;
};
