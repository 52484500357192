import React from 'react';
import { Svg, Path } from '../../../plugins';

export default ({ style, color, size }) => {
  return (
    <Svg
      style={style}
      width={size}
      height={size}
      viewBox={'0 0 512 512'}
      version='1.1'
    >
      <Path
        fill={color}
        d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'
      />
    </Svg>
  );
};
