import React, { Component } from 'react';
import { View, AsyncStorage, AppState } from '../../../plugins';
import Upload from '../../shared/upload';
import Alert from '../../../rnbc/components/alert';
import ActivityIndicator2 from '../../../rnbc/components/activityIndicator2';
import NetworkStatus from '../../../rnbc/components/networkStatus';
import { client } from '../../shared/apollo';
import {
  FORM_REVISION_SYNC,
  GET_FIELD,
  FORM_REVISION_CONFIDENTIAL_FILE_SET
} from './_gql';
import { errorParser } from '../../shared/errorParser';

let inst;
export default class FileTaskWorker extends Component {
  handler;
  tasks = [];
  online = true;
  isRunning = false;
  loadingText = 'Uploading';
  async componentDidMount() {
    this._mounted = true;
    inst = this;
    const json = (await AsyncStorage.getItem('files')) || '[]';
    this.tasks = JSON.parse(json);
    // console.log('files', this.tasks);

    AppState.addEventListener('change', this._handleAppStateChange);
  }
  async componentWillUnmount() {
    this._mounted = false;
    inst = null;

    AppState.removeEventListener('change', this._handleAppStateChange);
  }
  _handleAppStateChange = nextAppState => {
    if (nextAppState === 'inactive' || nextAppState === 'background') {
      this.saveTask().then();
    }
  };
  static getCache = (...args) => {
    return !!inst && inst.getCache(...args);
  };
  static addTask = async (...args) => {
    !!inst && (await inst.addTask(...args));
  };
  static clearTask = () => {
    !!inst && inst.clearTask();
  };
  static saveTask = () => {
    !!inst && inst.saveTask();
  };
  static toggleOnline = () => {
    !!inst && inst.toggleOnline();
  };
  getCache = ({ projectId, formId, revisionId, stageId, fieldId, i }) => {
    try {
      const task = this.tasks.find(task => {
        if (!!projectId && !!formId && !!revisionId && !!stageId && !!fieldId) {
          return (
            task.projectId === projectId &&
            task.formId === formId &&
            task.revisionId === revisionId &&
            task.stageId === stageId &&
            task.fieldId === fieldId &&
            task.i === i
          );
        } else if (!!revisionId) {
          return task.revisionId === revisionId && task.i === i;
        }
        return false;
      });
      if (!!task) return task.file;
    } catch (e) {}
    return null;
  };
  toggleOnline = () => {
    this.online = !this.online;
    console.log('online', this.online);
  };
  isOnline = () => {
    return this.online && NetworkStatus.isOnline();
  };
  addTask = async task => {
    !!task && this.tasks.push(task);
    await this.startWorker();
  };
  clearTask = () => {
    this.tasks = [];
  };
  saveTask = async () => {
    const json = JSON.stringify(this.tasks);
    await AsyncStorage.setItem('files', `${json}`);
  };
  startWorker = async () => {
    if (!this._mounted) return;
    if (!!this.isRunning) return;
    this.isRunning = true;
    !!this.handler && clearTimeout(this.handler);

    console.log('Start Worker');
    const isConnected = this.isOnline();
    if (!isConnected) {
      console.log('Offline, wait for retry');
      this.isRunning = false;
      return (this.handler = setTimeout(this.startWorker, 5000));
    } else {
      !!this.loadingText && ActivityIndicator2.show(this.loadingText);
      const needRetry = await this.startTasks();
      ActivityIndicator2.hide();
      if (!!needRetry) {
        this.isRunning = false;
        return (this.handler = setTimeout(this.startWorker, 5000));
      }
    }
    this.isRunning = false;
  };
  startTasks = async () => {
    const failedResult = [];
    while (this.tasks.length > 0) {
      const task = this.tasks.shift();
      if (!(await this.startTask(task))) failedResult.push(task);
    }
    if (failedResult.length > 0) {
      this.tasks.push(...failedResult);
      return true;
    }
  };
  startTask = async task => {
    try {
      const isConnected = this.isOnline();
      if (!isConnected) return false;
      await this.taskHandler(task);
    } catch (e) {
      console.log(e);
      Alert.danger(errorParser(e));
    }
    return true;
  };

  taskHandler = async ({ file, ...args }) => {
    const s3 = !!file ? await Upload(file) : null;

    console.log(s3);
    const isFormFieldUpdate = !!args.fieldId;
    if (!!isFormFieldUpdate) await this._handleFormField(s3, args);
    else await this._handleClientConfidentialFile(s3, args);
  };

  _handleFormField = async (
    s3,
    { projectId, formId, revisionId, stageId, fieldId, i }
  ) => {
    const { data } = await client.query({
      query: GET_FIELD(projectId, formId, revisionId, stageId, fieldId)
    });
    const { me } = data || {},
      project = (me || {})[`p_${projectId.replace(/-/g, '_')}`] || {},
      form = project[`f_${formId.replace(/-/g, '_')}`] || {},
      revision = form[`rev_${revisionId.replace(/-/g, '_')}`] || {},
      stage = revision[`s_${stageId.replace(/-/g, '_')}`] || {},
      field = stage[`fi_${fieldId.replace(/-/g, '_')}`] || {},
      { files } = field;

    files[i] = s3;

    const variables = {
      data: JSON.stringify({
        files: files.map(file =>
          !file
            ? null
            : {
                key: file.key,
                bucket: file.bucket,
                region: file.region,
                description: file.description
              }
        )
      })
    };

    console.log(variables);
    const res = await client.mutate({
      mutation: FORM_REVISION_SYNC(revisionId, stageId, fieldId),
      variables
    });
    // console.log(res);
  };

  _handleClientConfidentialFile = async (s3, { revisionId, i }) => {
    const variables = {
      index: i,
      file: s3
    };
    console.log(variables);
    const res = await client.mutate({
      mutation: FORM_REVISION_CONFIDENTIAL_FILE_SET(revisionId),
      variables
    });
    // console.log(res);
  };

  render() {
    return <View />;
  }
}
