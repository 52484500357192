import React from 'react';
import * as ReactStrap from 'reactstrap';
import * as ReactNativeWeb from 'react-native';
import * as ReactRouterDom from 'react-router-dom';
import _AWSAppSyncClient, * as _AWSAppSync from 'aws-appsync';
import * as _ApolloLink from 'apollo-link';
import * as _ApolloLinkError from 'apollo-link-error';
import * as _ApolloCacheInMemory from 'apollo-cache-inmemory';
import * as _ApolloBoost from 'apollo-boost';
import * as _ReactApollo from 'react-apollo';
import * as _ApolloLinkContext from 'apollo-link-context';
import _PDFView from 'mgr-pdf-viewer-react';
import S3FileUpload from 'react-s3';
import * as _ApolloLinkHttp from 'apollo-link-http';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import _FileDrop from 'react-file-drop';
import * as Recharts from 'recharts';
import * as localForage from 'localforage';
import _FilePicker from './filePicker';
import _Hyperlink from 'react-native-hyperlink';
import * as _Virtualized from 'react-virtualized';
import * as _Sentry from '@sentry/browser';
const SignaturePad = require('react-signature-pad');

const screen = {
  width: window.innerWidth,
  height: window.innerHeight
};
window.addEventListener('resize', () => {
  screen.width = document.documentElement.clientWidth;
  screen.height = document.documentElement.clientHeight;
});

export const Sentry = _Sentry;
export const Linking = ReactNativeWeb.Linking;
export const CompareVersions = {};
export const Hyperlink = _Hyperlink;
export const FlatList = _Virtualized;
export const ActionSheet = () => null;
export const StorageEngine = localForage;
export const Charts = Recharts;
export const Orientation = {
  unlockAllOrientations: _ => _,
  lockToLandscape: _ => _,
  addOrientationListener: _ => _,
  removeOrientationListener: _ => _
};
export const FileDrop = _FileDrop;
export const KeyboardAwareScrollView = ReactNativeWeb.ScrollView;
export const G = ({ children, ...props }) => <g {...props}>{children}</g>;
export const Line = props => <line {...props} />;
export const Biometrics = {
  isSensorAvailable: () => false,
  simple: msg => false
};
export const Base64 = {
  encode: str => new Buffer(str).toString('base64')
};
export const BackHandler = ReactNativeWeb.BackHandler;
export const DeviceInfo = {
  getVersion: () => process.env.REACT_APP_VERSION || '',
  getBundleId: () => ''
};
export const IFrame = props => <iframe {...props} />;
export const OneSignal = {
  init: _ => _,
  addEventListener: _ => _,
  removeEventListener: _ => _
};
export const AppState = ReactNativeWeb.AppState;
export const NetInfo = ReactNativeWeb.NetInfo;
export const Platform = ReactNativeWeb.Platform;
export const ReactNativeIPhoneXHelper = {
  getBottomSpace: () => 0,
  getStatusBarHeight: () => 0
};
export const ImageBackground = ReactNativeWeb.ImageBackground;
export const Alert = {
  alert: (title, message = '', [cancelBtn, okBtn], options) => {
    const buttons = [
      {
        label: 'No',
        className: 'bg-danger',
        onClick: () => {
          cancelBtn.onPress();
        }
      },
      {
        label: 'Yes',
        className: 'bg-primary',
        onClick: () => {
          okBtn.onPress();
        }
      }
    ];
    confirmAlert({
      title,
      message,
      closeOnClickOutside: false,
      buttons,
      customUI: ({ title = '', message = '', onClose = _ => _ }) => (
        <div
          className='react-confirm-alert-body'
          style={{
            maxWidth: '90vw'
          }}
        >
          {title && <h1>{title}</h1>}
          {message}
          <div className='react-confirm-alert-button-group'>
            {buttons.map((button, i) => (
              <button
                key={i}
                onClick={() => {
                  if (button.onClick) button.onClick();
                  onClose();
                }}
                className={button.className || ''}
              >
                {button.label}
              </button>
            ))}
          </div>
        </div>
      )
    });
  }
};
export const ApolloLinkHttp = _ApolloLinkHttp;
export const ApolloLinkContext = _ApolloLinkContext;
export const SplashScreen = { hide: _ => _ };
export const RNImage = ReactNativeWeb.Image;
export const Easing = ReactNativeWeb.Easing;
export const ReactNativeAWS3 = {
  RNS3: {
    put: (
      file,
      {
        keyPrefix: dirName,
        bucket: bucketName,
        region,
        accessKey: accessKeyId,
        secretKey: secretAccessKey
      }
    ) =>
      S3FileUpload.uploadFile(file, {
        dirName,
        bucketName,
        region,
        accessKeyId,
        secretAccessKey
      })
  }
};
export const ActivityIndicator = ReactNativeWeb.ActivityIndicator;
export const ApolloLink = _ApolloLink;
export const ApolloLinkError = _ApolloLinkError;
export const ApolloCacheInMemory = _ApolloCacheInMemory;
export const ApolloBoost = _ApolloBoost;
export const AsyncStorage = ReactNativeWeb.AsyncStorage;
export const BrandColor = input => ({ danger: '#e51937' }[input]);
export const Config = process.env;
export const ReactApollo = _ReactApollo;
export const AWSAppSyncClient = _AWSAppSyncClient;
export const AWSAppSync = _AWSAppSync;
export const Share = {};
export const ToastAndroid = {};
export const FilePicker = _FilePicker;
export const OS = ReactNativeWeb.Platform.OS;
export const FetchBlob = {};
export const PDFView = _PDFView;
export const DocumentPicker = {};
export const ImagePicker = {};
export const Animated = ReactNativeWeb.Animated;
export const PanResponder = ReactNativeWeb.PanResponder;
export const Signature = SignaturePad;
export const Modal = ({
  visible = true,
  style = [],
  backdrop = true,
  modalTransition,
  backdropTransition,
  fade,
  children
}) => {
  return (
    <ReactStrap.Modal
      isOpen={visible}
      backdrop={backdrop}
      modalTransition={modalTransition}
      backdropTransition={backdropTransition}
      fade={fade}
      style={[]
        .concat(style)
        .filter(_ => _)
        .reduce((reducer, style) => {
          return { ...reducer, ...style };
        }, {})}
    >
      {children}
    </ReactStrap.Modal>
  );
};
export const Keyboard = ReactNativeWeb.Keyboard;
export const Image = ReactNativeWeb.Image;
export const StyleSheet = ReactNativeWeb.StyleSheet;
export const StatusBar = ReactNativeWeb.StatusBar;
export const Dimensions = ReactNativeWeb.Dimensions;
export const Window = screen;
export const View = ReactNativeWeb.View;
export const Text = ReactNativeWeb.Text;
export const TextInput = ReactNativeWeb.TextInput;
export const ScrollView = ReactNativeWeb.ScrollView;
export const TouchableOpacity = ReactNativeWeb.TouchableOpacity;
export const Router = ReactRouterDom.BrowserRouter;
export const Route = ReactRouterDom.Route;
export const Link = ReactRouterDom.Link;
export const Switch = ReactRouterDom.Switch;
export const Svg = ({ style, children, ...props }) => {
  return (
    <svg
      style={[]
        .concat(style)
        .filter(_ => _)
        .reduce((reducer, style) => {
          return { ...reducer, ...style };
        }, {})}
      {...props}
    >
      {children}
    </svg>
  );
};
export const Path = props => <path {...props} />;
