import { ApolloBoost } from '../../../plugins';
import { GET_ME as _GET_ME } from '../Setting/_gql';
const { gql } = ApolloBoost;

export const GET_ME = _GET_ME;

export const UPDATE_PASSWORD = gql`
  mutation($email: AWSEmail, $oldPassword: String!, $password: String!) {
    userPasswordUpdate(
      email: $email
      oldPassword: $oldPassword
      password: $password
    ) {
      id
    }
  }
`;
