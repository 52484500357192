import React, { Component } from 'react';
import { Col, Flex } from '../../../rnbc/components/layout';
import Text from '../../../rnbc/components/text';
import Table from '../../../rnbc/components/table';
import styles from '../../../rnbc/styles';

export default class extends Component {
  render() {
    return (
      <Table
        configKey={'demo'}
        style={[styles.block, styles.blockh]}
        columns={[
          {
            label: 'a',
            field: 'a',
          },
          {
            label: 'b',
            field: 'b'
          },
          {
            label: 'c',
            field: 'c'
          },
          {
            label: 'a',
            field: 'a',
          },
          {
            label: 'b',
            field: 'b'
          },
          {
            label: 'c',
            field: 'c'
          },
          {
            label: 'a',
            field: 'a',
          },
          {
            label: 'b',
            field: 'b'
          },
          {
            label: 'c',
            field: 'c'
          },
          {
            label: 'a',
            field: 'a',
          },
          {
            label: 'b',
            field: 'b'
          },
          {
            label: 'c',
            field: 'c'
          }
        ]}
        data={Array(50)
          .fill(undefined)
          .map((_, i) => ({
            id: i,
            a: 'hello',
            b: Math.random(),
            c: Math.random()
          }))}
      />
    );
  }
}
