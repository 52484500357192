import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from './button';
import Text from './text';
import { Row, Col, Flex } from './layout';
import { ScrollView, View } from '../../plugins';
import styles from '../styles';

export const NavItem = ({ title = '', ...props }) => (
  <Button {...props}>
    {typeof title === 'string' ? <Text>{title}</Text> : title}
  </Button>
);

export default ({
  current = 0,
  style,
  block,
  pill,
  children,
  color = 'primary',
  onChange = _ => _,
  disabled
}) => {
  let items = children
    .reduce((reducer, child) => {
      return reducer.concat(child);
    }, [])
    .filter(_ => _);

  items = items.map((item, i) => {
    const isCurrent = item.props.active || current === i;
    const newProps = {
      key: i,
      transparent: true,
      color: item.props.color || color,
      disabled: item.props.disabled || disabled,
      onPress: () => {
        item.props.onPress && item.props.onPress();
        onChange(i);
      }
    };
    if (pill)
      return React.cloneElement(
        item,
        Object.assign(newProps, {
          transparent: !isCurrent,
          style: [styles.flex(1)].concat(item.props.style)
        })
      );

    return React.cloneElement(
      item,
      Object.assign(newProps, {
        style: [
          !!block && styles.flex(1),
          styles.margin(0),
          styles.padding(10, 15),
          styles.border(1),
          styles.rounded(5, 5, 0, 0),
          !isCurrent && {
            borderColor: 'transparent',
            borderBottomColor: styles.getColorCode('border')
          },
          i > 0 && { marginLeft: -1 },
          !!isCurrent && {
            borderBottomColor: 'transparent'
          }
        ].concat(item.props.style)
      })
    );
  });
  if (block) return <Row style={style}>{items}</Row>;
  return (
    <Row style={style}>
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
        {!pill && <Padder />}
        {items}
        {!pill && <Padder />}
      </ScrollView>
    </Row>
  );
};

export const Padder = ({ style, width = 10 }) => (
  <View
    style={[
      {
        width,
        borderBottomColor: styles.getColorCode('border'),
        borderBottomWidth: 0.5
      }
    ].concat(style)}
  />
);
