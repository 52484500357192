import React from 'react';
import { Svg, Path } from '../../../plugins';

export default ({ style, color, size }) => {
  return (
    <Svg
      style={style}
      width={size}
      height={size}
      viewBox={'0 0 512 512'}
      version='1.1'
    >
      <Path
        fill={color}
        d='M461.29 288H224V50.71c0-8.83-7.18-16.21-15.74-16.21-.69 0-1.4.05-2.11.15C87.08 51.47-3.96 155.43.13 280.07 4.2 404.1 107.91 507.8 231.93 511.87c2.69.09 5.39.13 8.07.13 121.04 0 220.89-89.66 237.35-206.16 1.33-9.45-6.52-17.84-16.06-17.84zM240 480c-2.33 0-4.68-.04-7.02-.12-107.24-3.52-197.35-93.63-200.87-200.87C28.84 179.02 96.45 92.23 192 69.83V320h250.15C420.27 412.43 336.49 480 240 480zM288.8.04c-.35-.03-.7-.04-1.04-.04C279.1 0 272 7.44 272 16.23V240h223.77c9.14 0 16.82-7.69 16.2-16.8C503.72 103.74 408.26 8.28 288.8.04zM304 208V33.9c89.25 13.81 160.28 84.85 174.1 174.1H304z'
      />
    </Svg>
  );
};
