import React from 'react';
import styles from '../../../rnbc/styles';
import Text from '../../../rnbc/components/text';
import { Col } from '../../../rnbc/components/layout';
import NavHeader from '../../../rnbc/components/navHeader';
import Loading from '../../../rnbc/components/loading';
import Form, { ModalizedSignature } from '../../../rnbc/components/form';
import { ReactApollo } from '../../../plugins';
import uploadS3 from '../../shared/upload';
import { getLocation } from '../../shared/s3';
import Alert from '../../../rnbc/components/alert';
import { errorParser } from '../../shared/errorParser';
import * as Biometric from '../../../rnbc/components/biometric';
import ActivityIndicator from '../../../rnbc/components/activityIndicator';
import { GET_QUERY, UPDATE_QUERY } from './_gql';
import Formilize from '../../../rnbc/components/formilize';
import { client } from '../../shared/apollo';

export default ({ history }) => (
  <Col style={[styles.blockh]}>
    <NavHeader title={'Signature'} back history={history} />
    <Col flex={1} fx={'center'} fy={'center'} style={[styles.padding(10)]}>
      <Text style={[styles.marginBottom(10)]}>Save your e-signature</Text>
      <ReactApollo.Query query={GET_QUERY} fetchPolicy={'cache-and-network'}>
        {({ loading, data: { me } = {} }) => {
          if (!!loading && !me) return <Loading />;
          const { email, name, position, signature: s3 } = me || {};
          return (
            <Formilize initialValues={{ s3 }}>
              {({ values, setFieldValue }) => {
                const value = getLocation(values.s3);
                return (
                  <Form style={[styles.block]}>
                    <ModalizedSignature
                      value={value}
                      issuer={value ? name : ''}
                      entitle={value ? position : ''}
                      issuedAt={''}
                      onChange={async base64 => {
                        try {
                          if (!(await Biometric.simple()))
                            throw new Error('Biometric Authorization Failed');
                          ActivityIndicator.show();
                          const s3 = await uploadS3(
                            { base64, type: 'image/png' },
                            'base64'
                          );
                          setFieldValue('s3', s3);
                          await client.mutate({
                            mutation: UPDATE_QUERY,
                            variables: {
                              email,
                              input: {
                                signature: s3
                              }
                            }
                          });
                          Alert.success('Signature Updated');
                        } catch (e) {
                          Alert.danger(errorParser(e));
                          setFieldValue('s3', undefined);
                        } finally {
                          ActivityIndicator.hide();
                        }
                      }}
                    />
                  </Form>
                );
              }}
            </Formilize>
          );
        }}
      </ReactApollo.Query>
      <Text style={[styles.marginTop(10)]}>
        You must enable the biometric authorization
      </Text>
    </Col>
  </Col>
);
