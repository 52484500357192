import React from 'react';
import { KeyboardAwareScrollView } from '../../../plugins';
import NavHeader from '../../../rnbc/components/navHeader';
import Form, { FormGroup, Label, Input } from '../../../rnbc/components/form';
import Dropdown, { DropdownItem } from '../../../rnbc/components/dropdown';
import Button from '../../../rnbc/components/button';
import Text from '../../../rnbc/components/text';
import Formilize from '../../../rnbc/components/formilize';
import styles from '../../../rnbc/styles';
import { client } from '../../shared/apollo';
import { REGISTER } from './_gql';
import Alert from '../../../rnbc/components/alert';
import { errorParser } from '../../shared/errorParser';
import FullWrapper from '../../../rnbc/components/fullWrapper';
import { BottomPadder } from '../../../rnbc/components/iphonePadder';

export default ({ history }) => {
  return (
    <FullWrapper style={[styles.column]}>
      <NavHeader title={'Register'} back history={history} />
      <KeyboardAwareScrollView>
        <Formilize
          initialValues={{
            group: 'INSPECTORS',
            email: '',
            name: '',
            password: '',
            password2: '',
            company: '',
            position: ''
          }}
          onSubmit={async (
            { email, password, password2, group, name, company, position },
            { setSubmitting }
          ) => {
            try {
              if (!email) throw new Error('Invalid Email');
              else if (!password) throw new Error('Invalid Password');
              else if (password !== password2)
                throw new Error('Password Confirmation Fail');
              else if (!company) throw new Error('Invalid Company');
              else if (!name) throw new Error('Invalid Name');
              else if (!position) throw new Error('Invalid Position');

              await client.mutate({
                mutation: REGISTER,
                variables: {
                  credentials: {
                    email,
                    password
                  },
                  input: { group, name, company, position }
                }
              });
              Alert.success('Account Activated');
              history.goBack();
            } catch (e) {
              Alert.danger(errorParser(e));
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <Form style={[styles.padding(10)]}>
              <FormGroup>
                <Label>
                  Email <Text color={'danger'}>**</Text>
                </Label>
                <Input
                  disabled={isSubmitting}
                  value={values.email}
                  onChangeText={val => setFieldValue('email', val)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Password <Text color={'danger'}>**</Text>
                </Label>
                <Input
                  disabled={isSubmitting}
                  value={values.password}
                  autoCompleteType={'password'}
                  secureTextEntry={true}
                  onChangeText={val => setFieldValue('password', val)}
                  onSubmitEditing={handleSubmit}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Confirm Password <Text color={'danger'}>**</Text>
                </Label>
                <Input
                  disabled={isSubmitting}
                  value={values.password2}
                  autoCompleteType={'password2'}
                  secureTextEntry={true}
                  onChangeText={val => setFieldValue('password2', val)}
                  onSubmitEditing={handleSubmit}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Name
                  <Text color={'danger'}>**</Text>
                </Label>
                <Input
                  disabled={isSubmitting}
                  value={values.name}
                  onChangeText={val => setFieldValue('name', val)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Group
                  <Text color={'danger'}>**</Text>
                </Label>
                <Dropdown disabled={isSubmitting} outline title={values.group}>
                  <DropdownItem
                    title={'Inspector'}
                    onPress={() => setFieldValue('group', 'INSPECTORS')}
                  />
                  <DropdownItem
                    title={'Contractor'}
                    onPress={() => setFieldValue('group', 'CONTRACTORS')}
                  />
                  <DropdownItem
                    title={'Consultant'}
                    onPress={() => setFieldValue('group', 'CONSULTANTS')}
                  />
                </Dropdown>
              </FormGroup>
              <FormGroup>
                <Label>
                  Company
                  <Text color={'danger'}>**</Text>
                </Label>
                <Input
                  disabled={isSubmitting}
                  value={values.company}
                  onChangeText={val => setFieldValue('company', val)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Position
                  <Text color={'danger'}>**</Text>
                </Label>
                <Input
                  disabled={isSubmitting}
                  value={values.position}
                  onChangeText={val => setFieldValue('position', val)}
                />
              </FormGroup>

              <Button onPress={handleSubmit} loading={isSubmitting}>
                <Text>Sign Up</Text>
              </Button>
            </Form>
          )}
        </Formilize>
        <BottomPadder />
      </KeyboardAwareScrollView>
    </FullWrapper>
  );
};
