import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '../../rnbc/components/layout';
import Button from '../../rnbc/components/button';
import Text from '../../rnbc/components/text';
import styles from '../../rnbc/styles';
import Small from '../../rnbc/components/form/small';
import FieldLoading from './fieldLoading';
import moment from 'moment';
import Icon from '../../rnbc/icon';
import FieldUserGroupPositionsRemark from './FieldUserGroupPositionsRemark';

export default class SubmitField extends FieldLoading {
  render() {
    const {
        label,
        disabled,
        onPress,
        updatedBy,
        updatedAt,
        userGroup,
        userPositions,
        description
      } = this.props,
      { isOnline } = this.state;
    return (
      <Col style={[styles.padding(20, 0)]}>
        {!!description && <Small>{description}</Small>}
        <Row fy={'center'}>
          <Button
            disabled={disabled || !isOnline}
            style={[styles.marginRight(10), { width: '100%', maxWidth: 500 }]}
            onPress={onPress}
          >
            <Text>{label}</Text>
            {!isOnline && (
              <Icon name={'wifi-slash'} size={styles.h5.fontSize} />
            )}
          </Button>
          <FieldUserGroupPositionsRemark
            userPositions={userPositions}
            userGroup={userGroup}
          />
        </Row>
        {!!updatedAt && !!updatedBy && (
          <Col fx={'flex-end'} style={[{ width: '100%', maxWidth: 500 }]}>
            {!!updatedBy.name && <Small>{updatedBy.name}</Small>}
            {!!updatedBy.position && (
              <Small style={[styles.color('danger'), styles.bold]}>
                {updatedBy.position}
              </Small>
            )}
            {!!updatedAt && (
              <Small>{moment(updatedAt).format('YYYY-MM-DD HH:mm')}</Small>
            )}
          </Col>
        )}
      </Col>
    );
  }
}
SubmitField.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  updatedBy: PropTypes.any,
  updatedAt: PropTypes.string,
  description: PropTypes.string
};
