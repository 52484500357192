import React from 'react';
import PropTypes from 'prop-types';
import RevisionRowsTable from '../Revisions/_revisionRowsTable';
import { ReactApollo } from '../../../plugins';
import { GET_REVISION_ROWS_REVAMPED } from '../Revisions/_gql';
import NavHeader from '../../../rnbc/components/navHeader';
import { limits } from '../Revisions/_pager';

export default class _RevisionRowsTable extends RevisionRowsTable {
  renderRowFetcher = columns => {
    const { projectId, formId, filter, cursor, view } = this.props,
      { sorting } = this.state,
      { field, order } = sorting || {};
    this.variables = {
      cursor,
      limits,
      view,
      sortBy: [
        (field && order && sorting) || { field: 'revision', order: 'DESC' }
      ].filter(_ => _),
      filter
    };
    return (
      <ReactApollo.Query
        query={GET_REVISION_ROWS_REVAMPED(projectId, formId)}
        variables={this.variables}
        fetchPolicy={
          NavHeader.lastBackReferrer.match('/Revision/')
            ? 'cache-first'
            : 'cache-and-network'
        }
        onCompleted={data => {
          const { onConnection } = this.props;
          !!onConnection && onConnection(this.parseConnection(data));
        }}
      >
        {res => this.renderContent(res, columns)}
      </ReactApollo.Query>
    );
  };
}
_RevisionRowsTable.propTypes = {
  projectId: PropTypes.string,
  formId: PropTypes.string,
  onExportData: PropTypes.func,
  onEditPress: PropTypes.func,
  filter: PropTypes.array,
  cursor: PropTypes.any,
  onConnection: PropTypes.func,
  view: PropTypes.string
};
_RevisionRowsTable.defaultProps = {
  onEditPress: _ => _,
  filter: [],
  onConnection: _ => _
};
