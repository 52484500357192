import React from 'react';
import { OS } from '../../../plugins';
import { Col } from '../layout';
import _Label from './label';
import _FormGroup from './formGroup';
import _Input from './input';
import _FileInputMultipleS3_2 from './fileInputMultipleS3_2';
import _FileInputS3_2 from './fileInputS3_2';
import _FileInputS3_2Web from './fileInputS3_2_web';
import _Textarea from './textarea';
import _Small from './small';
import _Checkbox from './checkbox';
import _InputGroup, {
  InputGroupAppend as _InputGroupAppend
} from './inputGroup';
import _RadioGroup, { Radio as _Radio } from './radioGroup';
import _ModalizedInputDate from './modalized/inputDate';
import _ModalizedInputTime from './modalized/inputTime';
import _ModalizedInput from './modalized/input';
import _ModalizedInputSelect from './modalized/inputSelect';
import _ModalizedSignature from './modalized/signature';
import _Modalized2DSelect from './modalized/2dSelect';
import _ModalizedInputUsers from './modalized/inputUsers';
import _ModalizedSelect from './modalized/select';
import _ModalizedTextarea from './modalized/inputTextarea';

export const FileInputS3_2 = {
  android: _FileInputS3_2,
  ios: _FileInputS3_2,
  web: _FileInputS3_2Web
}[OS];
export const Label = _Label;
export const FormGroup = _FormGroup;
export const Input = _Input;
export const InputGroupAppend = _InputGroupAppend;
export const InputGroup = _InputGroup;
export const FileInputMultipleS3_2 = _FileInputMultipleS3_2;
export const Small = _Small;
export const Checkbox = _Checkbox;
export const RadioGroup = _RadioGroup;
export const Radio = _Radio;
export const Textarea = _Textarea;
export const ModalizedInput = _ModalizedInput;
export const ModalizedInputSelect = _ModalizedInputSelect;
export const ModalizedSelect = _ModalizedSelect;
export const ModalizedSignature = _ModalizedSignature;
export const Modalized2DSelect = _Modalized2DSelect;
export const ModalizedInputTime = _ModalizedInputTime;
export const ModalizedInputDate = _ModalizedInputDate;
export const ModalizedInputUsers = _ModalizedInputUsers;
export const ModalizedTextarea = _ModalizedTextarea;

export default ({ style, children, ...props }) => (
  <Col style={style} {...props}>
    {children}
  </Col>
);
