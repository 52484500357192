import React from 'react';
import PropTypes from 'prop-types';

export default class FileInput extends React.Component {
  handleUpload = e => {
    const { onChange } = this.props;
    const files = e.target.files;
    if (files.length > 0 && this.validate(files)) onChange(files);
    this.fileInput.value = null;
  };

  validate = files => {
    const { onError, maxSize } = this.props;
    if (!maxSize) return true;
    const maxBytes = maxSize * 1000000;
    for (const file of files) {
      if (file.size > maxBytes) {
        onError(`File size must be less than ${maxSize} MB.`);
        return false;
      }
    }
    return true;
  };

  render() {
    const { children, multiple, style } = this.props;
    return (
      <div style={style}>
        <input
          type='file'
          multiple={multiple}
          style={{ display: 'none' }}
          onChange={this.handleUpload}
          ref={ref => (this.fileInput = ref)}
        />
        {React.cloneElement(children, {
          onClick: () => this.fileInput.click()
        })}
      </div>
    );
  }
}
FileInput.propTypes = {
  maxSize: PropTypes.number,
  style: PropTypes.any,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  multiple: PropTypes.bool
};
FileInput.defaultProps = {
  style: {},
  onChange: _ => _,
  onError: _ => _,
  multiple: false
};
