import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Nav, { NavItem, Padder } from './nav';
import { OS, View } from '../../plugins';
import { Row, Col, Flex } from './layout';
import styles from '../styles';

export default class TabContainer extends Component {
  state = { current: this.props.defaultTab, displayed: {} };

  componentDidMount() {
    const { current } = this.state;
    this.setState({ displayed: { [current]: true } });
  }

  onNavChange = current => {
    const { displayed } = this.state;
    this.setState({ current, displayed: { ...displayed, [current]: true } });
  };
  render() {
    const { tabs, style } = this.props,
      { current, displayed } = this.state;

    return (
      <Col style={style}>
        <Row style={[OS === 'web' && { overflow: 'auto' }]}>
          <Nav current={current} onChange={this.onNavChange}>
            {tabs.map(({ title }, i) => {
              return <NavItem key={i} title={title} />;
            })}
          </Nav>
          <Padder style={[styles.flex(1)]} />
        </Row>
        <Flex style={[{ position: 'relative' }]}>
          {tabs.map(({ component }, i) => {
            const isCurrent = i === current;
            if (displayed[i])
              return (
                <View
                  key={i}
                  pointerEvents={!!isCurrent ? 'auto' : 'none'}
                  style={[
                    !!isCurrent && styles.backgroundColor('white'),
                    !!isCurrent && { zIndex: 1 },
                    !isCurrent && styles.absolute({}),
                    !isCurrent && { zIndex: -1, opacity: 0, display: 'none' }
                  ]}
                >
                  {component}
                </View>
              );
            else return null;
          })}
        </Flex>
      </Col>
    );
  }
}
TabContainer.propTypes = {
  defaultTab: PropTypes.number,
  style: PropTypes.any,
  tabs: PropTypes.array
};
TabContainer.defaultProps = {
  defaultTab: 0
};
