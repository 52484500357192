import {
  Dimensions,
  BrandColor,
  ReactNativeIPhoneXHelper,
  Platform,
  Orientation
} from '../plugins';
import Color from 'color';

Orientation.unlockAllOrientations();
const getOrientation = () => {
  const { width, height } = Dimensions.get('window');
  return width > height ? 'landscape' : 'portrait';
};
const isNULL = (...args) => {
  for (let arg of args) if (arg != null) return arg;
  return null;
};
const getColorCode = input => {
  if (!input)
    return `rgb(${Math.random() * 255}, ${Math.random() *
      255}, ${Math.random() * 255})`;

  if (/transparent/.test(input)) {
    return 'transparent';
  }

  let color = BrandColor(input);
  if (!!color) return color;

  switch (input) {
    case 'border':
      return '#cbcbcb';

    case 'gray':
      return '#7d7d7d';

    case 'bg':
      return '#eeeeee';

    case 'primary-bg':
      return '#c8e1ff';
    case 'primary':
      return '#007bff';
    case 'primary-text':
      return '#428bca';

    case 'secondary-bg':
      return '#dddddd';
    case 'secondary':
      return '#6c757d';
    case 'secondary-text':
      return '#343a40';

    case 'success-bg':
      return '#dff0d8';
    case 'success':
      return '#28a745';
    case 'success-text':
      return '#3c763d';

    case 'danger-bg':
      return '#f2dede';
    case 'danger':
      return '#dc3545';
    case 'danger-text':
      return '#a94442';

    case 'warning-bg':
      return '#fcf8e3';
    case 'warning':
      return '#ffc107';
    case 'warning-text':
      return '#8a6d3b';

    case 'info-bg':
      return '#d9edf7';
    case 'info':
      return '#17a2b8';
    case 'info-text':
      return '#31708f';

    case 'light-bg':
      return '#f0f1f2';
    case 'light':
      return '#f0f1f2';
    case 'light-text':
      return '#6e6f70';

    case 'dark-bg':
      return '#d1d1d1';
    case 'dark':
      return '#343a40';
    case 'dark-text':
      return '#343a40';
  }

  try {
    const operators = [
      'darken',
      'lighten',
      'negate',
      'saturate',
      'desaturate',
      'whiten',
      'blacken',
      'grayscale',
      'fade',
      'opaquer',
      'rotate'
    ];
    for (let operator of operators) {
      if (new RegExp(`^${operator}-`).test(input)) {
        let value = input.replace(
            new RegExp(`^${operator}-(.*?)-(.*?)$`),
            '$1'
          ),
          type = input.replace(new RegExp(`^${operator}-(.*?)-(.*?)$`), '$2');
        return Color(getColorCode(type))
          [operator](value - 0)
          .hex();
      }
    }
    if (/^anti-/.test(input)) {
      let type = input.replace(/^anti-(.*?)$/, '$1');
      let color = Color(getColorCode(type)).negate();
      return color.isLight() ? color.lighten(2).hex() : color.darken(2).hex();
    }
    return input;
  } catch (e) {
    return getColorCode();
  }
};

export default {
  header: {
    height: 46
  },
  getIphoneTopPadder: () => ({
    height: Platform.select({
      ios: {
        portrait: ReactNativeIPhoneXHelper.getStatusBarHeight(true),
        landscape: 0
      }[getOrientation()],
      default: 0
    })
  }),
  getIphoneBottomPadder: (add = 0) => ({
    height: ReactNativeIPhoneXHelper.getBottomSpace() + add
  }),
  content: {
    height: Dimensions.get('window').height - 46
  },
  fill: {
    width: '100%',
    height: '100%'
  },
  absolute: ({ left, top, right, bottom, zIndex = 1 }) => ({
    position: 'absolute',
    left,
    top,
    right,
    bottom,
    zIndex
  }),
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  fullFloating: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height
  },
  row: {
    flexDirection: 'row'
  },
  column: {
    flexDirection: 'column'
  },
  flex: (val = 1) => ({
    flex: val
  }),
  rounded: (topLeft = 5, topRight, bottomLeft, bottomRight) => ({
    borderTopLeftRadius: topLeft,
    borderTopRightRadius: isNULL(topLeft, topLeft),
    borderBottomLeftRadius: isNULL(bottomLeft, topLeft),
    borderBottomRightRadius: isNULL(bottomRight, bottomLeft, topLeft),
    overflow: 'hidden'
  }),
  center: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  cutoff: {
    backgroundColor: 'transparent',
    overflow: 'hidden'
  },
  getColorCode,
  backgroundColor: input => ({ backgroundColor: getColorCode(input) }),
  color: input => ({ color: getColorCode(input) }),
  small: { fontSize: 12.8 },
  p: { fontSize: 16 },
  h1: { fontSize: 32 },
  h2: { fontSize: 28 },
  h3: { fontSize: 24 },
  h4: { fontSize: 20 },
  h5: { fontSize: 18 },
  bold: { fontWeight: 'bold' },
  vw: (ratio = 1) => ({
    width: Dimensions.get('window').width * ratio
  }),
  vh: (ratio = 1) => ({
    height: Dimensions.get('window').height * ratio
  }),
  block: { width: '100%' },
  blockh: { height: '100%' },
  padding: (top, right, bottom, left) => ({
    paddingTop: top,
    paddingRight: isNULL(right, top),
    paddingBottom: isNULL(bottom, top),
    paddingLeft: isNULL(left, right, top)
  }),
  paddingX: paddingX => ({
    paddingLeft: paddingX,
    paddingRight: paddingX
  }),
  paddingY: paddingY => ({
    paddingTop: paddingY,
    paddingBottom: paddingY
  }),
  paddingTop: paddingTop => ({
    paddingTop
  }),
  paddingBottom: paddingBottom => ({
    paddingBottom
  }),
  paddingLeft: paddingLeft => ({ paddingLeft }),
  paddingRight: paddingRight => ({ paddingRight }),
  margin: (top, right, bottom, left) => ({
    marginTop: top,
    marginRight: isNULL(right, top),
    marginBottom: isNULL(bottom, top),
    marginLeft: isNULL(left, right, top)
  }),
  marginX: (left, right) => ({
    marginLeft: left,
    marginRight: isNULL(right, left)
  }),
  marginY: (top, bottom) => ({
    marginTop: top,
    marginBottom: isNULL(bottom, top)
  }),
  marginTop: marginTop => ({
    marginTop
  }),
  marginBottom: marginBottom => ({
    marginBottom
  }),
  marginLeft: marginLeft => ({ marginLeft }),
  marginRight: marginRight => ({ marginRight }),
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3
  },
  border: (top, right, bottom, left, color = 'border') => ({
    borderColor: getColorCode(color),
    borderTopWidth: top,
    borderRightWidth: isNULL(right, top),
    borderBottomWidth: isNULL(bottom, top),
    borderLeftWidth: isNULL(left, right, top)
  }),
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  responsiveBreakPoint: {
    xs: 340,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }
};
