import React from 'react';
import { ReactApollo, KeyboardAwareScrollView } from '../../../plugins';
import { Col } from '../../../rnbc/components/layout';
import NavHeader from '../../../rnbc/components/navHeader';
import Loading from '../../../rnbc/components/loading';
import { BottomPadder } from '../../../rnbc/components/iphonePadder';
import Form, { FormGroup, Label, Input } from '../../../rnbc/components/form';
import Button from '../../../rnbc/components/button';
import Text from '../../../rnbc/components/text';
import Formilize from '../../../rnbc/components/formilize';
import styles from '../../../rnbc/styles';
import { client } from '../../shared/apollo';
import { GET_ME, UPDATE_PASSWORD } from './_gql';
import Alert from '../../../rnbc/components/alert';
import { errorParser } from '../../shared/errorParser';

export default ({ history }) => {
  return (
    <Col style={[styles.blockh]}>
      <NavHeader title={'Reset Password'} back history={history} />
      <KeyboardAwareScrollView>
        <ReactApollo.Query query={GET_ME}>
          {({ loading, data }) => {
            if (!!loading && !data.me) return <Loading />;
            const { me } = data || {},
              { email } = me || {};
            return (
              <Formilize
                initialValues={{ email, current: '', new: '' }}
                onSubmit={async (values, { reset }) => {
                  try {
                    if (!values.current || !values.new)
                      throw new Error('Invalid Input');

                    await client.mutate({
                      mutation: UPDATE_PASSWORD,
                      variables: {
                        email: values.email,
                        oldPassword: values.current,
                        password: values.new
                      }
                    });

                    reset();
                    Alert.success('Password Updated');
                  } catch (e) {
                    Alert.danger(errorParser(e));
                  }
                }}
              >
                {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
                  <Form style={[styles.padding(10)]}>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input disabled value={values.email} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Current Password</Label>
                      <Input
                        disabled={isSubmitting}
                        value={values.current}
                        autoCompleteType={'password'}
                        secureTextEntry={true}
                        onChangeText={val => setFieldValue('current', val)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>New Password</Label>
                      <Input
                        disabled={isSubmitting}
                        value={values.new}
                        autoCompleteType={'password'}
                        secureTextEntry={true}
                        onChangeText={val => setFieldValue('new', val)}
                        onSubmitEditing={handleSubmit}
                      />
                    </FormGroup>
                    <Button onPress={handleSubmit} loading={isSubmitting}>
                      <Text>Submit</Text>
                    </Button>
                  </Form>
                )}
              </Formilize>
            );
          }}
        </ReactApollo.Query>
        <BottomPadder />
      </KeyboardAwareScrollView>
    </Col>
  );
};
