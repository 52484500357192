import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RNImage, View } from '../../plugins';
import styles from '../styles';

class HasBackground extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoSize: {}
    };
  }

  componentDidMount() {
    const { resizeMode, source } = this.props;
    if (resizeMode === 'auto' && source && source.uri) {
      RNImage.getSize(source.uri, (width, height) => {
        console.log(width, height);
        this.setState({ autoSize: { width, height } });
      });
    }
  }

  render() {
    const {
        style,
        imageStyle,
        shaded,
        shadedColor,
        resizeMode,
        source,
        imageOnLoad,
        children
      } = this.props,
      { autoSize } = this.state;
    return (
      <View
        {...this.props}
        style={[styles.fill, styles.backgroundColor(shadedColor)].concat(style)}
      >
        {resizeMode === 'auto' ? (
          <RNImage
            onLoad={imageOnLoad}
            style={[
              styles.absolute({ top: 0, left: 0, zIndex: 0 }),
              {
                width: autoSize.width || 0,
                height: autoSize.height || 0,
                position: 'absolute',
                opacity: shaded
              }
            ].concat(imageStyle)}
            source={source}
          />
        ) : (
          <RNImage
            onLoad={imageOnLoad}
            style={[
              styles.absolute({
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 0
              }),
              { opacity: shaded }
            ].concat(imageStyle)}
            source={source}
            resizeMode={resizeMode}
          />
        )}

        {children}
      </View>
    );
  }
}
HasBackground.propTypes = {
  style: PropTypes.any,
  imageStyle: PropTypes.any,
  shaded: PropTypes.number,
  shadedColor: PropTypes.string,
  source: PropTypes.any,
  resizeMode: PropTypes.string,
  imageOnLoad: PropTypes.func
};
HasBackground.defaultProps = {
  resizeMode: 'cover',
  shadedColor: 'black',
  shaded: 1
};

export default HasBackground;
