import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row } from '../../layout';
import styles from '../../../styles';
import { TableSkeleton } from '../index';
import { AsyncStorage, ScrollView } from '../../../../plugins';
import Core from './_core';
import JSONParser from '../../../../wrapper/shared/JSONParser';
import ConfigModal from '../ConfigModal';

export default class Table extends Component {
  state = {
    key_table: 0,
    ready: false,
    selectedIndex: -1,
    config: {}
  };
  async componentDidMount(key) {
    const { configKey } = this.props;
    this.setState({
      config: JSONParser(
        (await AsyncStorage.getItem(`table:config:${configKey}`)) || '{}'
      ),
      selectedIndex:
        (await AsyncStorage.getItem('table:selectedIndex')) - 0 || 0,
      ready: true
    });
  }
  async componentWillUnmount() {
    const { selectedIndex } = this.state;
    await AsyncStorage.setItem('table:selectedIndex', `${selectedIndex}`);
  }
  onHeaderPress = col => {
    const { sorting = {}, onSortChange } = this.props;
    if (!!sorting && sorting.field === col.field) {
      if (sorting.order === 'ASC')
        onSortChange({
          field: col.field,
          order: 'DESC'
        });
      else
        onSortChange({
          field: col.field,
          order: 'ASC'
        });
    } else {
      onSortChange({
        field: col.field,
        order: 'ASC'
      });
    }
  };
  render() {
    const {
        style,
        columns,
        sorting,
        data,
        onRowPress,
        onRowDeepPress,
        padderCount,
        onEndReached,
        loading
      } = this.props,
      {
        key_table,
        selectedIndex,
        ready,
        config: { hiddenFields = [] } = {}
      } = this.state;
    if (!ready) return <TableSkeleton />;
    const rows = data;

    const displayColumns = columns.filter(
      ({ field = '' } = {}) => !~hiddenFields.indexOf(field)
    );

    return (
      <Fragment>
        <Row key={key_table} style={[{ position: 'relative' }].concat(style)}>
          <ScrollView style={[styles.flex(1)]} horizontal bounces={false}>
            <Core
              loading={loading}
              leftShiftPadding={0}
              onEndReached={onEndReached}
              selectedIndex={selectedIndex}
              columns={displayColumns}
              onRowPress={(row, i) => {
                if (selectedIndex === i) onRowDeepPress(row, i);
                else {
                  this.setState({ selectedIndex: i });
                  onRowPress(row, i);
                }
              }}
              initialScrollIndex={Math.min(selectedIndex, rows.length - 1)}
              onHeaderPress={this.onHeaderPress}
              data={rows}
              padderCount={padderCount}
              sorting={sorting}
            />
          </ScrollView>
        </Row>
        {this.renderConfigModal()}
      </Fragment>
    );
  }
  renderConfigModal() {
    const { key_table, config } = this.state;
    const { columns, configKey, configButtonStyle } = this.props;
    if (!configKey) return null;
    return (
      <ConfigModal
        style={configButtonStyle}
        columns={columns}
        config={config}
        onChange={async config => {
          await new Promise(resolve => setTimeout(resolve, 250));
          this.setState({ config, key_table: key_table + 1 });
          if (!!configKey)
            await AsyncStorage.setItem(
              `table:config:${configKey}`,
              JSON.stringify(config)
            );
        }}
      />
    );
  }
}
Table.propTypes = {
  configButtonStyle: PropTypes.any,
  configKey: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  style: PropTypes.any,
  onRowPress: PropTypes.func,
  onRowDeepPress: PropTypes.func,
  onSortChange: PropTypes.func,
  sorting: PropTypes.any,
  padderCount: PropTypes.number,
  loading: PropTypes.bool
};
Table.defaultProps = {
  configButtonStyle: { bottom: 20 },
  columns: [],
  data: [],
  onRowPress: _ => _,
  onRowDeepPress: _ => _,
  onSortChange: _ => _,
  padderCount: 0
};
