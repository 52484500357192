import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  View,
  TouchableOpacity,
  OS,
  Modal,
  KeyboardAwareScrollView
} from '../../../../plugins';
import Dropdown, { DropdownItem } from '../../dropdown';
import ZoomableView from '../../zoomableView';
import ZoomableView2 from '../../zoomableView2';
import ModalizedInput from './input';
import { Col, Row } from '../../layout';
import Small from '../small';
import Text from '../../text';
import ActualSizeImage from '../../actualSizeImage';
import Button from '../../button';
import Icon from '../../../icon';
import styles from '../../../styles';
import Alert from '../../alert';
import DoneBar from './doneBar';
import Form, { FormGroup, Label, Input } from '../index';
import { BottomPadder, TopPadder } from '../../iphonePadder';
import FullWrapper from '../../fullWrapper';

export default class Modalized2DSelect extends ModalizedInput {
  state = {
    ...this.state,
    showUpdateModal: false,
    random: 0,
    selectedMapIndex: 0
  };
  componentDidMount() {}
  componentWillUnmount() {}
  onDisplayerPress = () => {
    const { value } = this.props;

    if (!!value && !!value.name) {
      this.setState({ value, showUpdateModal: true });
    } else {
      this.onMapPress();
    }
  };
  onMapPress = () => {
    const { maps } = this.props;
    if (maps.length === 0) Alert.danger('No Maps found');
    else
      this.setState({
        show: true
      });
  };
  onRequestClose = () => {
    this.setState({ show: false, showUpdateModal: false });
  };
  renderDisplayer() {
    const {
      value: {
        name,
        latitude,
        longitude,
        altitude,
        x,
        y,
        radius,
        __typename,
        ...others
      } = {},
      style,
      disabled
    } = this.props;
    return (
      <TouchableOpacity
        activeOpacity={!disabled ? 0.5 : 1}
        onPress={!disabled && this.onDisplayerPress}
        style={[
          styles.row,
          styles.border(1),
          styles.rounded(5),
          styles.backgroundColor(!disabled ? 'white' : 'bg'),
          styles.padding(10, 12),
          { minHeight: 35 }
        ].concat(style)}
      >
        <Col flex={1} fy={'center'}>
          <Text>{name}</Text>
          {Object.keys(others).length > 0 && (
            <Text>
              {Object.keys(others)
                .filter(key => others[key] !== null)
                .map(key => `${others[key]}`)
                .join('  ')}
            </Text>
          )}
          <Text style={[styles.marginTop(10)]}>
            x:{latitude}
            {'  '}y:{longitude}
            {'  '}z:{altitude}
          </Text>
        </Col>
        <Col fy={'center'}>
          <Button
            disabled={disabled}
            style={[styles.column, { justifyContent: 'center' }]}
            transparent
            onPress={this.onMapPress}
          >
            <Icon name={'map-marked-alt'} size={styles.h2.fontSize} />
            <Small>Pick from MAP</Small>
          </Button>
        </Col>
      </TouchableOpacity>
    );
  }
  render() {
    return (
      <Fragment>
        {this.renderDisplayer()}
        {this.renderModal()}
        {this.renderUpdateModal()}
      </Fragment>
    );
  }
  renderWidgetWrapper() {
    return this.renderWidget();
  }
  renderBlackRegion() {
    return null;
  }
  renderUpdateModal() {
    const { onChange } = this.props;
    const { showUpdateModal, value } = this.state;
    if (!showUpdateModal) return null;
    const {
      name,
      x,
      y,
      radius,
      __typename,
      latitude,
      longitude,
      altitude,
      ...others
    } = value;
    const otherKeys = Object.keys(others);
    otherKeys.sort();
    otherKeys.reverse();
    return (
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        onRequestClose={this.onRequestClose}
      >
        <FullWrapper style={[styles.column, styles.backgroundColor('white')]}>
          <TopPadder />
          <DoneBar
            leftWidget={
              <Button transparent onPress={this.onRequestClose}>
                <Icon name={'arrow-left'} />
              </Button>
            }
            onDonePress={() => {
              const { value } = this.state;
              onChange(value);
              this.onRequestClose();
            }}
          />
          <Col flex={1}>
            <KeyboardAwareScrollView style={[styles.fill]}>
              <Form style={[styles.padding(10)]}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    autoFocus
                    value={name || ''}
                    onChangeText={name =>
                      this.setState({ value: { ...value, name } })
                    }
                  />
                </FormGroup>
                {otherKeys.length > 0 &&
                  otherKeys
                    .filter(key => others[key] !== null)
                    .map((key, i) => (
                      <FormGroup key={i}>
                        <Label>{key}</Label>
                        <Input
                          autoFocus
                          value={others[key] || ''}
                          onChangeText={text =>
                            this.setState({ value: { ...value, [key]: text } })
                          }
                        />
                      </FormGroup>
                    ))}
                <FormGroup>
                  <Label>Latitude (x)</Label>
                  <Input
                    keyboardType={'numeric'}
                    value={(latitude || '').toString()}
                    onChangeText={val =>
                      this.setState({
                        value: { ...value, latitude: val - 0 || 0 }
                      })
                    }
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Longitude (y)</Label>
                  <Input
                    keyboardType={'numeric'}
                    value={(longitude || '').toString()}
                    onChangeText={val =>
                      this.setState({
                        value: { ...value, longitude: val - 0 || 0 }
                      })
                    }
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Altitude (z)</Label>
                  <Input
                    keyboardType={'numeric'}
                    value={(altitude || '').toString()}
                    onChangeText={val =>
                      this.setState({
                        value: { ...value, altitude: val - 0 || 0 }
                      })
                    }
                    disabled
                  />
                </FormGroup>
              </Form>
              <BottomPadder />
            </KeyboardAwareScrollView>
          </Col>
        </FullWrapper>
      </Modal>
    );
  }
  renderDoneBar() {
    const { selectedMapIndex } = this.state,
      { maps } = this.props;
    return (
      <Fragment>
        <TopPadder />
        <Row
          style={[
            styles.header,
            styles.shadow,
            styles.backgroundColor('white')
          ]}
        >
          <Button transparent onPress={this.onRequestClose}>
            <Icon name={'arrow-left'} />
          </Button>
          <Dropdown
            rounded={false}
            transparent
            style={[styles.margin(0), styles.header, styles.flex(1)]}
            title={maps[selectedMapIndex].name}
          >
            {maps.map(({ name }, i) => (
              <DropdownItem
                key={i}
                title={name}
                onPress={() => {
                  this.setState({ selectedMapIndex: i, random: Math.random() });
                }}
              />
            ))}
          </Dropdown>
        </Row>
      </Fragment>
    );
  }
  renderWidget() {
    const { random, selectedMapIndex } = this.state,
      { maps, onChange } = this.props;
    const ZoomWidget = OS === 'web' ? ZoomableView : ZoomableView2;

    return (
      <View style={[styles.flex(1), { overflow: 'hidden' }]}>
        <ZoomWidget
          key={random}
          bindToBorders={false}
          onResetPress={() => {
            this.setState({ random: Math.random() });
          }}
        >
          <TouchableOpacity activeOpacity={1}>
            <ActualSizeImage source={{ uri: maps[selectedMapIndex].image }} />
          </TouchableOpacity>
          {maps[selectedMapIndex].locations.map((location, i) => {
            const { x = 0, y = 0, radius = 30, name } = location;
            return (
              <TouchableOpacity
                key={i}
                onPress={() => {
                  this.onRequestClose();
                  onChange && onChange(location);
                }}
                style={[
                  {
                    position: 'absolute',
                    top: y - radius,
                    left: x - radius,
                    width: 2 * radius,
                    height: 2 * radius,
                    borderRadius: radius,
                    opacity: 0.5
                  }
                ]}
              />
            );
          })}
        </ZoomWidget>
      </View>
    );
  }
}
Modalized2DSelect.propTypes = {
  ...ModalizedInput.propTypes,
  onChange: PropTypes.func,
  maps: PropTypes.array
};
Modalized2DSelect.defaultProps = {
  ...ModalizedInput.defaultProps,
  maps: []
};
