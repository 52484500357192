import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '../../rnbc/components/layout';
import Button from '../../rnbc/components/button';
import Text from '../../rnbc/components/text';
import { Small, FormGroup, Label } from '../../rnbc/components/form';
import styles from '../../rnbc/styles';
import FieldLoading from './fieldLoading';
import Icon from '../../rnbc/icon';

export default class WithdrawalField extends FieldLoading {
  render() {
    const {
        me = {},
        label,
        userGroup,
        disabled,
        finalized,
        onPress,
        withdrawnBy,
        onApprove,
        description,
        onReject
      } = this.props,
      { isOnline } = this.state;
    const isWithdrawn = !!withdrawnBy;
    const canApprove = userGroup !== me.group;
    if (!!finalized) {
      if (!!isWithdrawn)
        return (
          <Row>
            <Button
              outline
              color={'danger'}
              style={[styles.flex(1), { maxWidth: 500 }]}
            >
              <Small>Withdrawn by {withdrawnBy.name}</Small>
            </Button>
          </Row>
        );
      return null;
    }

    if (!isWithdrawn)
      return (
        <Row>
          <Button
            color={'danger'}
            disabled={disabled || !isOnline}
            style={[styles.flex(1), { maxWidth: 500 }]}
            onPress={onPress}
          >
            <Text>{label}</Text>
            {!isOnline && (
              <Icon name={'wifi-slash'} size={styles.h5.fontSize} />
            )}
          </Button>
        </Row>
      );

    if (!canApprove)
      return (
        <Row>
          <Button
            outline
            color={'danger'}
            style={[styles.flex(1), { maxWidth: 500 }]}
          >
            <Small>Withdrawing by {withdrawnBy.name}</Small>
          </Button>
        </Row>
      );
    return (
      <FormGroup style={[styles.marginTop(10)]}>
        <Label>Withdrawing by {withdrawnBy.name}</Label>
        <Row>
          <Button
            disabled={!isOnline}
            color={'success'}
            style={[styles.marginRight(10)]}
            onPress={onApprove}
          >
            <Text>Approve</Text>
            {!isOnline && (
              <Icon name={'wifi-slash'} size={styles.h5.fontSize} />
            )}
          </Button>
          <Button color={'danger'} onPress={onReject}>
            <Text>Reject</Text>
            {!isOnline && (
              <Icon name={'wifi-slash'} size={styles.h5.fontSize} />
            )}
          </Button>
        </Row>
        {!!description && <Small>{description}</Small>}
      </FormGroup>
    );
  }
}
WithdrawalField.propTypes = {
  me: PropTypes.any,
  label: PropTypes.string,
  userGroup: PropTypes.string,
  disabled: PropTypes.bool,
  finalized: PropTypes.bool,
  onPress: PropTypes.func,
  withdrawnBy: PropTypes.any,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  description: PropTypes.string
};
