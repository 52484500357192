import { ApolloBoost } from '../../../plugins';
const { gql } = ApolloBoost;

export const GET_PROJECTS = gql`
  query($cursor: ID, $limits: Int, $filter: ProjectFilter) {
    me {
      id
      projects(cursor: $cursor, limits: $limits, filter: $filter) {
        nextCursor
        totalCount
        nodes {
          id
          name
          image {
            key
            bucket
            region
          }
        }
      }
    }
  }
`;
