import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Formilize extends Component {
  state = {
    values: { ...this.props.initialValues },
    isSubmitting: false
  };
  componentDidMount() {
    this._mounted = true;
  }
  componentWillReceiveProps(nextProps): void {
    if (nextProps.initialValues !== this.props.initialValues) {
      this.setState({ values: { ...nextProps.initialValues } });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  reset = () => {
    const { initialValues } = this.props;
    this.setState({ values: { ...initialValues } });
  };
  setFieldValue = (key, value, broadcast = true) => {
    const { onFieldUpdate } = this.props;
    const { values } = this.state;
    values[key] = value;
    this._mounted && this.setState({ values });
    !!broadcast && onFieldUpdate(key, value);
  };
  handleSubmit = async () => {
    const { values, isSubmitting } = this.state,
      { onSubmit } = this.props;
    this._mounted && this.setState({ isSubmitting: true });
    await onSubmit(values, {
      isSubmitting,
      setSubmitting: this.setSubmitting,
      setFieldValue: this.setFieldValue,
      reset: this.reset
    });
    this._mounted && this.setState({ isSubmitting: false });
  };
  setSubmitting = (isSubmitting = false) => {
    this._mounted && this.setState({ isSubmitting });
  };
  render() {
    const { children } = this.props,
      { values, isSubmitting } = this.state;

    return children({
      values,
      isSubmitting,
      setFieldValue: this.setFieldValue,
      handleSubmit: this.handleSubmit,
      reset: this.reset
    });
  }
}
Formilize.propTypes = {
  initialValues: PropTypes.object,
  onFieldUpdate: PropTypes.func,
  onSubmit: PropTypes.func
};
Formilize.defaultProps = {
  initialValues: {},
  onFieldUpdate: _ => _,
  onSubmit: _ => _
};
