import React from 'react';
import {
  FormGroup,
  Label,
  FileInputS3_2,
  Small
} from '../../rnbc/components/form';
import styles from '../../rnbc/styles';

export default ({ label, downloadUrl, description }) => (
  <FormGroup>
    {!!label && (
      <Label style={[styles.marginY(5, !!description ? 0 : 5)]}>{label}</Label>
    )}
    {!!description && (
      <Small style={[styles.marginBottom(5)]}>{description}</Small>
    )}
    <FileInputS3_2
      style={[{ maxWidth: 500 }]}
      value={{ url: downloadUrl || '' }}
      disabled
    />
  </FormGroup>
);
