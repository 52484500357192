import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RNImage, Image } from '../../plugins';

export default class ActualSizeImage extends Component {
  state = {
    width: 0,
    height: 0,
    ratio: 16 / 9
  };

  componentWillReceiveProps(nextProps) {
    this.resize(nextProps);
  }
  componentDidMount() {
    this.resize();
  }

  resize = props => {
    const { source } = props || this.props;
    try {
      if (source && source.uri) {
        RNImage.getSize(source.uri, (width, height) => {
          this.setState({ width, height, ratio: width / height });
        });
      } else if (source) {
        const { width, height } = RNImage.resolveAssetSource(source);
        this.setState({ width, height, ratio: width / height });
      }
    } catch (e) {}
  };

  render() {
    const {
        style,
        width: fixedWidth,
        height: fixedHeight,
        ...props
      } = this.props,
      { width, height, ratio } = this.state;
    return (
      <Image
        style={[
          {
            width: fixedWidth || (!!fixedHeight ? fixedHeight * ratio : width),
            height: fixedHeight || (!!fixedWidth ? fixedWidth / ratio : height)
          }
        ].concat(style)}
        {...props}
      />
    );
  }
}
ActualSizeImage.propTypes = {
  ...Image.propTypes,
  style: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number
};
