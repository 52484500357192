import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ReactApollo } from '../../../plugins';
import Icon from '../../../rnbc/icon';
import Button from '../../../rnbc/components/button';

export default class NextPrevRevButton extends Component {
  static ListQueryFetcherCache = {
    query: undefined,
    variables: {},
    parseConnection: _ => _
  };
  static resetFetcherCache = () => {
    NextPrevRevButton.ListQueryFetcherCache = {
      query: undefined,
      variables: {},
      parseConnection: _ => _
    };
  };
  render() {
    const { projectId, formId, revisionId, history, title } = this.props;
    const {
        query,
        variables,
        parseConnection
      } = NextPrevRevButton.ListQueryFetcherCache,
      { cursor } = variables || {};
    if (!query) return null;
    return (
      <ReactApollo.Query query={query} variables={variables}>
        {({ loading, data }) => {
          const { nodes = [] } = parseConnection(data || {});
          const currentIndex =
            nodes.findIndex(node => node.id === revisionId) || 0;
          const prevRev = nodes[currentIndex - 1],
            nextRev = nodes[currentIndex + 1];

          return (
            <Fragment>
              <Button
                transparent
                color={!!prevRev && !loading ? 'light' : 'dark'}
                onPress={() => {
                  const currentPointer = (cursor - 0 || 0) + currentIndex,
                    targetPointer =
                      currentPointer - 2 < 0
                        ? undefined
                        : `${currentPointer - 2}`;
                  const { id } = prevRev;
                  NextPrevRevButton.ListQueryFetcherCache = {
                    query,
                    variables: {
                      ...variables,
                      cursor: targetPointer
                    },
                    parseConnection
                  };
                  history.replace({
                    pathname: `/Revision/${projectId}/${formId}/${id}`,
                    state: {
                      title
                    }
                  });
                }}
                disabled={!prevRev || loading}
              >
                <Icon name={'chevron-left'} />
              </Button>
              <Button
                transparent
                color={!!nextRev && !loading ? 'light' : 'dark'}
                onPress={() => {
                  const currentPointer = (cursor - 0 || 0) + currentIndex,
                    targetPointer =
                      currentPointer < 0 ? undefined : `${currentPointer}`;
                  const { id } = nextRev;
                  NextPrevRevButton.ListQueryFetcherCache = {
                    query,
                    variables: {
                      ...variables,
                      cursor: targetPointer
                    },
                    parseConnection
                  };
                  history.replace({
                    pathname: `/Revision/${projectId}/${formId}/${id}`,
                    state: {
                      title
                    }
                  });
                }}
                disabled={!nextRev || loading}
              >
                <Icon name={'chevron-right'} />
              </Button>
            </Fragment>
          );
        }}
      </ReactApollo.Query>
    );
  }
}
NextPrevRevButton.propTypes = {
  projectId: PropTypes.string,
  formId: PropTypes.string,
  revisionId: PropTypes.string,
  title: PropTypes.string,
  history: PropTypes.any
};
NextPrevRevButton.defaultProps = {};
