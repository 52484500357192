import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  ScrollView,
  Animated,
  TouchableOpacity,
  OS,
  Link as RouteLink
} from '../../plugins';
import List, { ListItem } from './list';
import styles from '../styles';

let inst;
export default class Drawer extends Component {
  state = {
    toggle: false,
    ani: new Animated.Value(0)
  };
  componentDidMount() {
    inst = this;
  }
  componentWillUnmount() {
    inst = null;
  }

  static toggle = () => {
    if (!inst) return;
    inst.toggle();
  };
  static show = () => {
    if (!inst) return;
    inst.show();
  };
  static hide = () => {
    if (!inst) return;
    inst.hide();
  };
  toggle = () => {
    const { toggle } = this.state;
    if (toggle) this.hide();
    else this.show();
  };
  show = () => {
    const { ani } = this.state;
    this.setState({ toggle: true });
    Animated.timing(ani, {
      duration: 250,
      toValue: 1
    }).start();
  };
  hide = () => {
    const { ani } = this.state;
    Animated.timing(ani, {
      duration: 250,
      toValue: 0,
      onComplete: () => {
        this.setState({ toggle: false });
      }
    }).start();
  };
  onLinkPress = () => {
    this.toggle();
  };
  render() {
    const { toggle } = this.state;
    const { style, children, width } = this.props,
      { ani } = this.state;
    const aniLeft = ani.interpolate({
      inputRange: [0, 1],
      outputRange: [-width, 0]
    });

    if (!toggle) return null;

    const items = [];
    React.Children.forEach(children, (child, i) => {
      items.push(
        React.cloneElement(child, {
          key: i,
          onPress: this.onLinkPress,
          onClick: this.onLinkPress
        })
      );
    });

    return (
      <Fragment>
        <Animated.View
          style={[
            styles.absolute({
              left: 0,
              top: styles.header.height,
              right: 0,
              bottom: 0
            }),
            { opacity: ani }
          ]}
        >
          <TouchableOpacity
            onPress={this.hide}
            activeOpacity={0.5}
            style={[
              styles.fill,
              styles.backgroundColor('black'),
              styles.margin(0),
              { opacity: 0.5 }
            ]}
          />
        </Animated.View>
        <Animated.View
          style={[
            styles.backgroundColor('white'),
            styles.shadow,
            styles.content,
            {
              width,
              position: 'absolute',
              zIndex: 999,
              top: styles.header.height,
              left: aniLeft
            }
          ].concat(style)}
        >
          <ScrollView style={[styles.fill]}>
            <List>{items}</List>
          </ScrollView>
        </Animated.View>
      </Fragment>
    );
  }
}
export const Link = ({ border = true, title, to, ...props }) => {
  if (OS === 'web')
    return (
      <RouteLink
        to={to}
        style={{
          ...styles.padding(10),
          ...styles.border(0, 0, !!border ? 1 : 0)
        }}
      >
        {title}
      </RouteLink>
    );
  return (
    <RouteLink
      border={border}
      to={to}
      title={title}
      component={ListItem}
      {...props}
    />
  );
};
Drawer.propTypes = {
  style: PropTypes.any,
  width: PropTypes.number
};
Drawer.defaultProps = {
  width: Math.max(styles.vw(0.5).width, 250)
};
