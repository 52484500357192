import React from 'react';
import { FormGroup, Label, Small } from '../../rnbc/components/form';
import { Row } from '../../rnbc/components/layout';
import ButtonGroup from '../../rnbc/components/buttonGroup';
import Button from '../../rnbc/components/button';
import Text from '../../rnbc/components/text';
import Icon from '../../rnbc/icon';
import styles from '../../rnbc/styles';
import FieldLoading from './fieldLoading';
import FieldUserGroupPositionsRemark from './FieldUserGroupPositionsRemark';

export default ({
  label,
  disabled,
  value,
  onChange = _ => _,
  required,
  values = [],
  _sync,
  description,
  userGroup,
  userPositions
}) => (
  <FormGroup>
    {!!_sync && (
      <FieldLoading style={[styles.absolute({ top: -5, right: 0 })]} />
    )}
    {!!label && (
      <Row fy={'center'}>
        <Label style={[styles.marginY(5, !!description ? 0 : 5)]}>
          {label} <Text color={'danger'}>{!!required && '**'}</Text>
        </Label>
        <FieldUserGroupPositionsRemark
          userGroup={userGroup}
          userPositions={userPositions}
        />
      </Row>
    )}
    {!!description && (
      <Small style={[styles.marginBottom(5)]}>{description}</Small>
    )}
    <Row>
      <ButtonGroup value={value} disabled={disabled}>
        {values.map((opt, i) => (
          <Button
            key={i}
            outline={value !== opt}
            onPress={() => {
              onChange(opt);
            }}
          >
            <Text>{opt}</Text>
          </Button>
        ))}
      </ButtonGroup>
      {!!value && !disabled && (
        <Button
          transparent
          onPress={() => {
            onChange(undefined);
          }}
        >
          <Icon name={'times'} color={'danger'} />
        </Button>
      )}
    </Row>
  </FormGroup>
);
