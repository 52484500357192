import React from 'react';
import { TouchableOpacity } from '../../../plugins';
import Label from './label';
import Icon from '../../icon';
import styles from '../../styles';

export default ({
  color = 'primary',
  disabled,
  label,
  style,
  checked,
  onPress
}) => {
  return (
    <TouchableOpacity
      activeOpacity={0.5}
      style={[
        styles.row,
        styles.paddingRight(10),
        { alignItems: 'center' },
        !!disabled && { opacity: 0.5 }
      ].concat(style)}
      onPress={!disabled && onPress}
    >
      <Icon
        size={20}
        name={!!checked ? 'check-square' : 'square'}
        color={!!checked ? color : 'dark'}
      />
      <Label style={[styles.marginLeft(5)]}>{label}</Label>
    </TouchableOpacity>
  );
};
