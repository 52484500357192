import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Flex } from '../../layout';
import Input from '../input';
import Button from '../../button';
import Icon from '../../../icon';
import styles from '../../../styles';

export default class DoneBar extends Component {
  async componentDidMount() {
    this._mounted = true;
    try {
      await new Promise(resolve => setTimeout(resolve, 100));
      if (this._mounted) this.input && this.input.focus();
    } catch (e) {}
  }
  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const { style, inputProps, leftWidget, widget, onDonePress } = this.props;
    return (
      <Row
        style={[
          styles.backgroundColor('white'),
          styles.block,
          !inputProps || !inputProps.multiline
            ? styles.header
            : { minHeight: styles.header.height },
          styles.shadow
        ].concat(style)}
      >
        {leftWidget ? (
          <Row fy={'center'} style={[styles.header, styles.marginX(5)]}>
            {leftWidget}
          </Row>
        ) : inputProps ? null : (
          <Flex />
        )}
        {!!inputProps ? (
          <Input
            inputRef={ref => (this.input = ref)}
            {...inputProps}
            autoFocus={true}
            style={[
              styles.flex(1),
              styles.padding(5, 10),
              styles.rounded(0)
            ].concat(inputProps.style)}
            border={false}
            rounded={false}
          />
        ) : (
          <Flex />
        )}
        <Row fy={'center'} style={[styles.header, styles.marginX(5)]}>
          {widget}
          {!!onDonePress && (
            <Button transparent onPress={onDonePress}>
              <Icon name={'check'} />
            </Button>
          )}
        </Row>
      </Row>
    );
  }
}
DoneBar.propTypes = {
  style: PropTypes.any,
  inputProps: PropTypes.any,
  leftWidget: PropTypes.any,
  widget: PropTypes.any,
  onDonePress: PropTypes.func
};
DoneBar.defaultProps = {};
