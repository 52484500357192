import { ApolloBoost, Config, OS } from '../../plugins';
import { client } from './apollo';
import uuid from 'uuidv4';
const { gql } = ApolloBoost;

const s3Options = {
  bucket: Config.REACT_APP_S3_BUCKET,
  region: Config.REACT_APP_S3_REGION
};

const UPLOAD_URL = gql`
  query($key: String!, $mime: String) {
    uploadUrl(input: { key: $key, mime: $mime })
  }
`;

export async function requestUploadUrl(key, mime) {
  const {
    data: { uploadUrl }
  } = await client.query({
    query: UPLOAD_URL,
    variables: {
      key,
      mime
    }
  });
  return uploadUrl;
}

export function putFileReadableStream(url, file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve('Image successfully uploaded to S3');
        } else {
          reject('Error while sending the image to S3');
        }
      }
    };
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
}

export function putFileBase64(url, file) {
  return fetch(url, {
    method: 'PUT',
    body: Buffer.from(file.base64.replace(/^data:.*?;base64,/, ''), 'base64'),
    headers: {
      'Content-Type': file.type
    }
  });
}

export async function putFileBlob(url, file) {
  await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.onreadystatechange = function() {
      console.log(xhr);
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve('Image successfully uploaded to S3');
        } else {
          reject('Error while sending the image to S3');
        }
      }
    };
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file.blob);
  });
  return;
  const res = await fetch(url, {
    method: 'PUT',
    body: file.blob,
    headers: {
      'Content-Type': file.type
    }
  });
  console.log(res);
  return res;
}

export default async (file, type = 'readableStream') => {
  console.log('file input', file);
  try {
    const extension = (file.name || file.uri || '')
      .replace(/^.*\.(.*?)$/, '$1')
      .toLowerCase();
    const rename = `${uuid()}.${extension}`;
    const uploadUrl = await requestUploadUrl(rename, file.type);

    switch (type) {
      case 'readableStream':
        await putFileReadableStream(uploadUrl, file);
        break;
      case 'base64':
        await putFileBase64(uploadUrl, file);
    }
    return {
      key: `tmp/${rename}`,
      region: s3Options.region,
      bucket: s3Options.bucket
    };
  } catch (e) {
    console.log(e);
    throw new Error('Upload Fail');
  }
};
