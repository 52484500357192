import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AsyncStorage, FlatList } from '../../../../plugins';
import styles from '../../../styles';
import { ROW_HEIGHT, TableSkeleton } from '../index';
import TH from './TH';
import PadderCell from './PadderCell';
import DataCell from './DataCell';
import FixCell from './FixCell';
import ConfigModal from '../ConfigModal';
import JSONParser from '../../../../wrapper/shared/JSONParser';
import CellMeasurerWrapper from './CellMeasurerWrapper';

export default class Table extends Component {
  state = {
    key_table: 0,
    selectedIndex: -1,
    config: {},
    cellMeasureCache: new FlatList.CellMeasurerCache({
      defaultWidth: 100,
      minWidth: 75,
      maxWidth: 300,
      fixedHeight: true
    })
  };
  resetTableSizeCache = () => {
    const { cellMeasureCache } = this.state;
    cellMeasureCache.clearAll();
  };
  async componentDidMount() {
    const { configKey } = this.props;
    const selectedIndex =
      (await AsyncStorage.getItem('table:selectedIndex')) - 0 || 0;
    this.setState({
      selectedIndex
    });
    if (configKey) {
      const configJSON =
        (await AsyncStorage.getItem(`table:config:${configKey}`)) || '{}';
      this.setState({ config: JSONParser(configJSON) });
    }

    try {
      this.resetTableSizeCache();
      this.bodyGrid.scrollToCell({ rowIndex: selectedIndex });
    } catch (e) {}
  }
  async componentWillUnmount() {
    const { selectedIndex } = this.state;
    await AsyncStorage.setItem('table:selectedIndex', `${selectedIndex}`);
  }
  onHeaderPress = col => {
    const { sorting = {}, onSortChange } = this.props;
    if (!!sorting && sorting.field === col.field) {
      if (sorting.order === 'ASC')
        onSortChange({
          field: col.field,
          order: 'DESC'
        });
      else
        onSortChange({
          field: col.field,
          order: 'ASC'
        });
    } else {
      onSortChange({
        field: col.field,
        order: 'ASC'
      });
    }
  };
  render() {
    const { loading, data } = this.props,
      { key_table } = this.state;
    if (!!loading && data.length === 0) return <TableSkeleton />;
    return (
      <Fragment>
        <FlatList.ScrollSync key={key_table}>
          {({ onScroll, scrollLeft }) => (
            <FlatList.AutoSizer>
              {({ width, height }) =>
                this.renderTable({ width, height, onScroll, scrollLeft })
              }
            </FlatList.AutoSizer>
          )}
        </FlatList.ScrollSync>
        {this.renderConfigModal()}
      </Fragment>
    );
  }
  renderTable(args) {
    const { columns } = this.props,
      { config: { hiddenFields = [] } = {} } = this.state;

    const displayColumns = columns.filter(
      ({ field = '' } = {}) => !~hiddenFields.indexOf(field)
    );
    return (
      <Fragment>
        {this.renderHeader(args, displayColumns)}
        {this.renderBody(args, displayColumns)}
      </Fragment>
    );
  }
  renderConfigModal() {
    const { config, key_table } = this.state;
    const { columns, configKey, configButtonStyle } = this.props;
    if (!configKey) return null;
    return (
      <ConfigModal
        style={configButtonStyle}
        columns={columns}
        config={config}
        onChange={async config => {
          this.resetTableSizeCache();
          await new Promise(resolve => setTimeout(resolve, 250));
          this.setState({ config, key_table: key_table + 1 });
          if (!!configKey)
            await AsyncStorage.setItem(
              `table:config:${configKey}`,
              JSON.stringify(config)
            );
        }}
      />
    );
  }
  renderHeader({ width, scrollLeft, onScroll }, columns) {
    const { sorting, loading } = this.props,
      { cellMeasureCache } = this.state;

    return (
      <FlatList.Grid
        width={width}
        height={ROW_HEIGHT}
        rowHeight={ROW_HEIGHT}
        rowCount={1}
        scrollLeft={scrollLeft}
        columnCount={columns.length}
        columnWidth={cellMeasureCache.columnWidth}
        deferredMeasurementCache={cellMeasureCache}
        onScroll={onScroll}
        cellRenderer={({ columnIndex, key, parent, style }) => {
          const column = columns[columnIndex];
          return (
            <CellMeasurerWrapper
              cache={cellMeasureCache}
              columnIndex={columnIndex}
              key={key}
              parent={parent}
              rowIndex={0}
            >
              <TH
                onPress={() => this.onHeaderPress(column, columnIndex)}
                column={column}
                style={style}
                sorting={sorting}
                columnIndex={columnIndex}
                loading={loading}
              />
            </CellMeasurerWrapper>
          );
        }}
      />
    );
  }
  renderBody({ width, height, scrollLeft, onScroll }, columns) {
    const { data, padderCount, sorting, defaultSorting } = this.props,
      { cellMeasureCache } = this.state;
    const rows = [...data, { fix: true }];

    return (
      <FlatList.Grid
        ref={ref => (this.bodyGrid = ref)}
        onScroll={onScroll}
        scrollLeft={scrollLeft}
        rowCount={rows.length + padderCount}
        rowHeight={ROW_HEIGHT}
        columnCount={columns.length}
        columnWidth={cellMeasureCache.columnWidth}
        deferredMeasurementCache={cellMeasureCache}
        width={width}
        height={height - ROW_HEIGHT}
        overscanColumnCount={0}
        overscanRowCount={2}
        cellRenderer={res => {
          const { columnIndex, rowIndex } = res;
          const column = columns[columnIndex] || {};
          const row = rows[rowIndex];

          if (!row) return this.renderPadderCell(res);
          else if (!!row.fix) return this.renderFixCell(res);
          return this.renderDataCell({
            column,
            row,
            ...res
          });
        }}
      />
    );
  }
  renderPadderCell({ column = {}, columnIndex, key, parent, rowIndex, style }) {
    const { cellMeasureCache } = this.state;
    return (
      <CellMeasurerWrapper
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <PadderCell style={style} />
      </CellMeasurerWrapper>
    );
  }
  renderDataCell({
    row,
    column = {},
    columnIndex,
    rowIndex,
    style,
    key,
    parent
  }) {
    const { onRowDeepPress, onRowPress } = this.props,
      { selectedIndex, cellMeasureCache } = this.state;
    const selected = selectedIndex === rowIndex;
    return (
      <CellMeasurerWrapper
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <DataCell
          style={style}
          column={column}
          row={row}
          selected={selected}
          onPress={() => {
            if (selected) {
              onRowDeepPress(row, rowIndex);
            } else {
              this.setState({ selectedIndex: rowIndex });
              onRowPress(row, rowIndex);
            }
          }}
        />
      </CellMeasurerWrapper>
    );
  }
  renderFixCell({ column = {}, columnIndex, rowIndex, style, key, parent }) {
    const { cellMeasureCache } = this.state;
    return (
      <CellMeasurerWrapper
        cache={cellMeasureCache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <FixCell style={style} />
      </CellMeasurerWrapper>
    );
  }
}
Table.propTypes = {
  configKey: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  onRowPress: PropTypes.func,
  onRowDeepPress: PropTypes.func,
  onSortChange: PropTypes.func,
  sorting: PropTypes.any,
  padderCount: PropTypes.number,
  defaultSorting: PropTypes.any,
  loading: PropTypes.bool,
  configButtonStyle: PropTypes.any
};
Table.defaultProps = {
  columns: [],
  data: [],
  onRowPress: _ => _,
  onRowDeepPress: _ => _,
  onSortChange: _ => _,
  padderCount: 0,
  width: styles.vw(1).width,
  height: styles.vh(1).height
};
