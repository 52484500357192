import { ApolloBoost } from '../../../plugins';
const { gql } = ApolloBoost;

export const REGISTER = gql`
  mutation($credentials: UserCredentialsInput!, $input: UserCreateInput!) {
    userCreate(credentials: $credentials, input: $input) {
      id
    }
  }
`;
