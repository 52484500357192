import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../styles';
import { TouchableOpacity } from '../../../../plugins';
import { Col, Row } from '../../layout';
import Text from '../../text';
import Icon from '../../../icon';
import { ROW_HEIGHT } from '../index';
import Loading from '../../loading';

export default class Head extends PureComponent {
  render() {
    const {
      leftShiftPadding,
      columns,
      onHeaderPress,
      onColumnLayout,
      sorting,
      loading
    } = this.props;
    return (
      <Row
        style={[
          styles.backgroundColor('white'),
          styles.border(0, 0, 1),
          styles.paddingLeft(leftShiftPadding)
        ]}
      >
        {columns.map((col, i) => {
          const { label, _width, width, field } = col;
          return (
            <TouchableOpacity
              key={i}
              onPress={() => onHeaderPress(col, i)}
              activeOpacity={1}
              {...(width ? {} : { onLayout: e => onColumnLayout(e, col) })}
              style={[
                styles.row,
                styles.padding(0, 10),
                !!width && { width },
                !!_width && { minWidth: _width },
                { height: ROW_HEIGHT, alignItems: 'center' }
              ]}
            >
              {!!loading && i === 0 ? (
                <Loading style={[styles.padding(0)]} />
              ) : typeof label === 'string' ? (
                <Text numberOfLines={1} style={[styles.bold]}>
                  {label}
                </Text>
              ) : (
                label
              )}
              <Col flex={1} fx={'flex-end'} style={[styles.marginLeft(10)]}>
                <Icon
                  size={24}
                  color={
                    !!sorting &&
                    sorting.field === field &&
                    sorting.order === 'ASC'
                      ? 'dark'
                      : '#dddddd'
                  }
                  style={[styles.marginBottom(-5)]}
                  name={'angle-up'}
                />
                <Icon
                  size={24}
                  color={
                    !!sorting &&
                    sorting.field === field &&
                    sorting.order === 'DESC'
                      ? 'dark'
                      : '#dddddd'
                  }
                  style={[styles.marginTop(-5)]}
                  name={'angle-down'}
                />
              </Col>
            </TouchableOpacity>
          );
        })}
      </Row>
    );
  }
}
Head.propTypes = {
  leftShiftPadding: PropTypes.number,
  columns: PropTypes.array,
  onHeaderPress: PropTypes.func,
  onColumnLayout: PropTypes.func,
  sorting: PropTypes.any,
  loading: PropTypes.bool
};
Head.defaultProps = {
  leftShiftPadding: 0,
  columns: [],
  onHeaderPress: _ => _,
  onColumnLayout: _ => _
};
