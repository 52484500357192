import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from './button';
import { Animated, PanResponder, View, TouchableOpacity } from '../../plugins';
import { Col, Flex } from './layout';
import Icon from '../icon';
import styles from '../styles';

export default class ZoomableView extends Component {
  state = {
    scale: new Animated.Value(1),
    translateX: new Animated.Value(0),
    translateY: new Animated.Value(0),
    points: []
  };
  lastMovePinch = false;
  scale = 1;
  translateX = 0;
  translateY = 0;
  distant = 0;
  i = 0;

  onScaleRequest = _scale => {
    const { scale } = this.state,
      { maxScale, minScale } = this.props;
    if (_scale > maxScale) _scale = maxScale;
    if (_scale < minScale) _scale = minScale;
    scale.setValue(_scale);
    this.setState({});
  };

  onStartShouldSetPanResponder = () => {
    return true;
  };
  onStartShouldSetPanResponderCapture = (e, gestureState) => {
    return false;
  };

  onMoveShouldSetPanResponder = (e, { dx, dy, numberActiveTouches }) => {
    const isMoving = Math.abs(dx) > 2 || Math.abs(dy) > 2;
    const isScaling = numberActiveTouches === 2;
    return isMoving || isScaling;
  };

  onPanResponderGrant = (e, { numberActiveTouches }) => {
    const { scale, translateX, translateY } = this.state;
    Animated.timing(scale).stop();
    Animated.timing(translateX).stop();
    Animated.timing(translateY).stop();

    if (numberActiveTouches === 2) {
      const { dx, dy, mx, my } = this._getPoints(e);
      this._setParams({
        distant: Math.sqrt(dx * dx + dy * dy)
      });
    }
  };

  onPanResponderMove = (e, gestureState) => {
    const { numberActiveTouches } = gestureState;
    if (numberActiveTouches === 2) {
      this._handlePinch(e, gestureState);
    } else if (numberActiveTouches === 1) {
      this._handlePan(e, gestureState);
    }
  };

  _handlePinch = (e, {}) => {
    const { translateX, translateY, scale } = this.state,
      { maxScale, minScale } = this.props;
    const { x1, x2, y1, y2, dx, dy, mx, my } = this._getPoints(e);
    let distant = Math.sqrt(dx * dx + dy * dy);
    let _scale = (distant / this.distant) * this.scale;

    /* Handle_Scale */
    if (_scale > maxScale) _scale = maxScale;
    if (_scale < minScale) _scale = minScale;
    scale.setValue(_scale);

    return;
    const convertScaled = (x, y) => {
      const w = styles.vw(1).width,
        h = styles.vh(1).height;

      const rx = x / w;
      const ry = y / h;

      const scaledW = _scale * w;
      const scaledH = _scale * h;

      return {
        x: scaledW * rx - (scaledW - w) / 2,
        y: scaledH * ry - (scaledH - h) / 2
      };
    };

    const { x: sx, y: sy } = convertScaled(mx, my);
    const offsetX = mx - sx,
      offsetY = my - sy;

    let _x = this.translateX + offsetX;
    let _y = this.translateY + offsetY;

    translateX.setValue(_x);
    translateY.setValue(_y);

    this._setParams({ lastMovePinch: true });
  };

  _handlePan = (e, { dx, dy }) => {
    const { translateX, translateY } = this.state;
    if (this.lastMovePinch) {
      dx = 0;
      dy = 0;
    }
    let _x = this.translateX + dx / this.scale;
    let _y = this.translateY + dy / this.scale;

    translateX.setValue(_x);
    translateY.setValue(_y);
    this._setParams({ lastMovePinch: false });
  };

  onPanResponderRelease = (e, { vx, vy }) => {
    const { scale, translateX, translateY } = this.state;

    let _x = translateX._value,
      _y = translateY._value,
      _scale = scale._value;
    this._setParams({
      translateX: _x,
      translateY: _y,
      scale: _scale
    });
    if (this.lastMovePinch) this.setState({});
    else {
      Handle_Pan: {
        translateX.flattenOffset();
        translateY.flattenOffset();

        Animated.parallel([
          Animated.decay(translateX, {
            velocity: vx,
            deceleration: 0.99,
            onComplete: () => this._setParams({ translateX: translateX._value })
          }),
          Animated.decay(translateY, {
            velocity: vy,
            deceleration: 0.99,
            onComplete: () => this._setParams({ translateY: translateY._value })
          })
        ]).start();
      }
    }
  };

  _setParams = params => {
    Object.keys(params).forEach(key => {
      this[key] = params[key];
    });
  };

  _getPoints = e => {
    let x1 = e.nativeEvent.touches[0].pageX,
      y1 = e.nativeEvent.touches[0].pageY;

    let x2 = (e.nativeEvent.touches[1] || e.nativeEvent.touches[0]).pageX,
      y2 = (e.nativeEvent.touches[1] || e.nativeEvent.touches[0]).pageY;

    let dx = Math.abs(x1 - x2);
    let dy = Math.abs(y1 - y2);

    let mx = (x1 + x2) / 2;
    let my = (y1 + y2) / 2;

    return { x1, x2, y1, y2, dx, dy, mx, my };
  };

  render() {
    const { children, style, maxScale, minScale, controls } = this.props,
      { scale, translateX, translateY } = this.state;
    const stripeCount = 50;

    return (
      <View
        style={[
          styles.fill,
          styles.backgroundColor('dark-bg'),
          {
            overflow: 'hidden'
          }
        ].concat(style)}
        {...PanResponder.create({
          onStartShouldSetPanResponder: this.onStartShouldSetPanResponder,
          onStartShouldSetPanResponderCapture: this
            .onStartShouldSetPanResponderCapture,
          onMoveShouldSetPanResponder: this.onMoveShouldSetPanResponder,
          onPanResponderGrant: this.onPanResponderGrant,
          onPanResponderMove: this.onPanResponderMove,
          onPanResponderRelease: this.onPanResponderRelease,
          onPanResponderTerminate: this.onPanResponderRelease
        }).panHandlers}
      >
        <TouchableOpacity style={[styles.fill]} />
        <Animated.View
          style={[
            {
              position: 'absolute',
              transform: [{ scale }, { translateX }, { translateY }]
            }
          ]}
        >
          {children}
        </Animated.View>
        {!!controls && (
          <Col style={[styles.absolute({ right: 5, top: 5, bottom: 5 })]}>
            <Button
              circle
              style={{ width: 30, height: 30, zIndex: 1 }}
              onPress={() => this.onScaleRequest(scale._value * 1.2)}
            >
              <Icon name={'plus'} />
            </Button>
            <Col flex={1} style={[styles.marginY(-15), styles.paddingY(15)]}>
              {Array(stripeCount)
                .fill(null)
                .map((_, i) => {
                  const ratio = 1 - (i + 1) / stripeCount;
                  const _scale = minScale + (maxScale - minScale) * ratio;

                  return (
                    <TouchableOpacity
                      key={i}
                      style={[styles.flex(1), styles.block, styles.center]}
                      onPress={() => this.onScaleRequest(_scale)}
                    >
                      <Icon
                        color={scale._value >= _scale ? 'primary' : 'light'}
                        size={Math.max(
                          (20 * (stripeCount - i)) / stripeCount,
                          5
                        )}
                        name={'minus'}
                      />
                    </TouchableOpacity>
                  );
                })}
            </Col>
            <Button
              circle
              style={{ width: 30, height: 30 }}
              onPress={() => this.onScaleRequest(scale._value * 0.85)}
            >
              <Icon name={'minus'} />
            </Button>
          </Col>
        )}
      </View>
    );
  }
}
ZoomableView.propTypes = {
  controls: PropTypes.bool,
  minScale: PropTypes.number,
  maxScale: PropTypes.number
};
ZoomableView.defaultProps = {
  controls: true,
  minScale: 0.5,
  maxScale: 2
};
