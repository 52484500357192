import React from 'react';
import styles from '../styles';
import { View } from '../../plugins';

export default ({ color = 'light', rounded = 50, opacity = 1 }) => (
  <View
    style={[
      styles.fill,
      styles.rounded(rounded),
      styles.backgroundColor(color),
      { opacity }
    ]}
  />
);
