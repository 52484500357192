import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles';
import Icon from '../../icon';
import {
  Image,
  PDFView,
  TouchableOpacity,
  Modal,
  IFrame,
  View,
  FilePicker,
  FileDrop,
  ScrollView
} from '../../../plugins';
import Alert from '../alert';
import Button from '../button';
import Text from '../text';
import H3 from '../h3';
import DoneBar from './modalized/doneBar';
import { Row, Col } from '../layout';
import ModalizedInput from './modalized/input';
import { errorParser } from '../../../wrapper/shared/errorParser';
import MimeTypeMap from './mimeTypeMap';
import { TopPadder } from '../iphonePadder';
import FullWrapper from '../fullWrapper';
import moment from 'moment';
import bytes from 'bytes';
const ImgGradient = require('../../image/gradient.png');

export default class FileInputS3_2_web extends Component {
  state = {
    show: false,
    dragging: false,
    showDescriptionInputModal: false
  };
  onFileDrop = files => {
    try {
      this.onFileSelect(files);
    } catch (e) {
      Alert.danger(errorParser(e));
    }
  };
  parseFile = file => {
    let url = this.getUrl(file);
    if (!file || !url)
      return {
        isValid: false
      };

    let name = file.name || url.split('/').pop();
    let extension = this.parseExtension(file.name || url);
    let mimeType = file.type || this.parseMimeType(extension);
    let isFilePath = /^content/.test(url);

    return {
      isValid: true,
      url,
      name,
      extension,
      mimeType,
      isFilePath
    };
  };
  getUrl = file => {
    try {
      const { key, region, bucket, url, uri } = file || {};
      if (!!url) return url || '';
      else if (!!uri) return uri || '';
      else if (!!bucket && !!region && !!key)
        return `https://${bucket}.s3-${region}.amazonaws.com/${key}`;
    } catch (e) {}
    return '';
  };
  parseMimeType = extension => {
    return MimeTypeMap[extension] || `application/${extension}`;
  };
  parseExtension = input => {
    return input
      .split('.')
      .pop()
      .toLowerCase();
  };
  onRequestClose = () => {
    this.setState({ show: false });
  };
  onDownloadPress = () => {
    const { value } = this.props;
    const { url } = this.parseFile(value);
    try {
      window.open(url);
    } catch (e) {}
  };
  onRemovePress = async () => {
    const { onChange } = this.props;
    this.setState({ show: false });
    onChange(undefined);
  };
  onDisplayerPress = () => {
    this.setState({ show: true });
  };
  onFileSelect = (value = []) => {
    const { maxSize } = this.props;

    for (let { size, name } of value) {
      if (size > maxSize) {
        Alert.danger(
          `File ${name} is ${bytes(size)}, only ${bytes(maxSize)} is allowed.`
        );
        return;
      }
    }

    console.log('onFileSelect', value);
    const { onFileSelect, disabled } = this.props;
    if (!disabled) onFileSelect([...value]);
    else Alert.danger('Input disabled');
  };
  onFileSelectError = error => {
    Alert.danger(errorParser(error));
  };
  render() {
    const { style, value, disabled, imageOnly, maxSize, multiple } = this.props;
    const { isValid } = this.parseFile(value);
    const containerStyle = [
      styles.center,
      styles.border(1),
      styles.backgroundColor(!disabled ? 'white' : 'bg'),
      styles.rounded(5),
      {
        position: 'relative',
        height: 300
        // height: (styles.vw(1).width - 20 - 20) / 2
      }
    ].concat(style);

    return (
      <Fragment>
        {!!isValid ? (
          <TouchableOpacity
            activeOpacity={!disabled ? 0.5 : 1}
            onPress={this.onDisplayerPress}
            style={containerStyle}
          >
            {this.renderFileDisplayer()}

            {this.renderDescription()}
          </TouchableOpacity>
        ) : !!disabled ? (
          <View activeOpacity={1} style={containerStyle}>
            <H3 color={'border'}>No File</H3>
          </View>
        ) : (
          <FileDrop onDrop={this.onFileDrop}>
            <FilePicker
              maxSize={maxSize}
              multiple={multiple}
              onChange={this.onFileSelect}
              onError={this.onFileSelectError}
            >
              <TouchableOpacity activeOpacity={0.5} style={containerStyle}>
                <Icon
                  name={!!imageOnly ? 'images' : 'file-plus'}
                  size={48}
                  color={'dark-bg'}
                />
              </TouchableOpacity>
            </FilePicker>
          </FileDrop>
        )}

        {this.renderModal()}
      </Fragment>
    );
  }
  renderFileDisplayer() {
    const { value } = this.props;
    const { name, url, extension } = this.parseFile(value);
    console.log(url);

    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return (
          <Image
            resizeMode={'cover'}
            style={[styles.fill]}
            source={{ uri: url }}
          />
        );
      case 'pdf':
        return <PDFView scale={0.5} document={{ url: url }} />;
      default:
        return (
          <Row fy={'center'}>
            <Icon name={'file'} style={[styles.marginRight(10)]} />
            <Text>{name}</Text>
          </Row>
        );
    }
    return null;
  }
  renderDescription() {
    const { value, hasDescription, timeFormat } = this.props,
      { description, createdAt } = value || {};
    if ((!description || !hasDescription) && !createdAt) return null;
    return (
      <Col style={[styles.absolute({ bottom: 0, left: 0, right: 0 })]}>
        <Image
          resizeMode={'stretch'}
          source={ImgGradient}
          style={[styles.block, { height: 35 * 1.71, opacity: 0.5 }]}
        />
        <Col
          style={[
            styles.padding(0, 10, 10),
            styles.backgroundColor('rgba(0,0,0,0.5)')
          ]}
        >
          {!!hasDescription && !!description && (
            <Text color={'light'} numberOfLines={3}>
              {description}
            </Text>
          )}
          <Text color={'light'} style={[{ textAlign: 'right' }]}>
            {moment(createdAt).format(timeFormat)}
          </Text>
        </Col>
      </Col>
    );
  }
  renderModal() {
    const { show } = this.state;
    if (!show) return null;
    return (
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        visible={show}
        transparent={false}
        onRequestClose={this.onRequestClose}
      >
        <FullWrapper
          style={[
            styles.column,
            styles.backgroundColor('white'),
            { position: 'relative' }
          ]}
        >
          {this.renderDoneBar()}
          {this.renderPreviewZone()}
          {this.renderDescriptionInput()}
        </FullWrapper>
      </Modal>
    );
  }
  renderDoneBar() {
    const { disabled } = this.props;
    return (
      <Fragment>
        <TopPadder />
        <DoneBar
          leftWidget={
            <Button transparent onPress={this.onRequestClose}>
              <Icon name={'arrow-left'} />
            </Button>
          }
          widget={
            <Fragment>
              {!disabled && (
                <Button
                  transparent
                  color={'danger'}
                  onPress={this.onRemovePress}
                >
                  <Icon name={'trash-alt'} />
                </Button>
              )}
            </Fragment>
          }
        />
      </Fragment>
    );
  }
  renderPreviewZone() {
    const { value } = this.props;
    const { url, isFilePath, extension, name } = this.parseFile(value);
    switch (extension) {
      case 'jpeg':
      case 'jpg':
      case 'png':
        return (
          <View style={[styles.flex(1), styles.backgroundColor('border')]}>
            <IFrame
              srcDoc={`
<style>
  html, body, img {
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>
<img src="${url}" />
              `}
              style={{ width: '100%', height: '100%' }}
            />
          </View>
        );
      case 'pdf':
        return (
          <View style={[styles.flex(1), styles.backgroundColor('border')]}>
            <IFrame src={url} style={{ width: '100%', height: '100%' }} />
          </View>
        );
      default:
        return (
          <Col
            fx={'center'}
            fy={'center'}
            style={[styles.flex(1), styles.backgroundColor('white')]}
          >
            <Icon name={'file'} size={64} />
            <Text style={[styles.marginY(10)]}>{name}</Text>
            <Button transparent onPress={this.onDownloadPress}>
              <Text>Download File</Text>
            </Button>
          </Col>
        );
    }
  }

  renderDescriptionInput() {
    const { value, onChange, disabled, hasDescription } = this.props,
      { description } = value || {};
    if (!hasDescription) return null;
    return (
      <ScrollView
        style={[
          styles.border(1, 0, 0),
          {
            maxHeight:
              styles.p.fontSize *
              (Math.min((description || '').split('\n').length, 5) + 3)
          }
        ]}
      >
        <ModalizedInput
          disabled={disabled}
          multiline={true}
          style={[styles.border(0), styles.rounded(0)]}
          value={description}
          onChange={description => {
            onChange({ ...value, description });
          }}
          multiNumberOfLines={3}
          placeholder={'Description...'}
        />
      </ScrollView>
    );
  }
}
FileInputS3_2_web.propTypes = {
  value:
    PropTypes.any /* S3Object { key, bucket, region, description }  or  { url }*/,
  onChange: PropTypes.func,
  onFileSelect: PropTypes.func,
  style: PropTypes.any,
  disabled: PropTypes.bool,
  hasDescription: PropTypes.bool,
  imageOnly: PropTypes.bool,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  timeFormat: PropTypes.string
};
FileInputS3_2_web.defaultProps = {
  onChange: _ => _,
  onFileSelect: _ => _,
  imageOnly: false,
  hasDescription: true,
  maxSize: 104857600 /* 100 MB */,
  multiple: false,
  timeFormat: 'DD-MMM-YYYY HH:mm'
};
