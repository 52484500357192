import React from 'react';
import {
  FormGroup,
  Label,
  ModalizedSignature,
  Small
} from '../../rnbc/components/form';
import { OS } from '../../plugins';
import Text from '../../rnbc/components/text';
import { Row } from '../../rnbc/components/layout';
import moment from 'moment';
import * as Biometric from '../../rnbc/components/biometric';
import styles from '../../rnbc/styles';
import Button from '../../rnbc/components/button';
import { ReactApollo, ApolloBoost } from '../../plugins';
import Alert from '../../rnbc/components/alert';
import { errorParser } from '../shared/errorParser';
import { getLocation } from '../shared/s3';
import FieldLoading from './fieldLoading';
import FieldUserGroupPositionsRemark from './FieldUserGroupPositionsRemark';
const gql = ApolloBoost.gql;

const GET_QUERY = gql`
  query {
    me {
      id
      signature {
        key
        bucket
        region
      }
    }
  }
`;

export default ({
  label,
  signature,
  me: { name: myName, position: myPosition } = {},
  updatedBy,
  updatedAt,
  onChange = _ => _,
  required,
  disabled,
  description,
  _sync,
  userGroup,
  userPositions
}) => (
  <FormGroup>
    {!!_sync && (
      <FieldLoading style={[styles.absolute({ top: -5, right: 0 })]} />
    )}
    {!!label && (
      <Row fy={'center'}>
        <Label style={[styles.marginY(5, !!description ? 0 : 5)]}>
          {label} <Text color={'danger'}>{!!required && '**'}</Text>
        </Label>
        <FieldUserGroupPositionsRemark
          userPositions={userPositions}
          userGroup={userGroup}
        />
      </Row>
    )}
    {!!description && (
      <Small style={[styles.marginBottom(5)]}>{description}</Small>
    )}
    {!disabled && OS !== 'web' && (
      <ReactApollo.Query query={GET_QUERY}>
        {({ data: { me } = {} }) => {
          const { signature } = me || {};
          const eSignatureUrl = getLocation(signature);
          return (
            <Row>
              <Button
                onPress={async () => {
                  try {
                    if (!(await Biometric.simple()))
                      throw new Error('Biometric Authorization Failed');
                    onChange(eSignatureUrl);
                  } catch (e) {
                    Alert.danger(errorParser(e));
                  }
                }}
              >
                <Text>Use e-Signature</Text>
              </Button>
            </Row>
          );
        }}
      </ReactApollo.Query>
    )}
    <ModalizedSignature
      style={[{ maxWidth: 500 }]}
      disabled={disabled}
      issuer={!!signature ? (!!updatedBy ? updatedBy.name : myName) : ''}
      entitle={
        !!signature ? (!!updatedBy ? updatedBy.position : myPosition) : ''
      }
      issuedAt={!!updatedAt ? moment(updatedAt).format('YYYY-MM-DD HH:mm') : ''}
      value={signature}
      onChange={onChange}
    />
  </FormGroup>
);
