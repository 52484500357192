import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, TouchableOpacity, Animated, ScrollView } from '../../plugins';
import styles from '../styles';
import Icon from '../icon';
import { ToggleButton } from './dropdown';
import { Col, Row } from './layout';
import { TopPadder } from './iphonePadder';

export default class ToolTips extends Component {
  state = {
    toggle: false,
    ani: new Animated.Value(0)
  };
  toggle = () => {
    const { toggle, ani } = this.state;
    this.setState({ toggle: !toggle });
    if (!toggle) {
      ani.setValue(0);
      Animated.spring(ani, { toValue: 1 }).start();
    }
  };
  render() {
    const { clickable, style, mode, ...props } = this.props;
    return (
      <Fragment>
        <TouchableOpacity
          {...props}
          style={[styles.paddingX(10)].concat(style)}
          onPress={this.toggle}
        >
          {clickable}
        </TouchableOpacity>
        {
          {
            modal: this.renderModal(),
            drawer: this.renderDrawer()
          }[mode]
        }
      </Fragment>
    );
  }
  renderModal() {
    const { children } = this.props;
    const { toggle, ani } = this.state;

    const aniOpacity = ani.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 0.5]
      }),
      aniModalTopOffset = ani.interpolate({
        inputRange: [0, 1],
        outputRange: [50, 0]
      });

    return (
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        onRequestClose={this.toggle}
        fade={true}
        backdrop={false}
        transparent={true}
        modalTransition={{ appear: false }}
        visible={toggle}
      >
        {!!toggle && (
          <Row style={[styles.vw(1), styles.vh(1), { position: 'relative' }]}>
            <Animated.View
              style={[
                styles.backgroundColor('black'),
                styles.absolute({
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: -1
                }),
                { opacity: aniOpacity }
              ]}
            />
            <ToggleButton onPress={this.toggle} style={[styles.flex(1)]} />
            <Col>
              <ToggleButton onPress={this.toggle} style={[styles.flex(1)]} />
              <Animated.View
                style={[
                  styles.padding(10),
                  styles.shadow,
                  styles.backgroundColor('white'),
                  styles.rounded(5),
                  {
                    maxWidth: styles.vw(1).width,
                    maxHeight: styles.vh(0.8).height,
                    marginBottom: aniModalTopOffset
                  }
                ]}
              >
                <ScrollView>{children}</ScrollView>
              </Animated.View>
              <ToggleButton onPress={this.toggle} style={[styles.flex(1)]} />
            </Col>
            <ToggleButton onPress={this.toggle} style={[styles.flex(1)]} />
          </Row>
        )}
      </Modal>
    );
  }
  renderDrawer() {
    const { children } = this.props;
    const { toggle, ani } = this.state;

    const aniOpacity = ani.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 0.5]
      }),
      aniDrawerOffset = ani.interpolate({
        inputRange: [0, 1],
        outputRange: [-100, 0]
      });

    return (
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        onRequestClose={this.toggle}
        fade={true}
        backdrop={false}
        transparent={true}
        modalTransition={{ appear: false }}
        visible={toggle}
      >
        {!!toggle && (
          <Row style={[styles.vw(1), styles.vh(1)]}>
            <Animated.View
              style={[
                styles.backgroundColor('black'),
                styles.absolute({
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: -1
                }),
                { opacity: aniOpacity }
              ]}
            />
            <ToggleButton onPress={this.toggle} style={[styles.flex(1)]} />
            <Animated.View
              style={[
                styles.column,
                styles.shadow,
                styles.padding(10),
                styles.backgroundColor('white'),
                { marginRight: aniDrawerOffset }
              ]}
            >
              <ScrollView>
                <TopPadder />
                {children}
              </ScrollView>
            </Animated.View>
          </Row>
        )}
      </Modal>
    );
  }
}
ToolTips.propTypes = {
  mode: PropTypes.string,
  clickable: PropTypes.any,
  style: PropTypes.any
};
ToolTips.defaultProps = {
  mode: 'modal',
  clickable: <Icon name={'info-circle'} />,
  style: []
};
