import React from 'react';
import { Row } from '../../../rnbc/components/layout';
import Text from '../../../rnbc/components/text';
import Icon from '../../../rnbc/icon';
import styles from '../../../rnbc/styles';
import moment from 'moment';

export default field => {
  const { id, columnWidth, label, shortLabel, __typename } = field || {};
  if (!label && !shortLabel) return null;
  const displayLabel = shortLabel || label,
    commonSetting = { label: displayLabel, field: id, width: columnWidth };

  switch (__typename) {
    case 'ApprovalField':
      return {
        ...commonSetting,
        render: row => {
          const { finalized, withdrawnBy, _color, _textStyle } = row || {},
            isWithdrawn = !!finalized && !!withdrawnBy,
            value = isWithdrawn ? 'Withdraw' : row[id];
          return (
            <Row fy={'center'} style={[styles.fill]}>
              <Text
                numberOfLines={1}
                style={[
                  styles.flex(1),
                  styles.ellipsis,
                  { minWidth: 100 }
                ].concat(_textStyle)}
                color={_color}
              >
                {value}
              </Text>
            </Row>
          );
        }
      };
    case 'DateTimeField':
      return {
        ...commonSetting,
        sort: (a, b, order = 1) => {
          if (!a) return 1;
          const aa = moment(a, 'YYYY-MM-DD hh:mm A'),
            bb = moment(b, 'YYYY-MM-DD hh:mm A');
          if (!aa.isValid()) return 1;
          else if (!bb.isValid()) return -1;
          else if (aa.isAfter(bb)) return -1 * order;
          else return order;
        }
      };
    case 'SignatureField':
      return {
        ...commonSetting,
        render: (row, name) => {
          if (!name) return null;
          return (
            <Row fy={'center'} style={[styles.fill]}>
              <Icon
                name={'check'}
                color={row._color || 'dark'}
                style={[styles.marginRight(10)]}
              />
              <Text
                color={row._color}
                style={[styles.flex(1), styles.ellipsis].concat(row._textStyle)}
              >
                {name}
              </Text>
            </Row>
          );
        }
      };
    case 'WithdrawButton':
      return {
        ...commonSetting,
        render: (row, name) => {
          if (!name) return null;
          if (!!row.finalized)
            return (
              <Row fy={'center'} style={[styles.fill]}>
                <Icon
                  name={'check'}
                  color={row._color || 'dark'}
                  style={[styles.marginRight(10)]}
                />
                <Text
                  color={row._color}
                  style={[styles.flex(1), styles.ellipsis].concat(
                    row._textStyle
                  )}
                >
                  {name}
                </Text>
              </Row>
            );
          return (
            <Row fy={'center'} style={[styles.fill]}>
              <Icon
                name={'question'}
                color={'danger'}
                style={[styles.marginRight(10)]}
              />
              <Text
                color={row._color}
                style={[styles.flex(1), styles.ellipsis].concat(row._textStyle)}
              >
                {name}
              </Text>
            </Row>
          );
        }
      };
    default:
      return commonSetting;
  }
};
