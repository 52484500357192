import React from 'react';
import { Col, Row, Flex } from '../../rnbc/components/layout';
import { FormGroup, Label, Small } from '../../rnbc/components/form';
import Button from '../../rnbc/components/button';
import Text from '../../rnbc/components/text';
import styles from '../../rnbc/styles';
import Icon from '../../rnbc/icon';
import FieldLoading from './fieldLoading';
import FieldUserGroupPositionsRemark from './FieldUserGroupPositionsRemark';

export const variations = {
  2: [
    { value: 'APPROVED', label: 'Pass', color: 'success' },
    { value: 'REJECTED', label: 'Fail', color: 'danger' }
  ],
  3: [
    { value: 'APPROVED', label: 'Pass', color: 'success' },
    { value: 'REJECTED', label: 'Fail', color: 'danger' },
    { value: 'REINSPECTION', label: 'Pass with Comments', color: 'warning' }
  ],
  4: [
    { value: 'APPROVED', label: 'Approved', color: 'success' },
    { value: 'REJECTED', label: 'Rejected', color: 'danger' },
    { value: 'REINSPECTION', label: 'Re\nInspection', color: 'warning' },
    { value: 'NOTED', label: 'Noted/\nRecorded', color: 'info' }
  ]
};

export default ({
  label,
  status,
  numberOfButton,
  onChange,
  required,
  disabled,
  _sync,
  description,
  userGroup,
  userPositions
}) => {
  let _numberOfButton = numberOfButton || 4;
  return (
    <FormGroup>
      {!!_sync && (
        <FieldLoading style={[styles.absolute({ top: -5, right: 0 })]} />
      )}
      {!!label && (
        <Row fy={'center'}>
          <Label style={[styles.marginY(5, !!description ? 0 : 5)]}>
            {label} <Text color={'danger'}>{!!required && '**'}</Text>
          </Label>
          <FieldUserGroupPositionsRemark
            userGroup={userGroup}
            userPositions={userPositions}
          />
        </Row>
      )}
      {!!description && (
        <Small style={[styles.marginBottom(5)]}>{description}</Small>
      )}
      <Row style={[{ maxWidth: 500 }]}>
        {(variations[_numberOfButton] || []).map(
          ({ value, label, color }, i) => (
            <Col flex={1} fx={'center'} key={i}>
              <Button
                color={color}
                style={[styles.rounded(25), { width: 50, height: 50 }]}
                onPress={!disabled && (() => onChange(value))}
              >
                {value === status && (
                  <Icon name={'check'} size={styles.h2.fontSize} />
                )}
              </Button>
              <Text style={[{ textAlign: 'center' }]}>{label}</Text>
            </Col>
          )
        )}
        <Flex flex={Math.max(4 - _numberOfButton, 0)} />
      </Row>
    </FormGroup>
  );
};
