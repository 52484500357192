import {
  ApolloCacheInMemory,
  AWSAppSyncClient,
  AWSAppSync,
  ApolloLink,
  ApolloLinkContext,
  ApolloLinkHttp,
  Config,
  AsyncStorage,
  StorageEngine
} from '../../plugins';
import fragmentMatcher from './fragmentMatcher';
import Main from '../router/Main';
import jwtDecode from 'jwt-decode';
import Alert from '../../rnbc/components/alert';

const appSyncOpts = {
  url: Config.REACT_APP_API_URL,
  region: Config.REACT_APP_API_REGION,
  auth: {
    type: 'NONE',
    apiKey: 'rfdsfdsfd'
  }
};

export const clear_ROOT_MUTATION = async () => {
  try {
    await client.cache.data.delete('ROOT_MUTATION');
  } catch (e) {}
};
export const client = new AWSAppSyncClient(
  {
    ...appSyncOpts,
    cacheOptions: {
      fragmentMatcher
    },
    offlineConfig: {
      storage: StorageEngine
    }
  },
  {
    link: AWSAppSync.createAppSyncLink({
      ...appSyncOpts,
      resultsFetcherLink: ApolloLink.ApolloLink.from([
        ApolloLinkContext.setContext(async (_, { headers, ...args }) => {
          let token = await AsyncStorage.getItem('token');

          if (!!token && !checkTokenAvailable(token)) {
            await AsyncStorage.removeItem('token');
            const history = Main.getHistory();
            history.entries = [];
            history.index = -1;
            history.push('/Login');
            Alert.danger('login session is invalid');
            throw new Error('Invalid Token');
          }
          if (!!isCheckVersion(_)) token = undefined;

          if (!!token)
            return {
              headers: {
                ...headers,
                authorization: `${token}`
              }
            };
          return { headers };
        }),
        ApolloLinkHttp.createHttpLink({
          uri: operation => {
            return appSyncOpts.url;
          }
        })
      ])
    }),
    cache: new ApolloCacheInMemory.InMemoryCache({ fragmentMatcher })
  }
);

export function checkTokenAvailable(token) {
  try {
    const { exp } = jwtDecode(token);
    return exp * 1000 - new Date() > 0;
  } catch (e) {
    return false;
  }
}

function isCheckVersion(content) {
  try {
    const query = content.query.loc.source.body;
    return !!query.match(/version \{/);
  } catch (e) {
    return false;
  }
}
