import React from 'react';
import { Row } from '../layout';
import { View } from '../../../plugins';
import styles from '../../styles';

export const InputGroupAppend = ({
  transparent = true,
  color = 'dark',
  style,
  children,
  ...props
}) => {
  const items = [];
  React.Children.forEach(children, (child, i) => {
    !!child &&
      items.push(
        React.cloneElement(
          child,
          !!child
            ? {
                key: i,
                color: child.props.color || color
              }
            : {}
        )
      );
  });

  return (
    <View
      style={[
        styles.row,
        styles.backgroundColor(`${color}-bg`),
        styles.padding(6, 12),
        styles.border(1),
        styles.margin(0),
        { alignItems: 'center' }
      ].concat(style)}
      {...props}
    >
      {items}
    </View>
  );
};

export default ({ children, ...props }) => {
  let items = children
    .reduce((reducer, child) => {
      return reducer.concat(child);
    }, [])
    .filter(_ => _);

  items = items.map((child, i) => {
    const isFirst = i === 0,
      isLast = i === items.length - 1;
    return React.cloneElement(child, {
      key: i,
      ...child.props,
      style: [
        {
          borderTopLeftRadius: isFirst ? 5 : 0,
          borderBottomLeftRadius: isFirst ? 5 : 0,
          borderTopRightRadius: isLast ? 5 : 0,
          borderBottomRightRadius: isLast ? 5 : 0,
          borderLeftWidth: isFirst ? 1 : 0,
          margin: 0
        }
      ].concat(child.props.style)
    });
  });

  return <Row {...props}>{items}</Row>;
};
