import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ModalizedInputDate from './inputDate';
import styles from '../../../styles';
import WheelSelect from '../../wheelSelect';
import H5 from '../../h5';
import moment from 'moment';

export default class ModalizedInputTime extends ModalizedInputDate {
  state = {
    ...this.state,
    cacheHour: undefined,
    cacheMinute: undefined,
    cacheSecond: undefined,
    cacheAM: undefined,
    dataHours: [
      {
        label: 1,
        value: 1
      },
      {
        label: 2,
        value: 2
      },
      {
        label: 3,
        value: 3
      },
      {
        label: 4,
        value: 4
      },
      {
        label: 5,
        value: 5
      },
      {
        label: 6,
        value: 6
      },
      {
        label: 7,
        value: 7
      },
      {
        label: 8,
        value: 8
      },
      {
        label: 9,
        value: 9
      },
      {
        label: 10,
        value: 10
      },
      {
        label: 11,
        value: 11
      },
      {
        label: 12,
        value: 0
      }
    ],
    dataMinutes: Array(60 / this.props.minuteInterval)
      .fill(null)
      .map((_, i) => ({
        label: i * this.props.minuteInterval,
        value: i * this.props.minuteInterval
      })),
    dataSeconds: Array(60)
      .fill(null)
      .map((_, i) => ({
        label: i,
        value: i
      })),
    dataAM: [{ label: 'AM', value: 'AM' }, { label: 'PM', value: 'PM' }]
  };
  onDisplayerPress = () => {
    const { value, minuteInterval } = this.props;
    const time = moment(value),
      remainder = time.minute() % minuteInterval;
    time
      .subtract(remainder, 'minutes')
      .add(remainder > minuteInterval / 2 ? minuteInterval : 0, 'minutes');

    this.setState({
      show: true,
      cacheHour: time.hour() % 12,
      cacheMinute: time.minute(),
      cacheSecond: time.second(),
      cacheAM: time.hour() < 12 ? 'AM' : 'PM'
    });
  };
  onHourChange = hour => {
    this.setState({ cacheHour: hour });
  };
  onMinuteChange = minute => {
    this.setState({ cacheMinute: minute });
  };
  onSecondChange = second => {
    this.setState({ cacheSecond: second });
  };
  onAMChange = am => {
    this.setState({ cacheAM: am });
  };
  onConfirmPress = () => {
    const { onChange, value } = this.props,
      { cacheHour, cacheMinute, cacheSecond, cacheAM } = this.state;
    let hour = cacheHour;
    if (cacheAM === 'PM' && hour < 12) hour += 12;
    if (cacheAM === 'AM' && hour >= 12) hour %= 12;

    onChange(
      moment(value)
        .hour(hour)
        .minute(cacheMinute)
        .second(cacheSecond)
        .toISOString()
    );
    this.onRequestClose();
  };
  componentDidMount() {}
  renderWheels() {
    const {
        dataHours,
        dataMinutes,
        dataSeconds,
        dataAM,
        cacheHour,
        cacheMinute,
        cacheSecond,
        cacheAM
      } = this.state,
      { showSecond } = this.props;

    return (
      <Fragment>
        <WheelSelect
          title={'Hour'}
          value={cacheHour}
          items={dataHours}
          onChange={this.onHourChange}
          height={styles.vh(0.5).height}
          style={[styles.flex(1)]}
        />
        <H5 style={[styles.marginX(-5)]}>:</H5>
        <WheelSelect
          value={cacheMinute}
          items={dataMinutes}
          onChange={this.onMinuteChange}
          height={styles.vh(0.5).height}
          style={[styles.flex(1)]}
        />
        {!!showSecond && (
          <Fragment>
            <H5 style={[styles.marginX(-5)]}>:</H5>
            <WheelSelect
              value={cacheSecond}
              items={dataSeconds}
              onChange={this.onSecondChange}
              height={styles.vh(0.5).height}
              style={[styles.flex(1)]}
            />
          </Fragment>
        )}
        <WheelSelect
          value={cacheAM}
          items={dataAM}
          onChange={this.onAMChange}
          height={styles.vh(0.5).height}
          style={[styles.flex(1)]}
        />
      </Fragment>
    );
  }
}
ModalizedInputTime.propTypes = {
  ...ModalizedInputDate.propTypes,
  showSecond: PropTypes.bool,
  minuteInterval: PropTypes.number
};
ModalizedInputTime.defaultProps = {
  onChange: _ => _,
  displayFormat: 'hh:mm A',
  minuteInterval: 1
};
