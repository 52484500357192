import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, TouchableOpacity, View } from '../../../../plugins';
import List, { ListItem } from '../../list';
import ModalizedInputSelect from './inputSelect';
import DoneBar from './doneBar';
import styles from '../../../styles';
import Button from '../../button';
import { TopPadder } from '../../iphonePadder';
import Text from '../../text';
import Icon from '../../../icon';

export default class ModalizedSelect extends ModalizedInputSelect {
  state = {
    ...this.state,
    search: ''
  };

  onDisplayerPress = () => {
    this.setState({ search: '', show: true });
  };

  onSearchChangeText = search => {
    this.setState({ search });
  };

  renderDisplayer() {
    const { value = [], style, disabled, renderValue } = this.props;
    return (
      <TouchableOpacity
        activeOpacity={!disabled ? 0.5 : 1}
        onPress={!disabled && this.onDisplayerPress}
        style={[
          styles.border(1),
          styles.rounded(5),
          styles.backgroundColor(!disabled ? 'white' : 'bg'),
          styles.padding(10, 12),
          { minHeight: 35 }
        ].concat(style)}
      >
        {!!renderValue ? (
          renderValue(value)
        ) : (
          <Text>{value.map(({ label }) => label).join('\n')}</Text>
        )}
      </TouchableOpacity>
    );
  }

  renderDoneBar() {
    const { autoFocus } = this.props,
      { search } = this.state;
    return (
      <Fragment>
        <TopPadder />
        <DoneBar
          onDonePress={this.onRequestClose}
          leftWidget={
            <Button transparent onPress={this.onRequestClose}>
              <Icon name={'arrow-left'} />
            </Button>
          }
          inputProps={{
            value: search,
            autoFocus,
            onChangeText: this.onSearchChangeText,
            style: [styles.border(0)]
          }}
        />
      </Fragment>
    );
  }
  renderWidget() {
    const { items, onChange, value = [], multiple } = this.props,
      { search } = this.state;
    const regexp = new RegExp(`${search}`, 'i');
    let filteredItems = items
      .map(item => {
        const isMatch = regexp.test(item.label);
        const isSelected = !!~value.findIndex(
          _value => _value.value === item.value
        );
        if (isSelected || isMatch) {
          return {
            ...item,
            isSelected,
            isMatch
          };
        }
      })
      .filter(_ => _);

    return (
      <ScrollView>
        <List>
          {filteredItems.length === 0 && (
            <ListItem title={'No Data'} titleColor={'border'} border={false} />
          )}
          {filteredItems.map((item, i) => {
            const { label, isSelected } = item;
            return (
              <ListItem
                key={i}
                title={label}
                titleColor={isSelected ? 'primary' : 'dark'}
                icon={isSelected ? 'check' : ''}
                iconColor={'primary'}
                onPress={() => {
                  let newValue = value.concat();
                  if (isSelected) {
                    newValue = newValue.filter(
                      _value => _value.value !== item.value
                    );
                  } else {
                    if (!multiple) {
                      newValue = [item];
                      this.onRequestClose();
                    } else {
                      if (newValue.length < multiple - 0) newValue.push(item);
                    }
                  }
                  onChange(newValue);
                }}
              />
            );
          })}
        </List>
      </ScrollView>
    );
  }
}
ModalizedSelect.propTypes = {
  ...ModalizedInputSelect.propTypes,
  renderValue: PropTypes.func,
  multiple: PropTypes.any,
  onChange: PropTypes.func
};
ModalizedSelect.defaultProps = {
  autoFocus: true,
  onChange: _ => _,
  items: [
    {
      label: 'test1',
      value: 1
    },
    {
      label: 'test2',
      value: 2
    },
    {
      label: 'test3',
      value: 3
    },
    {
      label: 'test4',
      value: 4
    }
  ]
};
