import { ApolloBoost } from '../../../plugins';
import {
  FORM_REVISION_CREATE as _FORM_REVISION_CREATE,
  GET_BADGES as _GET_BADGES
} from '../Revisions/_gql';
const { gql } = ApolloBoost;

export const GET_FORMS = projectId => gql`
  query {
    me {
      id
      p_${projectId.replace(/-/g, '_')}: project(id: "${projectId}") {
        id
        name
        description
        forms {
          id
          name
          image {
            key
            bucket
            region
          }
          template {
            id
            views {
              id
              label
            }
          }
          revision {
            id
            revision
          }
        }
      }
    }
  }
`;
export const GET_BADGES = _GET_BADGES;

export const FORM_REVISION_CREATE = _FORM_REVISION_CREATE;
