import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '../layout';
import { ScrollView } from '../../../plugins';
import { FileInputS3_2 } from './index';
import Button from '../button';
import Text from '../text';
import styles from '../../styles';

export default class FileInputMultiple extends Component {
  state = {
    random: 0,
    current: 0
  };
  onFileSelect = file => {
    const { value, onFileSelect, count } = this.props,
      { current } = this.state;
    if (Array.isArray(file)) {
      const files = [...file];
      const inserted = [];
      while (files.length > 0) {
        const file = files.shift();
        for (let i = current; i < count; i++) {
          if (!value[i]) {
            value[i] = file;
            inserted.push(i);
            break;
          }
        }
      }
      onFileSelect(value, inserted);
    } else {
      value[current] = file;
      onFileSelect(value, current);
    }
  };
  onChange = s3 => {
    const { value, onChange } = this.props,
      { current } = this.state;
    value[current] = s3;
    onChange(value, current);
  };
  onFrameChange = i => {
    this.setState({ current: i, random: Math.random() });
  };
  render() {
    const { random, current } = this.state,
      {
        value,
        count,
        style,
        imageOnly,
        disabled,
        timeFormat,
        maxSize
      } = this.props;
    return (
      <Col style={style}>
        <FileInputS3_2
          key={random}
          disabled={disabled}
          value={(value || [])[current]}
          onFileSelect={this.onFileSelect}
          onChange={this.onChange}
          imageOnly={imageOnly}
          multiple={true}
          maxSize={maxSize}
          style={[
            {
              borderBottomWidth: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }
          ]}
          timeFormat={timeFormat}
        />
        <ScrollView
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          style={[
            styles.backgroundColor('light-bg'),
            styles.border(1),
            {
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5
            }
          ]}
        >
          <Row style={[styles.marginX(5)]}>
            {Array(count)
              .fill(null)
              .map((_, i) => (
                <Button
                  key={i}
                  color={!!(value || [])[i] ? 'primary' : 'border'}
                  transparent={current !== i}
                  onPress={() => this.onFrameChange(i)}
                >
                  <Text>{i + 1}</Text>
                </Button>
              ))}
          </Row>
        </ScrollView>
      </Col>
    );
  }
}
FileInputMultiple.propTypes = {
  style: PropTypes.any,
  value: PropTypes.array,
  onFileSelect: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  count: PropTypes.number,
  imageOnly: PropTypes.bool,
  timeFormat: PropTypes.string,
  maxSize: PropTypes.number
};
FileInputMultiple.defaultProps = {
  onFileSelect: _ => _,
  onChange: _ => _,
  count: 20,
  imageOnly: false,
  timeFormat: 'DD-MMM-YYYY HH:mm'
};
