import React from "react";
import { Charts, OS, View } from "../../../plugins";
import { Row, Col } from "../layout";
import styles from "../../styles";
import Text from "../text";

const COLORS = ["primary", "info", "warning", "danger", "success"];
const PieChart_RN = ({
  size = Math.min(styles.vw(0.5).width, styles.vh(0.5).height),
  data = [],
  style
}) => {
  const total = data.reduce((reducer, { value }) => reducer + value, 0);
  return (
    <Row
      fx={"center"}
      style={[styles.padding(10), { minHeight: size }].concat(style)}
    >
      <Charts.PieChart
        style={{ height: size, width: size }}
        data={
          total > 0
            ? data.map(({ value, color }, i) => ({
                key: i,
                value,
                svg: {
                  fill: styles.getColorCode(color || COLORS[i % COLORS.length])
                }
              }))
            : [{ value: 1, svg: { fill: styles.getColorCode("secondary-bg") } }]
        }
      />
      <Col fy={"center"} style={[styles.marginLeft(20)]}>
        {data.map(
          ({ label, value, color }, i) =>
            value > 0 && (
              <Row fy={"center"} key={i}>
                <View
                  style={[
                    styles.backgroundColor(color || COLORS[i % COLORS.length]),
                    styles.marginRight(10),
                    styles.rounded(8),
                    { width: 16, height: 16 }
                  ]}
                />
                <Text>
                  {label}: {value}
                </Text>
              </Row>
            )
        )}
      </Col>
    </Row>
  );
};
const PieChart = ({ size = 300, data = [], style }) => {
  return (
    <Row
      fx={"center"}
      style={[styles.padding(10), { minHeight: size }].concat(style)}
    >
      <Charts.ResponsiveContainer width={size} height={size}>
        <Charts.PieChart>
          <Charts.Pie
            data={data}
            nameKey={"label"}
            dataKey={"value"}
            fill={"#fff"}
          >
            {data.map(({ color }, i) => (
              <Charts.Cell
                key={i}
                fill={styles.getColorCode(color || COLORS[i % COLORS.length])}
              />
            ))}
          </Charts.Pie>
        </Charts.PieChart>
      </Charts.ResponsiveContainer>
      <Col fy={"center"} style={[styles.marginLeft(20)]}>
        {data.map(
          ({ label, value, color }, i) =>
            value > 0 && (
              <Row fy={"center"} key={i}>
                <View
                  style={[
                    styles.backgroundColor(color || COLORS[i % COLORS.length]),
                    styles.marginRight(10),
                    styles.rounded(8),
                    { width: 16, height: 16 }
                  ]}
                />
                <Text>
                  {label}: {value}
                </Text>
              </Row>
            )
        )}
      </Col>
    </Row>
  );
};

export default OS === "web" ? PieChart : PieChart_RN;
