import React from 'react';
import Text from '../text';
import styles from '../../styles';

export default ({ color = 'secondary', style, children }) => (
  <Text
    color={color}
    style={[styles.small].concat(style)}
  >
    {children}
  </Text>
);
