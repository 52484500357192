import React from 'react';
import { View } from '../../plugins';
import styles from '../styles';

const mapper = {
  faceid: require('./svg/faceid').default,
  fingerprint: require('./svg/fingerprint').default,
  'chart-pie-alt': require('./svg/chart-pie-alt').default,
  'chevron-double-left': require('./svg/chevron-double-left').default,
  'chevron-double-right': require('./svg/chevron-double-right').default,
  'chevron-up': require('./svg/chevron-up').default,
  'chevron-left': require('./svg/chevron-left').default,
  'chevron-right': require('./svg/chevron-right').default,
  'arrow-left': require('./svg/arrow-left').default,
  'arrow-right': require('./svg/arrow-right').default,
  'arrow-down': require('./svg/arrow-down').default,
  'angle-up': require('./svg/angle-up').default,
  eye: require('./svg/eye').default,
  'trash-alt': require('./svg/trash-alt').default,
  'info-circle': require('./svg/info-circle').default,
  'eye-slash': require('./svg/eye-slash').default,
  'angle-down': require('./svg/angle-down').default,
  'list-ul': require('./svg/list-ul').default,
  'calendar-alt': require('./svg/calendar-alt').default,
  'map-marked-alt': require('./svg/map-marked-alt').default,
  'unlock-alt': require('./svg/unlock-alt').default,
  'lock-alt': require('./svg/lock-alt').default,
  download: require('./svg/download').default,
  keyboard: require('./svg/keyboard').default,
  ban: require('./svg/ban').default,
  eraser: require('./svg/eraser').default,
  user: require('./svg/user').default,
  export: require('./svg/export').default,
  check: require('./svg/check').default,
  redo: require('./svg/redo').default,
  undo: require('./svg/undo').default,
  clock: require('./svg/clock').default,
  file: require('./svg/file').default,
  'check-square': require('./svg/check-square').default,
  'paper-plane': require('./svg/paper-plane').default,
  'share-alt': require('./svg/share-alt').default,
  square: require('./svg/square').default,
  stamp: require('./svg/stamp').default,
  expand: require('./svg/expand').default,
  images: require('./svg/images').default,
  clone: require('./svg/clone').default,
  'square-s': require('./svg/square-s').default,
  times: require('./svg/times').default,
  print: require('./svg/print').default,
  search: require('./svg/search').default,
  cog: require('./svg/cog').default,
  plus: require('./svg/plus').default,
  minus: require('./svg/minus').default,
  filter: require('./svg/filter').default,
  'circle-s': require('./svg/circle-s').default,
  'file-plus': require('./svg/file-plus').default,
  'file-edit': require('./svg/file-edit').default,
  'file-signature': require('./svg/file-signature').default,
  'file-upload': require('./svg/file-upload').default,
  'wifi-slash': require('./svg/wifi-slash').default,
  circle: require('./svg/circle').default,
  edit: require('./svg/edit').default,
  question: require('./svg/question').default
};

export default ({
  name,
  size = styles.p.fontSize,
  color = '#000',
  style = []
}) => {
  let props = { size, color: styles.getColorCode(color), style };
  let I = mapper[name];
  return <View pointerEvents={'none'}>{I && <I {...props} />}</View>;
};
