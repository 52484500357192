import { ApolloCacheInMemory } from '../../plugins';

const {
  data: {
    __schema: { types }
  }
} = {
  data: {
    __schema: {
      types: [
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INTERFACE',
          possibleTypes: [
            {
              name: 'SignatureField'
            },
            {
              name: 'SubmitButton'
            },
            {
              name: 'UserField'
            },
            {
              name: 'FileField'
            },
            {
              name: 'TextField'
            },
            {
              name: 'PreviewField'
            },
            {
              name: 'LocationField'
            },
            {
              name: 'NotifyField'
            },
            {
              name: 'ChopField'
            },
            {
              name: 'WithdrawButton'
            },
            {
              name: 'ApprovalField'
            },
            {
              name: 'DateTimeField'
            },
            {
              name: 'EnumField'
            }
          ]
        },
        {
          kind: 'SCALAR',
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INTERFACE',
          possibleTypes: [
            {
              name: 'FormTemplateConnection'
            },
            {
              name: 'ProjectConnection'
            },
            {
              name: 'UserConnection'
            },
            {
              name: 'RevisionConnection'
            },
            {
              name: 'SearchConnection'
            }
          ]
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INTERFACE',
          possibleTypes: [
            {
              name: 'FormTemplate'
            },
            {
              name: 'FormStage'
            },
            {
              name: 'User'
            },
            {
              name: 'Project'
            },
            {
              name: 'Form'
            },
            {
              name: 'Revision'
            }
          ]
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'INPUT_OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'SCALAR',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'OBJECT',
          possibleTypes: null
        },
        {
          kind: 'ENUM',
          possibleTypes: null
        }
      ]
    }
  }
};

export default new ApolloCacheInMemory.IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: types.filter(type => type.possibleTypes !== null)
    }
  }
});
