import React from 'react';
import { Svg, Path } from '../../../plugins';

export default ({ style, color, size }) => {
  return (
    <Svg
      style={style}
      width={size}
      height={size}
      viewBox={'0 0 512 512'}
      version='1.1'
    >
      <Path
        fill={color}
        d='M497.94 273.94a48 48 0 0 0 0-67.88l-160-160a48 48 0 0 0-67.88 0l-256 256a48 48 0 0 0 0 67.88l96 96A48 48 0 0 0 144 480h356a12 12 0 0 0 12-12v-8a12 12 0 0 0-12-12H323.88l174.06-174.06zM292.69 68.69a16 16 0 0 1 22.62 0l160 160a16 16 0 0 1 0 22.62L358.63 368 176 185.37zM144 448a15.88 15.88 0 0 1-11.31-4.69l-96-96a16 16 0 0 1 0-22.62L153.37 208 336 390.63l-52.69 52.68A15.88 15.88 0 0 1 272 448z'
      />
    </Svg>
  );
};
