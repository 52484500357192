import React, { Component } from 'react';
import { Animated, TouchableOpacity } from '../../plugins';
import Text from './text';
import { Col } from './layout';
import styles from '../styles';

let inst;
const Content = ({ style, color, children }) => (
  <Col
    style={[
      styles.padding(12, 20),
      styles.backgroundColor(`${color}-bg`),
      styles.rounded(5),
      styles.border(1, null, null, null, `darken-0.1-${color}-bg`)
    ].concat(style)}
    fy={'center'}
  >
    <Text color={`${color}-text`}>{children}</Text>
  </Col>
);
export default class Alert extends Component {
  state = {
    ani: new Animated.Value(0),
    color: 'primary',
    msg: '',
    pressed: false
  };
  handle = undefined;
  static success = (msg, duration) => {
    if (!inst) return;
    inst.show('success', msg, duration);
  };
  static secondary = (msg, duration) => {
    if (!inst) return;
    inst.show('secondary', msg, duration);
  };
  static danger = (msg, duration) => {
    if (!inst) return;
    inst.show('danger', msg, duration);
  };
  static info = (msg, duration) => {
    if (!inst) return;
    inst.show('info', msg, duration);
  };
  static primary = (msg, duration) => {
    if (!inst) return;
    inst.show('primary', msg, duration);
  };
  static light = (msg, duration) => {
    if (!inst) return;
    inst.show('light', msg, duration);
  };
  static warning = (msg, duration) => {
    if (!inst) return;
    inst.show('warning', msg, duration);
  };
  static dark = (msg, duration) => {
    if (!inst) return;
    inst.show('dark', msg, duration);
  };
  componentDidMount() {
    if (!inst) inst = this;
  }
  componentWillUnmount() {
    if (inst === this) inst = null;
  }
  show = (color = 'primary', msg = '', duration = 2000) => {
    if (!msg) return;
    const { ani } = this.state;
    this.setState({ color, msg });
    Animated.spring(ani, {
      toValue: 1,
      onComplete: () => {
        clearTimeout(this.handle);
        this.handle = setTimeout(this.hide, duration);
      }
    }).start();
  };
  hide = () => {
    const { ani, pressed } = this.state;

    clearTimeout(this.handle);
    if (pressed) {
      this.handle = setTimeout(this.hide, 1000);
    } else {
      Animated.spring(ani, {
        toValue: 0,
        onComplete: () => {
          if (ani._value === 0) this.setState({ msg: '' });
        }
      }).start();
    }
  };

  onPressIn = () => {
    this.pressInTime = new Date();
    this.setState({ pressed: true });
  };
  onPressOut = () => {
    if (!!this.pressInTime) {
      if (new Date() - this.pressInTime < 500) {
        this.state.pressed = false;
        this.hide();
      }
    }

    this.setState({ pressed: false });
  };
  render() {
    const { ani, msg, color } = this.state;
    const aniRight = ani.interpolate({
      inputRange: [0, 1],
      outputRange: [-50, 5]
    });
    return (
      <Animated.View
        style={[
          styles.shadow,
          {
            position: 'absolute',
            top: 5 + styles.getIphoneTopPadder().height,
            maxWidth: styles.vw(1).width - 10,
            right: aniRight,
            opacity: ani,
            zIndex: 9999
          }
        ]}
      >
        <TouchableOpacity
          activeOpacity={1}
          onPress={this.hide}
          onPressIn={this.onPressIn}
          onPressOut={this.onPressOut}
          touchSoundDisabled={true}
        >
          <Content color={color}>{msg}</Content>
        </TouchableOpacity>
      </Animated.View>
    );
  }
}
