import React from 'react';
import styles from '../../../styles';
import { ROW_HEIGHT } from '../index';
import { TouchableOpacity } from '../../../../plugins';
import Text from '../../text';

export default ({
  row,
  column,
  style = {},
  selected,
  onPress
}) => {
  const { field, render, width } = column,
    value = row[field] || '';

  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={1}
      style={[
        selected && styles.backgroundColor('primary-bg'),
        styles.border(0, 0, 1, 1),
        styles.padding(10),
        styles.row,
        !!width && { width, maxWidth: width, minWidth: width },
        { ...style, height: ROW_HEIGHT, alignItems: 'center' }
      ]}
    >
      {!!render ? (
        render(row, value, field)
      ) : (
        <Text
          numberOfLines={1}
          color={row._color}
          style={[
            {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          ].concat(row._textStyle)}
        >
          {value}
        </Text>
      )}
    </TouchableOpacity>
  );
};
