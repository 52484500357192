import React, { Component } from 'react';
import { Animated, StyleSheet, View, Text, Easing } from '../../plugins';
import { Row, Col, Flex } from './layout';
import Ico from '../icon';
import styles from '../styles';

export const PullRefresher = ({ style, progress }) => {
  const aniRotate = progress.interpolate({
    inputRange: [0.9, 1],
    outputRange: [`1.57rad`, `-1.57rad`],
    extrapolate: 'clamp'
  });
  const aniOpacity = progress.interpolate({
    inputRange: [0.9, 1],
    outputRange: [1, 0],
    extrapolate: 'clamp'
  });
  const aniAntiOpacity = progress.interpolate({
    inputRange: [0.9, 1],
    outputRange: [0, 1],
    extrapolate: 'clamp'
  });
  return (
    <View style={[styles.center, { overflow: 'hidden' }].concat(style)}>
      <Row>
        <Flex />
        <Animated.View style={[{ transform: [{ rotateZ: aniRotate }] }]}>
          <Ico
            name={'arrow-right'}
            size={25}
            color={styles.getColorCode('dark')}
          />
        </Animated.View>
        <View style={[styles.margin(0, 0, 0, 15)]}>
          <Animated.Text
            style={[styles.color('dark'), { opacity: aniOpacity }]}
          >
            Pull to Refresh
          </Animated.Text>
          <Animated.Text
            style={[
              _styles.text,
              styles.color('dark'),
              { opacity: aniAntiOpacity }
            ]}
          >
            Release
          </Animated.Text>
        </View>
        <Flex />
      </Row>
    </View>
  );
};

export class RepeatRefresher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ani: new Animated.Value(0)
    };
  }

  static propTypes = {
    style: Row.propTypes.style
  };
  componentDidMount() {
    this.cycleAnimation();
  }
  cycleAnimation() {
    let { ani } = this.state;
    ani.setValue(0);
    Animated.timing(ani, {
      toValue: 1,
      duration: 500,
      easing: Easing.linear
    }).start(() => {
      this.cycleAnimation();
    });
  }
  render() {
    let { style } = this.props,
      { ani } = this.state;
    const aniRotate = ani.interpolate({
      inputRange: [0, 1],
      outputRange: [`0rad`, `6.283rad`]
    });
    return (
      <View style={[styles.center].concat(style)}>
        <Row>
          <Flex />
          <Animated.View style={[{ transform: [{ rotateZ: aniRotate }] }]}>
            <Ico name={'redo'} size={25} color={styles.getColorCode('dark')} />
          </Animated.View>
          <Animated.View style={[styles.margin(0, 0, 0, 15)]}>
            <Text style={[styles.color('dark')]}>Refreshing</Text>
          </Animated.View>
          <Flex />
        </Row>
      </View>
    );
  }
}

const _styles = StyleSheet.create({
  text: {
    position: 'absolute',
    left: 0,
    top: 0
  }
});
