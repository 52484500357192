import React from 'react';
import { View, StyleSheet } from '../../plugins';
import { Col, Flex } from './layout';
import styles from '../styles';

export default ({
  style,
  lineStyle,
  count = 1,
  color = 'dark',
  transparent = false,
  ...props
}) => (
  <Col {...props} style={[styles.margin(1, 0, 2)].concat(style)}>
    <Flex />
    {Array(count)
      .fill(null)
      .map((_, i) => (
        <View
          key={i}
          style={[
            styles.marginTop(i === 0 ? 16 : 1),
            styles.marginBottom(i === count - 1 ? 16 : 1),
            {
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderBottomColor: transparent
                ? 'transparent'
                : styles.getColorCode(`darken-0.1-${color}-bg`)
            }
          ].concat(lineStyle)}
        />
      ))}
    <Flex />
  </Col>
);
