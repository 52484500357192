import { Alert } from '../../plugins';

export const alert = (
  title = '',
  msg = '',
  okText = 'Yes',
  cancelText = 'Cancel'
) => {
  return new Promise((resolve, reject) => {
    Alert.alert(
      title,
      msg,
      [
        !!cancelText && { text: cancelText, onPress: reject, style: 'cancel' },
        !!okText && { text: okText, onPress: resolve }
      ].filter(_ => _),
      { cancelable: true }
    );
  });
};
