import { Config, ReactNativeAWS3, OS } from '../../plugins';
import uuid from 'uuidv4';

const s3Options = {
  keyPrefix: Config.REACT_APP_S3_DIRNAME /* deprecated */,
  bucket: Config.REACT_APP_S3_BUCKET,
  region: Config.REACT_APP_S3_REGION,
  awsUrl: Config.REACT_APP_S3_AWSURL /* deprecated */,
  accessKey: Config.REACT_APP_S3_ACCESS_KEY /* deprecated */,
  secretKey: Config.REACT_APP_S3_SECRET_KEY /* deprecated */
};

export const getLocation = s3Object => {
  try {
    const { key, region, bucket } = s3Object;
    return `https://${bucket}.s3-${region}.amazonaws.com/${key}`;
  } catch (e) {
    return '';
  }
};

/* deprecated: replaced by ./upload.js */
export const uploadS3 = async file => {
  const extension = file.name.replace(/^.*\.(.*?)$/, '$1').toLowerCase();
  let _file = undefined;

  if (OS === 'web') {
    _file = new File([file], `${uuid()}.${extension}`);
  } else {
    _file = { ...file, name: `${uuid()}.${extension}` };
  }

  console.log(_file, s3Options);
  const res = await ReactNativeAWS3.RNS3.put(_file, s3Options);

  let s3Object;
  if (OS === 'web') {
    const { bucket, key } = res;
    s3Object = { bucket, key, region: s3Options.region };
  } else {
    const { body, Response } = res;
    if (body && body.postResponse) s3Object = body.postResponse;
    else if (Response) s3Object = Response;
  }

  if (!!s3Object && !!s3Object.key) {
    console.log(s3Object);
    return {
      key: s3Object.key,
      region: Config.REACT_APP_S3_REGION,
      bucket: Config.REACT_APP_S3_BUCKET
    };
  }
  throw new Error('Upload Fail');
};
