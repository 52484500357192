import { ApolloBoost } from '../../../plugins';
const { gql } = ApolloBoost;

export const GET_ME = gql`
  query {
    me {
      id
      createdAt
      updatedAt
      email
      group
      name
      company
      position
      blocked
    }
  }
`;
