import React, { Component } from 'react';
import { OneSignal, View, Config } from '../../plugins';

let inst;
export default class OneSignalWorker extends Component {
  state = {
    playerId: undefined,
    pushToken: undefined,
    openData: undefined
  };
  static getPlayerId = () => {
    return !!inst ? inst.state.playerId : undefined;
  };
  static getOpenData = () => {
    return !!inst ? inst.getOpenData() : undefined;
  };
  getOpenData = () => {
    const { openData } = this.state;
    return openData;
  };
  static clearOpenData = () => {
    return !!inst && inst.clearOpenData();
  };
  clearOpenData = () => {
    this.setState({ openData: undefined });
  };

  componentDidMount() {
    inst = this;
    OneSignal.init(Config.REACT_APP_ONE_SIGNAL_TOKEN);
    console.log('ONE SIGNAL TOKEN:', Config.REACT_APP_ONE_SIGNAL_TOKEN);
    OneSignal.addEventListener('received', this.onReceived.bind(this));
    OneSignal.addEventListener('opened', this.onOpened.bind(this));
    OneSignal.addEventListener('ids', this.onIds.bind(this));
  }
  componentWillUnmount() {
    inst = null;
    OneSignal.removeEventListener('received', this.onReceived.bind(this));
    OneSignal.removeEventListener('opened', this.onOpened.bind(this));
    OneSignal.removeEventListener('ids', this.onIds.bind(this));
  }
  onReceived(notification) {
    console.log('Notification received: ', notification);
  }

  onOpened(openResult) {
    try {
      this.setState({
        openData: openResult.notification.payload.additionalData
      });
    } catch (e) {}
  }

  onIds({ userId: playerId, pushToken }) {
    this.setState({
      playerId,
      pushToken
    });
  }

  render() {
    return <View />;
  }
}
