import React from 'react';
import { ROW_HEIGHT } from '../index';
import { View } from '../../../../plugins';

export default ({ style = {} }) => {
  return (
    <View
      style={[
        {
          ...style,
          height: ROW_HEIGHT
        }
      ]}
    />
  );
};
