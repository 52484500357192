import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Flex } from '../../layout';
import DoneBar from './doneBar';
import {
  TouchableOpacity,
  Modal,
  Keyboard,
  View,
  KeyboardAwareScrollView,
  StatusBar
} from '../../../../plugins';
import Text from '../../text';
import Button from '../../button';
import Icon from '../../../icon';
import styles from '../../../styles';
import { TopPadder } from '../../iphonePadder';
import FullWrapper from '../../fullWrapper';

export default class ModalizedInput extends Component {
  state = {
    value: '',
    show: false,
    keyboard: 0
  };
  onDisplayerPress = () => {
    const { value } = this.props;
    this.setState({ show: true, value });
  };
  onRequestClose = () => {
    this.setState({ show: false });
    Keyboard.dismiss();
  };
  componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      ({ endCoordinates }) => {
        this.setState({ keyboard: endCoordinates.height });
      }
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        this.setState({ keyboard: 0 });
      }
    );
  }
  componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }
  render() {
    return (
      <Fragment>
        {this.renderDisplayer()}
        {this.renderModal()}
      </Fragment>
    );
  }
  renderDisplayer() {
    const { style, disabled } = this.props;
    return (
      <TouchableOpacity
        activeOpacity={!disabled ? 0.5 : 1}
        onPress={!disabled && this.onDisplayerPress}
        style={[
          styles.border(1),
          styles.rounded(5),
          styles.backgroundColor(!disabled ? 'white' : 'bg'),
          styles.padding(10, 12),
          { minHeight: 35 }
        ].concat(style)}
      >
        {this.renderDisplayContent()}
      </TouchableOpacity>
    );
  }
  renderDisplayContent() {
    const { value, placeholder } = this.props;
    if (!!value) return <Text style={[styles.block]}>{value}</Text>;
    return (
      <Text style={[styles.block]} color={'border'}>
        {placeholder}
      </Text>
    );
  }
  renderModal() {
    const { show, keyboard } = this.state;
    if (!show) return null;
    return (
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        onRequestClose={this.onRequestClose}
      >
        <StatusBar barStyle={'dark-content'} />
        <FullWrapper
          style={[
            styles.column,
            styles.backgroundColor('white'),
            styles.paddingBottom(keyboard + styles.getIphoneTopPadder().height)
          ]}
        >
          {this.renderDoneBar()}
          {this.renderWidget()}
        </FullWrapper>
      </Modal>
    );
  }
  renderDoneBar() {
    const { onChange, onChangeText, autoFocus, multiline } = this.props,
      { value } = this.state;
    return (
      <Fragment>
        <TopPadder />
        <DoneBar
          leftWidget={
            <Button transparent onPress={this.onRequestClose}>
              <Icon name={'arrow-left'} />
            </Button>
          }
          inputProps={{
            value,
            autoFocus,
            multiline,
            onChangeText: value => {
              this.setState({ value });
              onChangeText(value);
            },
            style: [styles.border(0)],
            onSubmitEditing: !multiline && this.onRequestClose
          }}
          onDonePress={() => {
            const { value } = this.state;
            onChange(value);
            this.onRequestClose();
          }}
        />
      </Fragment>
    );
  }
  renderWidget() {
    const { widget } = this.props;
    return <Flex>{widget}</Flex>;
  }
}
ModalizedInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChangeText: PropTypes.func,
  onChange: PropTypes.func,
  widget: PropTypes.any,
  autoFocus: PropTypes.bool,
  multiline: PropTypes.any,
  style: PropTypes.any,
  multiNumberOfLines: PropTypes.number,
  placeholder: PropTypes.string
};
ModalizedInput.defaultProps = {
  onChangeText: _ => _,
  onChange: _ => _,
  autoFocus: true,
  multiline: false,
  placeholder: '',
  multiNumberOfLines: 10
};
