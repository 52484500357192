import { ApolloBoost } from '../../../plugins';
import {
  FORM_REVISION_SYNC as _FORM_REVISION_SYNC,
  FORM_REVISION_CONFIDENTIAL_FILE_SET as _FORM_REVISION_CONFIDENTIAL_FILE_SET
} from '../../router/Revision/_gql';
const gql = ApolloBoost.gql;

export const FORM_REVISION_CONFIDENTIAL_FILE_SET = _FORM_REVISION_CONFIDENTIAL_FILE_SET;
export const FORM_REVISION_SYNC = _FORM_REVISION_SYNC;
export const GET_FIELD = (
  projectId,
  formId,
  revisionId,
  stageId,
  fieldId
) => gql`
  query {
    me {
      id
      p_${projectId.replace(/-/g, '_')}: project(id: "${projectId}") {
        id
        f_${formId.replace(/-/g, '_')}: form(id: "${formId}") {
          id
          rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
            id
            s_${stageId.replace(/-/g, '_')}: stage(stageId: "${stageId}") {
              id
              fi_${fieldId.replace(/-/g, '_')}: field(fieldId: "${fieldId}") {
                id
                ... on FileField {
                  files {
                    key
                    bucket
                    region
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
