import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../styles';
import { Col } from '../../layout';
import Text from '../../text';
import { TouchableOpacity } from '../../../../plugins';
import { ROW_HEIGHT } from '../index';

export default class TR extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.active !== this.props.active) return true;

    const { row } = this.props,
      { row: _row } = nextProps;
    try {
      return JSON.stringify(row) !== JSON.stringify(_row);
    } catch (e) {
      return false;
    }
  }

  render() {
    const {
      columns,
      row,
      onRowPress,
      onRowPressIn,
      onColumnLayout,
      leftShiftPadding,
      active
    } = this.props;
    return (
      <TouchableOpacity
        activeOpacity={1}
        onPress={onRowPress}
        onPressIn={onRowPressIn}
        style={[
          styles.row,
          styles.border(0, 0, 1),
          styles.paddingLeft(leftShiftPadding),
          styles.backgroundColor(active ? 'primary-bg' : 'white'),
          {
            position: 'relative',
            height: ROW_HEIGHT,
            minHeight: ROW_HEIGHT,
            maxHeight: ROW_HEIGHT
          }
        ]}
      >
        {columns.map((col, j) => {
          const { field, _width, width, render, align = 'flex-start' } = col;
          return (
            <Col
              key={j}
              fx={align}
              fy={'center'}
              {...(width ? {} : { onLayout: e => onColumnLayout(e, col) })}
              style={[
                j > 0 && styles.border(0, 0, 0, 1),
                styles.padding(0, 10),
                !!width && { width },
                !!_width && { minWidth: _width }
              ]}
            >
              {!!render ? (
                render(row, row[field], field)
              ) : (
                <Text
                  numberOfLines={1}
                  color={row._color}
                  style={row._textStyle}
                >
                  {row[field]}
                </Text>
              )}
            </Col>
          );
        })}
      </TouchableOpacity>
    );
  }
}
TR.propTypes = {
  columns: PropTypes.array,
  row: PropTypes.any,
  onRowPress: PropTypes.func,
  onRowPressIn: PropTypes.func,
  onColumnLayout: PropTypes.func,
  leftShiftPadding: PropTypes.number,
  active: PropTypes.bool
};
TR.defaultProps = {
  columns: [],
  row: {},
  onRowPress: _ => _,
  onRowPressIn: _ => _,
  onColumnLayout: _ => _,
  leftShiftPadding: 0
};
