import React, { Component } from 'react';
import { ActionSheet } from '../../plugins';
import PropTypes from 'prop-types';

let inst;
export default class _ActionSheet extends Component {
  state = {
    title: 'Select Action',
    options: ['Action1', 'Action2'],
    cancelText: '',
    resolve: undefined,
    reject: undefined
  };
  componentDidMount() {
    inst = this;
  }
  componentWillUnmount() {
    inst = undefined;
  }
  static execute = (...args) => {
    return inst && inst.execute(...args);
  };
  execute = ({ title, options, cancelText }) => {
    return new Promise(async (resolve, reject) => {
      this.setState({ resolve, reject, title, options, cancelText });
      await new Promise(resolve => setTimeout(resolve, 10));
      this.actionSheet && this.actionSheet.show();
    });
  };
  onPress = i => {
    const { options, resolve, reject } = this.state;
    if (!options[i]) {
      reject && reject(new Error('Cancelled'));
    } else {
      resolve && resolve(i);
    }
    this.setState({ resolve: undefined, reject: undefined });
  };
  render() {
    const { title, options, cancelText, resolve, reject } = this.state;
    if (!resolve || !reject) return null;
    return (
      <ActionSheet
        key={`${title}:${options.join(':')}`}
        ref={ref => (this.actionSheet = ref)}
        title={title}
        options={options.concat(cancelText || 'Cancel')}
        cancelButtonIndex={options.length}
        destructiveButtonIndex={options.length}
        onPress={this.onPress}
      />
    );
  }
}
_ActionSheet.propTypes = {};
_ActionSheet.defaultProps = {};
