import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-virtualized/styles.css';
import App from './wrapper/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register({
  onUpdate: async registration => {
    await registration.unregister();

    window.location.reload(true);
  }
});
