import { ApolloBoost } from '../../../plugins';
import { GET_ME as _GET_ME } from '../Setting/_gql';
const { gql } = ApolloBoost;

export const LOGIN = gql`
  mutation($email: String, $password: String!, $oneSignalPlayerId: ID) {
    signin(
      username: $email
      password: $password
      oneSignalPlayerId: $oneSignalPlayerId
    )
  }
`;
export const GET_VERSION = gql`
  query {
    version {
      current
      alertMessage
    }
  }
`;
