import React from 'react';
import { TextInput } from '../../../plugins';
import styles from '../../styles';

export default ({
  style,
  disabled,
  editable,
  inputRef,
  value,
  onChangeText,
  spellCheck = true,
  ...props
}) => {
  const _disabled = !(!disabled || editable);
  return (
    <TextInput
      ref={inputRef}
      {...props}
      spellCheck={spellCheck}
      editable={!_disabled}
      value={value}
      onChangeText={onChangeText}
      style={[
        styles.border(1),
        styles.rounded(5),
        styles.padding(6, 12),
        styles.backgroundColor(!_disabled ? 'white' : 'bg'),
        styles.color('dark'),
        styles.p
      ].concat(style)}
    />
  );
};
