import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ModalizedInput from './input';
import Text, { TouchableOpacity, View } from '../../../../plugins';
import Input from '../input';
import styles from '../../../styles';
import DoneBar from './doneBar';
import Button from '../../button';
import Icon from '../../../icon';
import { Flex } from '../../layout';
import { TopPadder } from '../../iphonePadder';

export default class InputTextarea extends ModalizedInput {
  state = {
    ...this.state,
    value: ''
  };
  onDisplayerPress = () => {
    this.setState({ show: true, value: this.props.value });
  };
  renderDisplayer() {
    const { style, disabled } = this.props;
    return (
      <TouchableOpacity
        activeOpacity={0.5}
        onPress={this.onDisplayerPress}
        style={[
          styles.border(1),
          styles.rounded(5),
          styles.backgroundColor(!disabled ? 'white' : 'bg'),
          styles.padding(10, 12),
          { minHeight: 65 }
        ].concat(style)}
      >
        {this.renderDisplayContent()}
      </TouchableOpacity>
    );
  }
  renderDoneBar() {
    const { disabled } = this.props;
    return (
      <Fragment>
        <TopPadder />
        <DoneBar
          style={[
            {
              shadowOffset: {
                width: 0,
                height: 0
              },
              shadowOpacity: 0,
              elevation: 0
            }
          ]}
          leftWidget={
            <Button transparent onPress={this.onRequestClose}>
              <Icon name={'arrow-left'} />
            </Button>
          }
          onDonePress={
            !disabled &&
            (() => {
              const { onChangeText } = this.props,
                { value } = this.state;
              onChangeText(value);
              this.onRequestClose();
            })
          }
        />
      </Fragment>
    );
  }
  renderWidget() {
    const { disabled } = this.props;
    const { value } = this.state;
    return (
      <Flex style={[styles.padding(10)]}>
        <Input
          multiline
          autoFocus
          disabled={disabled}
          value={value}
          onChangeText={value => {
            this.setState({ value });
          }}
        />
      </Flex>
    );
  }
}
InputTextarea.propTypes = {
  ...ModalizedInput.propTypes
};
InputTextarea.defaultProps = {
  ...ModalizedInput.defaultProps,
  onChangeText: _ => _,
  multiline: 10
};
