import React, { Component } from 'react';
import styles from '../styles';
import { Orientation, View } from '../../plugins';

export class TopPadder extends Component {
  componentDidMount() {
    Orientation.addOrientationListener(this.onOrientationChange);
  }
  componentWillUnmount() {
    Orientation.removeOrientationListener(this.onOrientationChange);
  }
  onOrientationChange = () => {
    this.setState({});
  };
  render() {
    const { style, ...props } = this.props;
    return (
      <View {...props} style={[styles.getIphoneTopPadder()].concat(style)} />
    );
  }
}
export const BottomPadder = ({ style, additionPadder = 0, ...props }) => (
  <View {...props} style={[styles.getIphoneBottomPadder(additionPadder)].concat(style)} />
);
