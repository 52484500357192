import React, { Component } from 'react';
import { OS, View } from '../../../plugins';
import RNTable from './native';
import WebTable from './web';
import { Col, Row } from '../layout';
import styles from '../../styles';
import TextSkeleton from '../textSkeleton';

export const ROW_HEIGHT = 60;
export default OS === 'web' ? WebTable : RNTable;

export class TableSkeleton extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { columns = [] } = this.props;
    return (
      <Col style={[styles.fill]}>
        {Array(3 + ~~(Math.random() * 3))
          .fill(null)
          .map((_, i) => (
            <TRSkeleton key={i} columns={columns} />
          ))}
      </Col>
    );
  }
}
export class TRSkeleton extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { columns = [] } = this.props;
    const firstRowWidth = !!columns[0]
      ? columns[0].width || columns[0]._width || styles.vw(0.25).width
      : styles.vw(0.25).width;
    return (
      <Row style={[styles.border(0, 0, 1), { height: ROW_HEIGHT }]}>
        <View
          style={[
            styles.padding(10),
            styles.border(0, 1, 0, 0),
            { width: firstRowWidth }
          ]}
        >
          <TextSkeleton rounded={ROW_HEIGHT / 2} />
        </View>
        <View style={[styles.padding(10), styles.flex(1)]}>
          <TextSkeleton rounded={ROW_HEIGHT / 2} />
        </View>
      </Row>
    );
  }
}
