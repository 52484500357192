import React, { Component, Fragment } from 'react';
import { Col, Row } from '../../../rnbc/components/layout';
import NavHeader from '../../../rnbc/components/navHeader';
import Button from '../../../rnbc/components/button';
import ScrollHeaderLayout from '../../../rnbc/components/scrollHeaderLayout';
import CursorBasedList2 from '../../../rnbc/components/cursorBasedList2';
import DesktopIcon from '../../../rnbc/components/desktopIcon';
import RefreshButton from '../../../rnbc/components/refreshButton';
import { BottomPadder } from '../../../rnbc/components/iphonePadder';
import Icon from '../../../rnbc/icon';
import styles from '../../../rnbc/styles';
import { GET_PROJECTS } from './_gql';
import { getLocation } from '../../shared/s3';
import OneSignalWorker from '../../../rnbc/components/oneSignalWorker';
import { OS } from '../../../plugins';

export default class extends Component {
  state = {
    random: 0,
    showLoading: false
  };
  componentDidMount() {
    this._mounted = true;
    const { history } = this.props;
    try {
      const {
        projectId = '',
        formId = '',
        revisionId = ''
      } = OneSignalWorker.getOpenData();
      history.push({
        pathname: `/Revision/${projectId}/${formId}/${revisionId}`,
        state: {
          title: ''
        }
      });
    } catch (e) {
    } finally {
      OneSignalWorker.clearOpenData();
    }
  }
  componentWillUnmount() {
    this._mounted = false;
  }
  onItemPress = project => {
    const { history } = this.props;
    history.push({
      pathname: `/Forms/${project.id}`,
      state: { title: project.name }
    });
  };
  onSettingPress = () => {
    const { history } = this.props;
    history.push('/Setting');
  };
  onRefreshPress = async () => {
    this.setState({ random: Math.random(), showLoading: true });
    await new Promise(resolve => setTimeout(resolve, 1000));
    this._mounted && this.setState({ showLoading: false });
  };
  render() {
    const { random, showLoading } = this.state;
    return (
      <Col style={[styles.blockh]}>
        <NavHeader
          title={'Projects'}
          leftComponent={
            <Button onPress={this.onSettingPress}>
              <Icon name={'cog'} />
            </Button>
          }
          rightComponent={
            <Fragment>
              {OS === 'web' && (
                <RefreshButton
                  color={'light'}
                  isLoading={showLoading}
                  onPress={this.onRefreshPress}
                />
              )}
            </Fragment>
          }
        />
        <ScrollHeaderLayout
          heightRange={[0, 0]}
          onRefresh={() => {
            this.setState({ random: Math.random() });
          }}
          onBottom={async () => {
            !!this.fnLoadMore && this.fnLoadMore();
          }}
        >
          <Row wrap style={[styles.padding(10, 10, 50)]}>
            <CursorBasedList2
              key={random}
              query={GET_PROJECTS}
              getConnection={data => data.me.projects}
              renderItem={(project, i) => (
                <DesktopIcon
                  key={i}
                  {...project}
                  size={Math.min(150, (styles.vw(1).width - 20) / 3)}
                  image={getLocation(project.image)}
                  onPress={() => this.onItemPress(project || {})}
                />
              )}
              renderLoadMoreButton={({ onPress }) => {
                this.fnLoadMore = onPress;
                return null;
              }}
            />
          </Row>
          <BottomPadder additionPadder={100} />
        </ScrollHeaderLayout>
      </Col>
    );
  }
}
