import React from 'react';
import {
  FormGroup,
  Label,
  Modalized2DSelect,
  Small
} from '../../rnbc/components/form';
import Text from '../../rnbc/components/text';
import styles from '../../rnbc/styles';
import FieldLoading from './fieldLoading';
import FieldUserGroupPositionsRemark from './FieldUserGroupPositionsRemark';
import { Row } from '../../rnbc/components/layout';

export default ({
  label,
  disabled,
  location = {},
  onChange = _ => _,
  required,
  maps = [],
  userGroup,
  userPositions = [],
  description,
  _sync
}) => (
  <FormGroup>
    {!!_sync && (
      <FieldLoading style={[styles.absolute({ top: -5, right: 0 })]} />
    )}
    {!!label && (
      <Row fy={'center'}>
        <Label style={[styles.marginY(5, !!description ? 0 : 5)]}>
          {label} <Text color={'danger'}>{!!required && '**'}</Text>
        </Label>
        <FieldUserGroupPositionsRemark
          userGroup={userGroup}
          userPositions={userPositions}
        />
      </Row>
    )}
    {!!description && (
      <Small style={[styles.marginBottom(5)]}>{description}</Small>
    )}
    <Modalized2DSelect
      disabled={disabled}
      value={location || {}}
      style={[styles.flex(1)]}
      onChange={onChange}
      maps={maps}
    />
  </FormGroup>
);
