import React from 'react';
import { Svg, Path, Line, G } from '../../../plugins';

export default ({ style, color, size }) => {
  return (
    <Svg
      style={style}
      width={size}
      height={size}
      viewBox={'2 2 26 26'}
      version='1.1'
    >
      <Path
        fill={'none'}
        stroke={color}
        strokeWidth={2}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
        strokeMiterlimit={10}
        d='M12.062,20c0.688,0.5,1.688,1,2.938,1s2.25-0.5,2.938-1'
      />
      <Line
        fill={'none'}
        stroke={color}
        strokeWidth={2}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
        strokeMiterlimit={10}
        x1='20'
        x2='20'
        y1='12'
        y2='14'
      />
      <Line
        fill={'none'}
        stroke={color}
        strokeWidth={2}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
        strokeMiterlimit={10}
        x1='10'
        x2='10'
        y1='12'
        y2='14'
      />
      <Path
        fill={'none'}
        stroke={color}
        strokeWidth={2}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
        strokeMiterlimit={10}
        d='M15,12  v4c0,0.552-0.448,1-1,1'
      />
      <G>
        <Path
          fill={'none'}
          stroke={color}
          strokeWidth={2}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
          strokeMiterlimit={10}
          d='M26,9   V6c0-1.105-0.895-2-2-2h-3'
        />
        <Path
          fill={'none'}
          stroke={color}
          strokeWidth={2}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
          strokeMiterlimit={10}
          d='M9,4   H6C4.895,4,4,4.895,4,6v3'
        />
        <Path
          fill={'none'}
          stroke={color}
          strokeWidth={2}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
          strokeMiterlimit={10}
          d='M21,26h3c1.105,0,2-0.895,2-2v-3'
        />
        <Path
          fill={'none'}
          stroke={color}
          strokeWidth={2}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
          strokeMiterlimit={10}
          d='M4,21   v3c0,1.105,0.895,2,2,2h3'
        />
      </G>
    </Svg>
  );
};
