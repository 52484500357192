import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles';
import Icon from '../../icon';
import Button from '../button';
import { Modal, ScrollView, View } from '../../../plugins';
import FullWrapper from '../fullWrapper';
import Form, { Checkbox, FormGroup, Label } from '../form';
import { ToggleButton } from '../dropdown';
import { Col, Row } from '../layout';
import _ from 'lodash';
import JSONParser from '../../../wrapper/shared/JSONParser';

export default class ConfigModal extends Component {
  state = {
    toggle: false,
    config: {}
  };
  toggle = () => {
    const { config } = this.state;
    const { onChange } = this.props,
      { toggle } = this.state;
    this.setState({ toggle: !toggle });

    if (toggle) {
      onChange(config);
    }
  };
  render() {
    const { style } = this.props;
    return (
      <Fragment>
        <Button
          onPress={() => {
            const { config } = this.props;
            this.setState({ config: JSONParser(JSON.stringify(config)) });
            this.toggle();
          }}
          style={[
            styles.absolute({
              right: 10,
              bottom: 10
            })
          ].concat(style)}
        >
          <Icon name={'cog'} />
        </Button>
        {this.renderModel()}
      </Fragment>
    );
  }
  renderModel() {
    const { toggle } = this.state;
    return (
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        onRequestClose={this.toggle}
        transparent={true}
        visible={toggle}
      >
        <FullWrapper
          style={[styles.column, styles.backgroundColor('rgba(0,0,0,0.5)')]}
        >
          <ToggleButton onPress={this.toggle} />
          <Row>
            <ToggleButton onPress={this.toggle} />
            <Col
              style={[
                styles.backgroundColor('light'),
                styles.padding(15),
                styles.rounded(5),
                { maxHeight: styles.vh(1).height - 200 }
              ]}
            >
              <ScrollView>
                {this.renderForm()}
                <View style={{ height: 50 }} />
              </ScrollView>
            </Col>
            <ToggleButton onPress={this.toggle} />
          </Row>
          <ToggleButton onPress={this.toggle} />
        </FullWrapper>
      </Modal>
    );
  }
  renderForm() {
    const { columns, onChange } = this.props,
      { config } = this.state,
      { hiddenFields = [] } = config || {};
    return (
      <Form>
        <FormGroup>
          <Label>Display Table Columns</Label>
        </FormGroup>
        {_.uniqBy(columns, 'field').map((column, i) => {
          const { label, field } = column || {};
          const containIndex = hiddenFields.indexOf(field),
            checked = !~containIndex;

          const displayLabel = typeof label === 'string' ? label : field;
          return (
            <FormGroup key={i} row>
              <Label style={[styles.flex(1)]}>{displayLabel}</Label>
              <Checkbox
                label={``}
                checked={checked}
                onPress={() => {
                  if (checked) {
                    hiddenFields.push(field);
                  } else {
                    hiddenFields.splice(containIndex, 1);
                  }
                  config.hiddenFields = hiddenFields;
                  this.setState({ config });
                }}
              />
            </FormGroup>
          );
        })}
      </Form>
    );
  }
}
ConfigModal.propTypes = {
  style: PropTypes.any,
  columns: PropTypes.array,
  config: PropTypes.any,
  onChange: PropTypes.func
};
ConfigModal.defaultProps = {
  style: [],
  columns: [],
  config: {},
  onChange: _ => _
};
