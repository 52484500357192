import React from 'react';
import { Svg, Path } from '../../../plugins';

export default ({ style, color, size }) => {
  return (
    <Svg
      style={style}
      width={size}
      height={size}
      viewBox={'0 0 512 512'}
      version='1.1'
    >
      <Path
        fill={color}
        d='M416 256h-66.56c-16.26 0-29.44-13.18-29.44-29.44v-9.46c0-27.37 8.88-53.42 21.46-77.73 9.11-17.61 12.9-38.38 9.05-60.42-6.77-38.78-38.47-70.7-77.26-77.45C267.41.49 261.65 0 256 0c-53.02 0-96 42.98-96 96 0 14.16 3.12 27.54 8.68 39.57C182.02 164.43 192 194.71 192 226.5v.06c0 16.26-13.18 29.44-29.44 29.44H96c-53.02 0-96 42.98-96 96v32c0 17.67 14.33 32 32 32v64c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-64c17.67 0 32-14.33 32-32v-32c0-53.02-42.98-96-96-96zm32 224H64v-64h384v64zm32-96H32v-32c0-35.29 28.71-64 64-64h66.56c33.88 0 61.44-27.56 61.44-61.5 0-32.42-8.35-65.58-26.27-104.35-3.8-8.23-5.73-17.03-5.73-26.15 0-35.29 28.71-64 64-64 3.88 0 7.83.35 11.76 1.03 25.24 4.39 46.79 26.02 51.22 51.42 2.45 14.04.39 27.95-5.95 40.21C296.19 157.23 288 187.47 288 217.1v9.46c0 33.88 27.56 61.44 61.44 61.44H416c35.29 0 64 28.71 64 64v32z'
      />
    </Svg>
  );
};
