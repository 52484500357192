import React from 'react';
import { View } from '../../../plugins';
import styles from '../../styles';

export default ({ children, style, row = false, ...props }) => (
  <View
    {...props}
    style={[
      styles.margin(0, 0, 15),
      { position: 'relative' },
      !!row && { ...styles.row, flexWrap: 'wrap' }
    ].concat(style)}
  >
    {children}
  </View>
);
