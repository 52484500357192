import React from 'react';
import { Text } from '../../../plugins';
import styles from '../../styles';

export default ({ children, style }) => (
  <Text
    style={[styles.color('dark'), styles.margin(5, 0), { fontSize: 16 }].concat(
      style
    )}
  >
    {children}
  </Text>
);
