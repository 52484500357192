import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView, TouchableOpacity, OS } from '../../plugins';
import H5 from './h5';
import { Col, Flex } from './layout';
import styles from '../styles';

export default class WheelSelect extends Component {
  async componentDidMount() {
    const { value, items, itemHeight } = this.props;
    const i = Math.max(items.findIndex(item => value === item.value), 0);
    await new Promise(resolve => setTimeout(resolve, 0));
    this.scrollView && this.scrollView.scrollTo(i * itemHeight, 0, false);
  }

  async componentWillReceiveProps(nextProps) {
    await new Promise(resolve => setTimeout(resolve, 0));
    this.setValue(nextProps.value);
  }

  setValue = _value => {
    const { value, items, itemHeight } = this.props;
    const i = Math.max(items.findIndex(item => _value === item.value), 0);
    this.scrollView && this.scrollView.scrollTo(i * itemHeight, 0);
  };

  onScroll = e => {
    const {
      nativeEvent: {
        contentOffset: { y }
      }
    } = e;
    const { itemHeight, onChange, items } = this.props;
    const i = Math.round(y / itemHeight);
    const item = items[i] || {};
    onChange(item.value);

    this.scrollView && this.scrollView.scrollTo(i * itemHeight, 0);
  };

  onValuePress = i => {
    const { itemHeight, onChange, items } = this.props;
    const item = items[i] || {};
    onChange(item.value);
    this.scrollView && this.scrollView.scrollTo(i * itemHeight, 0);
  };

  onMomentumScrollEnd = e => {
    const {
      nativeEvent: {
        contentOffset: { y }
      }
    } = e;
    const { itemHeight, onChange, items } = this.props;
    const i = ~~(y / itemHeight);
    const item = items[i] || {};
    onChange(item.value);
  };

  render() {
    const { items, height, itemHeight, style } = this.props;

    const offset = height / 2 - itemHeight / 2;
    return (
      <View style={[{ height }].concat(style)}>
        <ScrollView
          ref={ref => (this.scrollView = ref)}
          scrollEventThrottle={250}
          bounces={false}
          alwaysBounceVertical={false}
          onScroll={OS === 'web' && this.onScroll}
          onMomentumScrollEnd={this.onMomentumScrollEnd}
          snapToInterval={itemHeight}
          showsVerticalScrollIndicator={false}
        >
          <View style={[{ height: offset }]} />
          {items.map(({ label, style }, i) =>
            OS === 'web' ? (
              <TouchableOpacity
                key={i}
                onPress={() => this.onValuePress(i)}
                style={[styles.column, styles.center, { height: itemHeight }]}
              >
                <H5 style={[{ textAlign: 'center' }]}>{label}</H5>
              </TouchableOpacity>
            ) : (
              <Col key={i} fy={'center'} style={[{ height: itemHeight }]}>
                <H5 style={[{ textAlign: 'center' }].concat(style)}>{label}</H5>
              </Col>
            )
          )}
          <View style={[{ textAlign: 'center', height: offset }]} />
        </ScrollView>
        <View
          pointerEvents={'none'}
          style={[
            styles.border(0, 0, 1),
            styles.absolute({
              top: 0,
              left: 0,
              right: 0,
              bottom: offset + itemHeight
            }),
            styles.backgroundColor('white'),
            { opacity: 0.75 }
          ]}
        />
        <View
          pointerEvents={'none'}
          style={[
            styles.border(1, 0, 0, 0),
            styles.absolute({
              top: offset + itemHeight,
              left: 0,
              right: 0,
              bottom: 0
            }),
            styles.backgroundColor('white'),
            { opacity: 0.75 }
          ]}
        />
      </View>
    );
  }
}
WheelSelect.propTypes = {
  items: PropTypes.array,
  value: PropTypes.any,
  style: PropTypes.any,
  height: PropTypes.number,
  itemHeight: PropTypes.number,
  onChange: PropTypes.func
};
WheelSelect.defaultProps = {
  items: [],
  height: 120,
  itemHeight: 40,
  onChange: _ => _
};
