import { ApolloBoost } from '../../../plugins';
const { gql } = ApolloBoost;

export const GET_QUERY = gql`
  query {
    me {
      id
      name
      position
      email
      signature {
        key
        bucket
        region
      }
    }
  }
`;

export const UPDATE_QUERY = gql`
  mutation($email: AWSEmail, $input: UserSignatureUpdateInput!) {
    userSignatureUpdate(email: $email, input: $input) {
      id
      signature {
        key
        bucket
        region
      }
    }
  }
`;
