import React from 'react';
import { TouchableOpacity, ActivityIndicator, OS } from '../../plugins';
import styles from '../styles';

const Button = ({
  style,
  loading = false,
  circle = false,
  rounded = true,
  transparent = false,
  outline = false,
  color = 'primary',
  disabled = false,
  shadow = false,
  onPress,
  onClick,
  children
}) => {
  let colorBtn = `${color}`;
  let colorText = `anti-${color}`;
  let colorBorder = `${color}`;

  if (outline) {
    colorBtn = `transparent`;
    colorText = `${color}`;
  }

  if (transparent) {
    colorBtn = 'transparent';
    colorText = `${color}`;
    colorBorder = 'transparent';
  }

  if (disabled) {
    colorBtn = `grayscale-0-${colorBtn}`;
    colorText = `grayscale-0-${colorText}`;
    colorBorder = `grayscale-0-${colorBorder}`;
  }

  const items = [];
  React.Children.forEach(children, (child, i) => {
    const isFirst = i === 0;
    !!child &&
      items.push(
        React.cloneElement(
          child,
          !!child
            ? {
                key: i,
                color: child.props.color || colorText,
                style: [!isFirst && styles.marginLeft(5)].concat(
                  child.props.style
                )
              }
            : {}
        )
      );
  });

  const action = onPress || onClick;
  const allowAction = !!action && !disabled && !loading;

  return (
    <TouchableOpacity
      onPress={allowAction ? action : undefined}
      activeOpacity={!allowAction ? 1 : 0.5}
      style={[
        styles.row,
        styles.center,
        styles.backgroundColor(colorBtn),
        styles.border(1, null, null, null, colorBorder),
        styles.padding(6, 10),
        styles.marginY(5),
        !!rounded && styles.rounded(5),
        !!shadow && styles.shadow,
        !!circle && styles.rounded(100)
      ].concat(style)}
    >
      {!loading ? (
        items
      ) : (
        <ActivityIndicator
          size={
            OS === 'ios'
              ? 'small'
              : (styles.h3.fontSize + styles.h4.fontSize) / 2
          }
          color={styles.getColorCode(colorText)}
        />
      )}
    </TouchableOpacity>
  );
};

export default Button;
