import React from 'react';
import { ActivityIndicator as Loader, OS } from '../../plugins';
import { Col } from './layout';
import styles from '../styles';

export default ({
  style,
  color = 'primary',
  iosSize = 'large',
  size = styles.h5.fontSize,
  children,
  ...props
}) => (
  <Col fx={'center'} style={[styles.padding(10)].concat(style)} {...props}>
    {children || (
      <Loader
        size={OS === 'ios' ? iosSize : size}
        color={styles.getColorCode(color)}
      />
    )}
  </Col>
);
