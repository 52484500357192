import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AsyncStorage, ReactApollo } from '../../../plugins';
import {
  GET_REVISION_ROWS_REVAMPED,
  GET_REVISION_ROWS_REVAMPED_NANO,
  GET_TABLE_COLUMNS
} from './_gql';
import styles from '../../../rnbc/styles';
import { Row } from '../../../rnbc/components/layout';
import Table from '../../../rnbc/components/table';
import Button from '../../../rnbc/components/button';
import NavHeader from '../../../rnbc/components/navHeader';
import Text from '../../../rnbc/components/text';
import Icon from '../../../rnbc/icon';
import columnRenderer from './_columnRenderer';
import { limits } from './_pager';
import getFieldValue from './_getFieldValue';
import NextPrevRevButton from '../Revision/NextPrevRevButton';

export default class _RevisionRowsTable extends Component {
  variables = undefined;
  cursors = [];
  state = {
    sorting: undefined,
    key_table: 0
  };
  async componentDidMount() {
    this._mounted = true;
    Get_Default_Sorting_Config: {
      const { formId } = this.props;
      try {
        const configStr = await AsyncStorage.getItem(`sort:${formId}`);
        this.setState({ sorting: JSON.parse(configStr) });
      } catch (e) {
        this.setState({ sorting: { field: 'revision', order: 'DESC' } });
      }
    }
  }
  componentWillUnmount() {
    this._mounted = false;
  }
  getColumns = settings => {
    const { onResubmitPress, onExportData } = this.props;
    return [
      {
        label: !!onExportData && (
          <Button transparent onPress={onExportData}>
            <Icon name={'export'} size={styles.h3.fontSize} />
          </Button>
        ),
        field: 'revision',
        align: 'center',
        width: 150,
        render: (row, rev) => {
          const { _color, _hasResubmitButton, _textStyle, revisionId } =
            row || {};
          return (
            <Row fy={'center'}>
              <Text numberOfLines={1} color={_color} style={_textStyle}>
                {rev}
              </Text>
              {_hasResubmitButton && !!onResubmitPress && (
                <Button
                  style={[styles.marginLeft(10)]}
                  color={'danger'}
                  onPress={() => onResubmitPress({ id: revisionId })}
                >
                  <Text>Resubmit</Text>
                </Button>
              )}
            </Row>
          );
        }
      },
      ...settings.map(columnRenderer)
    ].filter(_ => _);
  };
  onSortChange = async sorting => {
    const { formId } = this.props;
    this.setState({ sorting });
    await new Promise(resolve => setTimeout(resolve, 100));
    await AsyncStorage.setItem(`sort:${formId}`, JSON.stringify(sorting));
    if (!this._mounted) return;
    NavHeader.resetLastBackReferrer();
    this.refetch && this.refetch();
  };
  onRowDeepPress = (row, i) => {
    const { projectId, formId, onEditPress } = this.props;
    onEditPress({ ...row, id: row.revisionId });

    Next_Prev_Fetcher: {
      const { cursor } = this.variables || {};
      const targetRowFunctionalCursor = (() => {
        let tmp = cursor - 0 || 0 + i - 1;
        return tmp < 0 ? undefined : `${tmp}`;
      })();
      NextPrevRevButton.ListQueryFetcherCache = {
        query: GET_REVISION_ROWS_REVAMPED_NANO(projectId, formId),
        variables: {
          ...(this.variables || {}),
          cursor: targetRowFunctionalCursor,
          limits: 3
        },
        parseConnection: this.parseConnection
      };
    }
  };
  parseConnection = data => {
    const { projectId, formId } = this.props;
    const { me = {} } = data || {},
      project = me[`p_${projectId.replace(/-/g, '_')}`] || {},
      { revisionsWithData } = project[`f_${formId.replace(/-/g, '_')}`] || {};
    return revisionsWithData || {};
  };
  render() {
    const { projectId, formId } = this.props;
    return (
      <ReactApollo.Query
        query={GET_TABLE_COLUMNS(projectId, formId)}
        fetchPolicy={
          NavHeader.lastBackReferrer.match('/Revision/')
            ? 'cache-first'
            : 'cache-and-network'
        }
      >
        {({ data: { me } = {} }) => {
          const project = (me || {})[`p_${projectId.replace(/-/g, '_')}`] || {},
            { template } = project[`f_${formId.replace(/-/g, '_')}`] || {},
            { stages = [] } = template || {},
            fields = stages
              .reduce((reducer, { fields }) => reducer.concat(fields), [])
              .filter(field => !!field.showInList),
            columns = this.getColumns(fields);

          return this.renderRowFetcher(columns);
        }}
      </ReactApollo.Query>
    );
  }
  renderRowFetcher = columns => {
    const { projectId, formId, filterId, cursor } = this.props,
      { sorting } = this.state,
      { field, order } = sorting || {};
    this.variables = {
      cursor,
      limits,
      view: filterId,
      sortBy: [
        (field && order && sorting) || { field: 'revision', order: 'DESC' }
      ].filter(_ => _),
      filter: []
    };
    return (
      <ReactApollo.Query
        query={GET_REVISION_ROWS_REVAMPED(projectId, formId)}
        variables={this.variables}
        fetchPolicy={
          NavHeader.lastBackReferrer.match('/Revision/')
            ? 'cache-first'
            : 'cache-and-network'
        }
      >
        {res => this.renderContent(res, columns)}
      </ReactApollo.Query>
    );
  };

  renderContent = ({ loading, data, fetchMore, refetch }, columns) => {
    const { onConnection } = this.props,
      { sorting, key_table } = this.state;
    this.refetch = refetch;
    const connection = this.parseConnection(data),
      { nodes = [] } = connection;
    !!onConnection && onConnection(connection);

    const rows = nodes.map(node =>
      parseData2Row(node).reduce((reducer, field) => {
        reducer[field.id] = getFieldValue(field, reducer);
        return reducer;
      }, {})
    );
    return (
      <Table
        key={key_table}
        // configKey={projectId}
        loading={loading}
        style={[styles.block, styles.blockh]}
        columns={columns}
        data={rows}
        sorting={sorting || { field: 'revision', order: 'DESC' }}
        onSortChange={this.onSortChange}
        onRowDeepPress={this.onRowDeepPress}
      />
    );
  };
}
_RevisionRowsTable.propTypes = {
  projectId: PropTypes.string,
  formId: PropTypes.string,
  filterId: PropTypes.string,
  onExportData: PropTypes.func,
  onResubmitPress: PropTypes.func,
  onEditPress: PropTypes.func
};
_RevisionRowsTable.defaultProps = {
  onResubmitPress: _ => _,
  onEditPress: _ => _
};

export const parseData2Row = revision => {
  const {
    id: revisionId,
    revision: rev = '',
    withdrawnBy,
    finalized = false,
    fields = [],
    resubmittingRevisions = []
  } = revision || {};
  return [
    { id: 'withdrawnBy', value: withdrawnBy },
    { id: 'finalized', value: finalized },
    { id: 'revisionId', value: revisionId },
    { id: 'hasResubmittingRevision', value: resubmittingRevisions.length > 0 },
    { id: 'revision', value: rev },
    ...fields
      .filter(field => !!field && !!field.showInList)
      .map(field => ({
        ...field,
        id: field.id.replace(`_${revisionId}`, '').replace(`-${revisionId}`, '')
      }))
  ];
};
