import React from 'react';
import { Router, Route, Switch } from '../../plugins';
import Main from './Main';

export default () => (
  <Router>
    <Switch>
      <Route path={'/'} component={Main} />
    </Switch>
  </Router>
);
