import React from 'react';
import { View } from '../../plugins';
import styles from '../styles';

export default ({ border = true, height, color = 'light-bg', children }) => (
  <View
    style={[
      styles.backgroundColor(color),
      border && styles.border(1, 0),
      {
        height: height || 15
      }
    ]}
  >
    {children}
  </View>
);
