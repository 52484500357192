import React from 'react';
import Icon from '../icon';
import Button from './button';
import { ActivityIndicator as Loader, OS } from '../../plugins';
import styles from '../styles';

export default ({ isLoading, color = 'primary', onPress, ...props }) => {
  return (
    <Button
      transparent
      color={color}
      onPress={isLoading ? undefined : onPress}
      {...props}
    >
      {!!isLoading ? (
        <Loader
          color={styles.getColorCode(color)}
          size={
            OS === 'ios'
              ? 'small'
              : (styles.h3.fontSize + styles.h4.fontSize) / 2
          }
        />
      ) : (
        <Icon name={'redo'} />
      )}
    </Button>
  );
};
