import { gql } from 'apollo-boost';

export const FORM_REVISION_SYNCS = (revisionId, stageId) => gql`
  mutation($fields: [FieldDataInput!]!) {
    formRevisionSync(id: "${revisionId}", fields: $fields) {
        id
        rev_${revisionId.replace(/-/g, '_')}: revision(id: "${revisionId}") {
            id
            s_${stageId.replace(/-/g, '_')}: stage(stageId: "${stageId}") {
                id
                fields {
                    id
                    label
                    required
                    showInList
                    disabled
                    userGroup
                    updatedAt
                    updatedBy {
                        id
                        name
                        position
                        group
                    }
                    ... on TextField {
                        multiline
                        text
                        values
                    }
                    ... on LocationField {
                        maps {
                            id
                            name
                            image
                            locations {
                                name
                                x
                                y
                                radius
                                latitude
                                longitude
                                altitude
                                level
                                grid
                                area
                            }
                        }
                        location {
                            name
                            x
                            y
                            radius
                            latitude
                            longitude
                            altitude
                            level
                            grid
                            area
                        }
                    }
                    ... on SubmitButton {
                        id
                    }
                    ... on DateTimeField {
                        minimum
                        maximum
                        dateTime
                    }
                    ... on PreviewField {
                        downloadUrl
                    }
                    ... on SignatureField {
                        signature
                    }
                    ... on UserField {
                        users {
                            id
                            name
                            email
                            position
                        }
                        requireNoted
                        noted
                    }
                    ... on NotifyField {
                        users {
                            id
                            name
                            email
                            position
                        }
                        message
                    }
                    ... on WithdrawButton {
                        id
                    }
                    ... on FileField {
                        numberOfFiles
                        allow
                        files {
                            key
                            bucket
                            region
                            description
                        }
                    }
                    ... on ApprovalField {
                        status
                        numberOfButton
                    }
                    ... on ChopField {
                        chopped
                        image
                    }
                    ... on EnumField {
                        value
                        values
                    }
                }
            }
        }
    }
  }
`;
