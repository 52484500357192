import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, TouchableOpacity, ScrollView, View } from '../../plugins';
import { Row, Col, Flex } from './layout';
import Button from './button';
import Text from './text';
import Icon from '../icon';
import styles from '../styles';
import FullWrapper from './fullWrapper';

export const DropdownItem = ({ onPress, title }) => {
  return (
    <Button
      onPress={onPress}
      style={[
        styles.margin(0),
        styles.rounded(0),
        { justifyContent: 'flex-start' }
      ]}
      color={'light'}
    >
      <Text>{title}</Text>
    </Button>
  );
};

export default class extends Component {
  static propTypes = {
    color: PropTypes.string,
    style: PropTypes.any,
    outline: PropTypes.bool,
    disabled: PropTypes.bool,
    shadow: PropTypes.bool,
    title: PropTypes.string
  };
  static defaultProps = {
    color: 'primary'
  };

  state = {
    toggle: false
  };

  toggle = () => {
    const { toggle } = this.state;
    this.setState({ toggle: !toggle });
  };

  render() {
    const { disabled, color, style, children, title, ...props } = this.props,
      { toggle } = this.state;

    const items = children
      .reduce((reducer, child) => {
        return reducer.concat(child);
      }, [])
      .filter(child => child && child.type)
      .map((child, i) =>
        React.cloneElement(child, {
          key: i,
          onPress: () => {
            this.toggle();
            child.props.onPress && child.props.onPress();
          }
        })
      );

    return (
      <Fragment>
        <Button
          disabled={disabled}
          onPress={this.toggle}
          color={color}
          style={[styles.row].concat(style)}
          {...props}
        >
          <Text>{title}</Text>
          <Flex />
          <Icon name={'angle-down'} />
        </Button>

        <Modal
          supportedOrientations={['portrait', 'landscape']}
          onRequestClose={this.toggle}
          transparent={true}
          visible={toggle}
        >
          <FullWrapper
            style={[styles.column, styles.backgroundColor('rgba(0,0,0,0.5)')]}
          >
            <ToggleButton onPress={this.toggle} />
            <Row>
              <ToggleButton onPress={this.toggle} />
              <Col>
                <ScrollView
                  style={[
                    styles.backgroundColor('light'),
                    styles.padding(15),
                    styles.rounded(5),
                    { maxHeight: styles.vh(1).height - 200 }
                  ]}
                >
                  {items}
                  <View style={{ height: 50 }} />
                </ScrollView>
              </Col>
              <ToggleButton onPress={this.toggle} />
            </Row>
            <ToggleButton onPress={this.toggle} />
          </FullWrapper>
        </Modal>
      </Fragment>
    );
  }
}

export const ToggleButton = ({ onPress, style, ...props }) => {
  return (
    <TouchableOpacity
      {...props}
      onPress={onPress}
      activeOpacity={0}
      style={[styles.flex(1), { opacity: 0 }].concat(style)}
    />
  );
};
