import { ApolloBoost } from '../../../plugins';
const { gql } = ApolloBoost;

export const GET_TEMPLATE = (projectId, formId) => gql`
  query {
    me {
      id
      p_${projectId.replace(/-/g, '_')}: project(id: "${projectId}") {
        id
        f_${formId.replace(/-/g, '_')}: form(id: "${formId}") {
          id
          fieldSettings
          template {
            id
            views {
              id
              label
            }
            stages {
              id
              label
              fields {
                id
                label
                ... on EnumField {
                  values
                }
                ...on ApprovalField {
                  numberOfButton
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_REVISIONS = gql`
  query(
    $projectId: ID!
    $formId: ID!
    $cursor: String
    $input: [ProjectSearchInput!]!
  ) {
    me {
      id
      project(id: $projectId) {
        id
        form(id: $formId) {
          search(cursor: $cursor, input: $input) {
            nextCursor
            totalCount
            nodes {
              id
              form {
                id
                name
              }
              fields {
                id
                __typename
                label
                showInList
                updatedAt
                updatedBy {
                  id
                  name
                }
                ... on TextField {
                  text
                }
                ... on LocationField {
                  location {
                    name
                    level
                    grid
                    area
                  }
                }
                ... on DateTimeField {
                  dateTime
                }
                ... on PreviewField {
                  downloadUrl
                }
                ... on UserField {
                  users {
                    id
                    name
                  }
                }
                ... on FileField {
                  files {
                    key
                  }
                }
                ... on ApprovalField {
                  status
                  numberOfButton
                }
                ... on ChopField {
                  chopped
                }
                ... on EnumField {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const FORM_REVISION_GENCSV = gql`
  mutation(
    $formId: ID!
    $templateViewId: String
    $filter: [ProjectSearchInput!]
  ) {
    formRevisionGenCSV(
      formId: $formId
      templateViewId: $templateViewId
      filter: $filter
    )
  }
`;
