import React, { Component } from 'react';
import { NetInfo } from '../../plugins';

let inst;
export default class NetworkStatus extends Component {
  state = {
    pollInterval: 1000,
    isOnline: true
  };
  handler;
  static isOnline = () => {
    return !!inst && inst.isOnline();
  };
  isOnline = () => {
    const { isOnline } = this.state;
    return isOnline;
  };
  async componentDidMount() {
    this._mounted = true;
    inst = this;
    this.startPolling();
  }
  componentWillUnmount() {
    this._mounted = false;
    inst = undefined;
  }
  startPolling = async () => {
    if (!this._mounted) return;
    const { pollInterval, isOnline } = this.state;
    const _isOnline = await NetInfo.isConnected.fetch();
    if (_isOnline !== isOnline) this.setState({ isOnline: _isOnline });
    setTimeout(this.startPolling, pollInterval);
  };

  render() {
    const { children } = this.props,
      { isOnline } = this.state;
    return !!children && children(isOnline);
  }
}
