import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ReactApollo } from '../../plugins';
import Loading from './loading';
import Text from './text';
import { Col } from './layout';
import styles from '../styles';
import Button from './button';

export default class CursorBasedList2 extends Component {
  static propTypes = {
    query: PropTypes.any,
    getConnection: PropTypes.func,
    renderItem: PropTypes.func,
    renderItems: PropTypes.func,
    renderLoading: PropTypes.func,
    renderEmpty: PropTypes.func,
    renderLoadMoreButton: PropTypes.func,
    fetchPolicy: PropTypes.string,
    filter: PropTypes.object,
    variables: PropTypes.object,
    limits: PropTypes.number
  };
  static defaultProps = {
    getConnection: _ => _,
    fetchPolicy: 'network-only',
    variables: {},
    renderItem: _ => false,
    renderItems: (items, renderItem) => items.map(renderItem),
    renderLoading: () => (
      <Col fx={'center'} style={[styles.block, styles.padding(10)]}>
        <Loading />
      </Col>
    ),
    renderEmpty: () => (
      <Col fx={'center'} style={[styles.block, styles.padding(10)]}>
        <Text color={'border'}>No Data</Text>
      </Col>
    ),
    renderLoadMoreButton: ({ onPress }) => (
      <Col fx={'center'} style={[styles.block, styles.padding(10)]}>
        <Button transparent style={[styles.block]} onPress={onPress}>
          <Text>Load More</Text>
        </Button>
      </Col>
    ),
    limits: 20
  };
  state = {
    cursors: [undefined]
  };
  render() {
    const {
      query,
      fetchPolicy,
      limits,
      filter,
      variables,
      getConnection,
      renderItem,
      renderItems,
      renderLoading,
      renderEmpty,
      renderLoadMoreButton
    } = this.props;
    const { cursors } = this.state;

    return (
      <Fragment>
        {cursors.map((cursor, i) => (
          <ReactApollo.Query
            key={i}
            query={query}
            fetchPolicy={fetchPolicy}
            variables={{
              cursor,
              limits,
              filter,
              ...variables
            }}
          >
            {({ loading, data }) => {
              let isCacheAvailable = false;
              if (!!data) {
                try {
                  getConnection(data);
                  isCacheAvailable = true;
                } catch (e) {}
              }

              if (!!loading && !isCacheAvailable) return renderLoading();

              let connection = { nextCursor: null, totalCount: 0, nodes: [] };
              try {
                connection = getConnection(data);
              } catch (e) {}
              const { nextCursor, nodes } = connection;
              return (
                <Fragment>
                  {renderItems(nodes, renderItem)}
                  {cursors.length === 1 && nodes.length === 0 && renderEmpty()}
                  {!!nextCursor &&
                    i === cursors.length - 1 &&
                    renderLoadMoreButton({
                      onPress: () => {
                        const { cursors } = this.state;
                        if (cursors[cursors.length - 1] !== nextCursor)
                          this.setState({
                            cursors: [...cursors, nextCursor]
                          });
                      }
                    })}
                </Fragment>
              );
            }}
          </ReactApollo.Query>
        ))}
      </Fragment>
    );
  }
}
