import { AsyncStorage } from '../../plugins';
import { client } from './apollo';
import Main from '../router/Main';
const removePrefix = [/GraphQL error:/gi, /PasswordHistoryError:/gi];

export const errorParser = (e, defaultMessage = '') => {
  let message = '';
  try {
    if (typeof e === 'object') message = e.message;
    else if (Array.isArray(e)) message = e[0].message || e[0];
    else if (typeof e === 'string') message = e;
  } catch (e) {}
  removePrefix.forEach(prefix => {
    message = message.replace(prefix, '');
  });

  message = message.trim();
  checkUnauthorizationError(message);
  checkQuotaExceededError(message);

  return message;
};

async function checkUnauthorizationError(message) {
  let lowercase = message.toLowerCase();
  if (/authorized/.test(lowercase)) {
    const history = Main.getHistory();
    await new Promise(resolve => setTimeout(resolve, 100));
    history.replace('/Login');
  }
}

async function checkQuotaExceededError(message) {
  let lowercase = message.toLowerCase();
  if (/exceeded/.test(lowercase) && /quota/.test(lowercase)) {
    await resetStore();
  } else if (/sqlite_full/.test(lowercase)) {
    await resetStore();
  }

  async function resetStore() {
    await client.resetStore();
    await AsyncStorage.clear();
  }
}
