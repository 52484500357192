import React from 'react';
import styles from '../../../styles';
import { ROW_HEIGHT } from '../index';
import TextSkeleton from '../../textSkeleton';
import { View } from '../../../../plugins';

export default ({ style = {} }) => {
  return (
    <View
      style={[
        styles.padding(10),
        styles.border(0, 0, 1, 1),
        {
          ...style,
          height: ROW_HEIGHT
        }
      ]}
    >
      <TextSkeleton rounded={ROW_HEIGHT / 2} />
    </View>
  );
};
