import React from 'react';
import { Image, TouchableOpacity, OS } from '../../plugins';
import Text from './text';
import Icon from '../icon';
import styles from '../styles';

export default ({ name, image, icon, size = 100, onPress }) => (
  <TouchableOpacity
    onPress={onPress}
    style={[
      styles.column,
      styles.rounded(5),
      styles.padding(10),
      {
        alignItems: 'center',
        width: size
      }
    ]}
  >
    {!!image ? (
      <Image
        source={{ uri: image }}
        style={[
          styles.shadow,
          OS === 'web' && styles.border(1),
          styles.rounded(5),
          styles.marginBottom(10),
          styles.backgroundColor('transparent'),
          { width: size - 10, height: size - 10 }
        ]}
      />
    ) : (
      <Icon name={icon} size={size - 10} style={[styles.marginBottom(10)]} />
    )}
    <Text style={[{ textAlign: 'center' }]}>{name}</Text>
  </TouchableOpacity>
);
