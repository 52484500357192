import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  TouchableOpacity,
  Signature,
  Image,
  View,
  Modal,
  OS,
  Orientation,
  StatusBar,
  FilePicker
} from '../../../../plugins';
import ModalizedInput from './input';
import HR from '../../hr';
import { Col, Row, Flex } from '../../layout';
import Button from '../../button';
import { BottomPadder, TopPadder } from '../../iphonePadder';
import Alert from '../../alert';
import Small from '../small';
import Icon from '../../../icon';
import styles from '../../../styles';
import { errorParser } from '../../../../wrapper/shared/errorParser';
import FullWrapper from '../../fullWrapper';

export default class ModalizedSignature extends ModalizedInput {
  state = {
    ...this.state,
    random: 0,
    rotate: true,
    locked: true,
    empty: true,
    cache: undefined
  };

  componentDidMount() {
    this._mounted = true;
  }
  componentWillUnmount() {
    this._mounted = false;
  }
  onRequestClose = async () => {
    this.setState({ show: false });
    await new Promise(resolve => setTimeout(resolve, 100));
    Orientation.unlockAllOrientations();
  };
  onClearPress = () => {
    this.setState({
      cache: undefined,
      random: Math.random(),
      empty: true,
      locked: false
    });

    /* Desktop Compatibility */
    try {
      this.signature.clear();
    } catch (e) {}
  };
  onDisplayerPress = async () => {
    let { value } = this.props;
    Orientation.lockToLandscape();
    await new Promise(resolve => setTimeout(resolve, 100));
    this.setState({
      show: true,
      empty: !value,
      locked: true
    });
    if (!value) {
      if (this._mounted) {
        this.onClearPress();
      }
    } else {
      if (this._mounted)
        this.setState({
          random: Math.random(),
          cache: value
        });
    }
  };

  renderDisplayer() {
    const { style, value, issuer, issuedAt, entitle, disabled } = this.props;
    return (
      <TouchableOpacity
        activeOpacity={!disabled ? 0.5 : 1}
        onPress={!disabled && this.onDisplayerPress}
        style={[
          styles.center,
          styles.border(1),
          styles.backgroundColor(!disabled ? 'white' : 'bg'),
          styles.rounded(5),
          styles.padding(5),
          {
            width: '100%',
            maxWidth: 500,
            maxHeight: (500 * 9) / 16
          },
          OS !== 'web' && { aspectRatio: 16 / 9 },
          OS === 'web' && { height: 250 }
        ].concat(style)}
      >
        {!!value ? (
          <Fragment>
            <Image
              style={[
                styles.backgroundColor('white'),
                styles.fill,
                { minHeight: 150 }
              ]}
              resizeMode={'contain'}
              source={{ uri: value }}
            />
            <Col
              fx={'flex-end'}
              style={[styles.absolute({ right: 10, bottom: 5 })]}
            >
              {!!issuer && <Small>{issuer}</Small>}
              {!!entitle && (
                <Small style={[styles.bold, styles.color('danger')]}>
                  {entitle}
                </Small>
              )}
              {!!issuedAt && <Small>{issuedAt}</Small>}
            </Col>
          </Fragment>
        ) : (
          <Icon color={'dark-bg'} name={'file-signature'} size={48} />
        )}
      </TouchableOpacity>
    );
  }

  renderDoneBar() {
    return (
      <Fragment>
        <Row style={[styles.header, { zIndex: 1 }]}>
          <Button
            transparent
            style={{ zIndex: 99999 }}
            onPress={this.onRequestClose}
          >
            <Icon name={'arrow-left'} />
          </Button>
          <Flex />
          {this.renderInsertButton()}
          {this.renderClearButton()}
          {this.renderConfirmButton()}
        </Row>
      </Fragment>
    );
  }
  renderInsertButton() {
    if (OS !== 'web') return null;
    return (
      <FilePicker
        maxSize={1024 * 1024 * 5 /* 5 MB */}
        style={{ ...styles.column, ...styles.center }}
        onChange={value => {
          Clear_Canvas: {
            this.setState({
              cache: undefined,
              random: Math.random(),
              empty: true,
              locked: false
            });

            /* Desktop Compatibility */
            try {
              this.signature.clear();
            } catch (e) {}
          }

          Read_Image_DataURI: {
            let reader = new FileReader();
            reader.onload = e => {
              this.signature.fromDataURL(e.target.result);
              this.setState({
                empty: false,
                locked: false,
                cache: null
              });
            };
            reader.onerror = e => {
              Alert.danger(errorParser(e));
            };
            reader.readAsDataURL(value[0]);
          }
        }}
      >
        <Button transparent style={[styles.marginY(0), styles.blockh]}>
          <Icon name={'file-upload'} />
        </Button>
      </FilePicker>
    );
  }

  renderClearButton() {
    return (
      <Button transparent onPress={this.onClearPress}>
        <Icon name={'eraser'} />
      </Button>
    );
  }
  renderConfirmButton() {
    const { locked } = this.state;
    return (
      <Button
        disabled={!!locked}
        transparent
        onPress={() => {
          const { cache } = this.state,
            { onChange } = this.props;

          /* Desktop Compatibility */
          if (OS === 'web') {
            try {
              onChange && onChange(this.signature.toDataURL());
            } catch (e) {}
          } else {
            onChange && onChange(cache);
          }
          this.onRequestClose();
        }}
      >
        <Icon name={'check'} />
      </Button>
    );
  }

  renderModal() {
    const { show } = this.state;
    if (!show) return null;

    return (
      <Modal
        supportedOrientations={['landscape']}
        onRequestClose={this.onRequestClose}
      >
        <StatusBar barStyle={'dark-content'} />
        <FullWrapper style={[styles.column, styles.backgroundColor('white')]}>
          <TopPadder />
          {this.renderDoneBar()}
          {this.renderWidget()}
          <BottomPadder style={[styles.backgroundColor('bg')]} />
        </FullWrapper>
      </Modal>
    );
  }

  renderWidget() {
    const { cache, random, locked } = this.state;

    return (
      <Col
        flex={1}
        fy={'center'}
        fx={'center'}
        style={[styles.backgroundColor('bg'), styles.padding(10)]}
      >
        <View
          pointerEvents={!!locked ? 'none' : 'auto'}
          style={[
            styles.border(1),
            styles.fill,
            styles.backgroundColor('white')
          ]}
          onClick={() => {
            this.setState({ empty: false });
          }}
        >
          <Signature
            key={random}
            style={[styles.fill]}
            ref={ref => {
              this.signature = ref;
              try {
                cache && this.signature.fromDataURL(cache);
              } catch (e) {}
            }}
            minWidth={2}
            maxWidth={10}
            dataURL={cache}
            onChange={({ base64DataUrl }) => {
              this.setState({ cache: base64DataUrl, empty: false });
            }}
          />
          <HR
            pointerEvents={'none'}
            style={[styles.absolute({ bottom: '25%', left: 0, right: 0 })]}
          />
          {!!locked && !!cache && (
            <View
              pointerEvents={'none'}
              style={[
                styles.absolute({ top: 0, left: 0, right: 0, bottom: 0 }),
                styles.fill
              ]}
            >
              <Image
                style={[styles.fill]}
                resizeMode={'contain'}
                src={{ uri: cache }}
              />
            </View>
          )}
        </View>
      </Col>
    );
  }
}
ModalizedSignature.propTypes = {
  ...ModalizedInput.propTypes,
  onChange: PropTypes.func,
  issuer: PropTypes.string,
  entitle: PropTypes.string,
  issuedAt: PropTypes.string
};
ModalizedSignature.defaultProps = {
  ...ModalizedInput.defaultProps,
  autoFocus: false,
  issuer: 'Furla',
  entitle: 'Contractor',
  issuedAt: 'YYYY-MM-DD HH:mm:ss'
};
