import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Flex } from '../../layout';
import Text from '../../text';
import styles from '../../../styles';
import { FlatList, View, Window } from '../../../../plugins';
import { BottomPadder } from '../../iphonePadder';
import TR from './_tr';
import Head from './_head';
import { ROW_HEIGHT, TRSkeleton } from '../index';

const { height: vh } = Window;
export default class TableCore extends Component {
  state = {
    key_head: 0,
    flatListHeight: vh
  };

  componentDidMount() {
    this._mounted = true;
  }
  componentWillUnmount() {
    this._mounted = false;
  }
  onColumnLayout = (
    {
      nativeEvent: {
        layout: { width }
      }
    },
    col
  ) => {
    let originalWidth = col._width || 0;
    if (originalWidth < width) {
      col._width = width;
      clearTimeout(this.handler);
      this.handler = setTimeout(() => {
        if (this._mounted) {
          const { key_head } = this.state;
          this.setState({ key_head: key_head + 1 });
        }
      }, 500);
    }
  };
  onHeaderPress = (col, i) => {
    const { onHeaderPress } = this.props,
      { key_head } = this.state;
    onHeaderPress(col, i);
    this.setState({ key_head: key_head + 1 });
  };

  render() {
    const {
        style,
        columns,
        data,
        onScroll,
        onScrollBeginDrag,
        selectedIndex,
        onRowPress,
        scrollEventThrottle,
        showIndicator,
        leftShiftPadding,
        isMainTable,
        sorting,
        initialScrollIndex,
        padderCount,
        onEndReached,
        loading,
        ...props
      } = this.props,
      { key_head, flatListHeight } = this.state;

    let threshold = 1;
    try {
      threshold = (padderCount * ROW_HEIGHT) / flatListHeight;
    } catch (e) {
    } finally {
      if (threshold === 0) threshold = 99999;
    }

    return (
      <Col style={style} {...props}>
        <Head
          key={key_head}
          columns={columns}
          sorting={sorting}
          loading={loading}
          onHeaderPress={this.onHeaderPress}
          onColumnLayout={this.onColumnLayout}
          leftShiftPadding={leftShiftPadding}
        />
        <Flex
          style={{ position: 'relative' }}
          onLayout={({
            nativeEvent: {
              layout: { height }
            }
          }) => {
            this.setState({ flatListHeight: height });
          }}
        >
          <FlatList
            viewabilityConfig={{
              minimumViewTime: 3000,
              viewAreaCoveragePercentThreshold: 100,
              waitForInteraction: true
            }}
            keyExtractor={(item, i) => item.id || i}
            initialScrollIndex={initialScrollIndex}
            data={data}
            {...(!!onEndReached
              ? {
                  onEndReached: () => onEndReached(threshold),
                  onEndReachedThreshold: threshold
                }
              : {})}
            getItemLayout={(data, index) => ({
              length: ROW_HEIGHT,
              offset: ROW_HEIGHT * index,
              index
            })}
            ListEmptyComponent={
              <Col
                fx={'center'}
                style={[
                  styles.vw(1),
                  styles.backgroundColor('white'),
                  styles.padding(10)
                ]}
              >
                <Text color={'border'}>No Data</Text>
              </Col>
            }
            renderItem={({ item, index }) => (
              <TR
                key={item.id || index}
                columns={columns}
                row={item}
                leftShiftPadding={leftShiftPadding}
                onRowPress={() => onRowPress(item, index)}
                onPressIn={() => onRowPress(item, index)}
                onColumnLayout={this.onColumnLayout}
                active={selectedIndex === index}
              />
            )}
            ListFooterComponent={
              <Fragment>
                {Array(padderCount)
                  .fill(undefined)
                  .map((_, i) => (
                    <TRSkeleton columns={columns} />
                  ))}
                <View style={[{ height: ROW_HEIGHT }]} />
                <BottomPadder />
              </Fragment>
            }
          />
        </Flex>
      </Col>
    );
  }
}
TableCore.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  style: PropTypes.any,
  onScroll: PropTypes.func,
  selectedIndex: PropTypes.number,
  onRowPress: PropTypes.func,
  onHeaderPress: PropTypes.func,
  showIndicator: PropTypes.bool,
  onScrollBeginDrag: PropTypes.func,
  leftShiftPadding: PropTypes.number,
  scrollEventThrottle: PropTypes.number,
  isMainTable: PropTypes.bool,
  sorting: PropTypes.any,
  initialScrollIndex: PropTypes.number,
  onViewPercentageChange: PropTypes.func,
  padderCount: PropTypes.number,
  loading: PropTypes.bool
};
TableCore.defaultProps = {
  columns: [],
  data: [],
  onScroll: _ => _,
  onScrollBeginDrag: _ => _,
  selectedIndex: -1,
  onRowPress: _ => _,
  onHeaderPress: _ => _,
  showIndicator: true,
  leftShiftPadding: 0,
  scrollEventThrottle: 16,
  isMainTable: true,
  initialScrollIndex: 0,
  padderCount: 0,
  onViewPercentageChange: _ => _
};
